import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { TrashXMark, EditData } from 'bvt-icon';
import { Skeleton } from '@bvt-shared/component/Skeleton';
/**
 * @typedef {Object} IDraggableListData
 * @property {number} index - Sort index of array
 * @property {string} id -
 * @property {{name:string,icon:any,color:string,totalBrand:number}} meta
 */
/**
 * @typedef {Object} IDraggableList
 * @property {Array<IDraggableListData>} data - data / data provided from useState or redux
 * @property {string} className
 * @property {import('react').CSSProperties} style
 * @property {(v:Array<IDraggableListData>,IDraggableListData)=>void} onChange - Change handler
 * @property {(v:Array<IDraggableListData>,IDraggableListData)=>void} onDelete - Delete handler
 * @property {(v:Array<IDraggableListData>,IDraggableListData)=>void} onEdit - Edit handler
 */

/**
 * @author Randa <m.randa@bvarta.com>
 * @copyright PT Bhumi Varta Technology
 * @param {...IDraggableList} props
 */
export default function DraggableList(props) {
  const { data, onChange, onDelete, className, style, onEdit } = props;

  const reorderIndexMapping = (newArr) => {
    let nData = [];
    for (let idx = 0; idx < newArr.length; idx++) {
      nData[idx] = {
        ...newArr[idx],
        index: idx + 1,
      };
    }
    return nData;
  };

  const internalHandleOnchange = (indexBefore, indexAfter) => {
    const reorderedData = Array.from(data);
    const [removed] = reorderedData.splice(indexBefore, 1);
    reorderedData.splice(indexAfter, 0, removed);
    onChange &&
      onChange(reorderIndexMapping(reorderedData), reorderedData[indexAfter]);
  };

  /**
   * @param {...IDraggableListData} param
   */
  const internalHandleOnDelete = (param) => {
    const filteredData = _.filter(data, (v) => v.index !== param.index);
    onDelete && onDelete(reorderIndexMapping(filteredData), param);
  };

  const internalHandleOnEdit = (param) => {
    onEdit && onEdit(param);
  };
  return (
    <React.Fragment>
      {data && (
        <DragDropContext
          onDragEnd={(e) =>
            internalHandleOnchange(e.source.index, e.destination.index)
          }
        >
          <Droppable droppableId='DraggableList'>
            {(provider) => (
              <div
                {...provider.droppableProps}
                className={className}
                ref={provider.innerRef}
                style={{ ...style }}
              >
                {data.map((ctx, index) => {
                  const ThisIcon = ctx?.meta?.icon;
                  return (
                    <Draggable
                      draggableId={'DraggableList-' + String(index)}
                      index={index}
                      key={index}
                    >
                      {(provider) => (
                        <div
                          {...provider.draggableProps}
                          className='DraggableList__card'
                          ref={provider.innerRef}
                          style={{
                            '--DraggableListCardIconColor': ctx?.meta?.color
                              ? ctx?.meta?.color
                              : '#c7c7c7',
                            ...provider.draggableProps.style,
                          }}
                        >
                          <div
                            {...provider.dragHandleProps}
                            className='DraggableList__card-drag-handler'
                          >
                            <svg height='20' viewBox='0 0 11 20' width='11'>
                              <path
                                d='M7,18a2,2,0,1,1,2,2A2,2,0,0,1,7,18ZM0,18a2,2,0,1,1,2,2A2,2,0,0,1,0,18Zm7-8a2,2,0,1,1,2,2A2,2,0,0,1,7,10ZM0,10a2,2,0,1,1,2,2A2,2,0,0,1,0,10ZM7,2A2,2,0,1,1,9,4,2,2,0,0,1,7,2ZM0,2A2,2,0,1,1,2,4,2,2,0,0,1,0,2Z'
                                fill='#caced5'
                              />
                            </svg>
                          </div>
                          <div className='DraggableList__card-inside'>
                            <div className='DraggableList__card-inside-icon'>
                              <Skeleton height='2rem' loading={!ctx?.meta?.name} type='image'>
                                {ThisIcon}
                              </Skeleton>
                            </div>
                            <div className='DraggableList__card-inside-text'>
                              <Skeleton height='2rem' loading={!ctx?.meta?.name} type='square' width='6rem'>
                                {ctx?.meta?.name}
                              </Skeleton>
                            </div>
                            <div className='DraggableList__card-inside-brand'>
                              {ctx.meta.count_brand !== 0 ? (
                                <React.Fragment>
                                  Brands
                                  <span>&nbsp;{ctx.meta.count_brand}</span>

                                </React.Fragment>
                              ) : <></>}
                              &nbsp;
                              &nbsp;
                              {ctx.meta.count_criteria !== 0 ? (
                                <React.Fragment>
                                  Criteria
                                  <span>&nbsp;{ctx.meta.count_criteria}</span>

                                </React.Fragment>
                              ) : <></>}
                            </div>
                            <button
                              className='DraggableList__card-inside-edit-icon'
                              onClick={() => internalHandleOnEdit(ctx)}
                            >
                              <EditData />
                            </button>
                            <button
                              className='DraggableList__card-inside-trash-icon'
                              onClick={() => internalHandleOnDelete(ctx)}
                            >
                              <TrashXMark />
                            </button>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </React.Fragment>
  );
}

DraggableList.defaultProps = {
  className: undefined,
  data: undefined,
  onChange: undefined,
  onDelete: undefined,
  style: undefined,
};
DraggableList.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  style: PropTypes.object,
};
