import { notification } from 'antd';
import _ from 'lodash';
import { GrianaService } from '../service';
import { GRIANA_CONSTANT } from './GRIANA_CONSTANT';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta <renta.yustika@bvarta.com>
 */

const grianaService = new GrianaService();

export const GRIANA_ACTION = {
  setStep: (payload) => {
    const { step = 0 } = payload;
    return {
      type: GRIANA_CONSTANT.SET_STEP,
      payload: {
        step,
      },
    };
  },

  setGid: (gid) => {
    return {
      type: GRIANA_CONSTANT.SELECT_GRID,
      payload: { gid },
    };
  },

  setGidRight: (gid_right) => {
    return {
      type: GRIANA_CONSTANT.SELECT_GRID_RIGHT,
      payload: { gid_right },
    };
  },

  getGridCollections: (payload) => {
    return async (dispatch) => {
      dispatch({
        type: GRIANA_CONSTANT.GRID_COLLECTIONS_REQUESTED,
        payload: { status_GET_AREA: 'LOADING' },
      });
      try {
        const data = await grianaService.gridAreaCollections(payload);
        const totalPage = Math.ceil(data.meta.total / payload.pageSize);
        const geoJson = data.geoJson;
        if (totalPage < 2) {
          dispatch({
            type: GRIANA_CONSTANT.GRID_COLLECTIONS_SUCCESS,
            payload: {
              status_GET_AREA: 'SUCCESS',
              geojson: geoJson,
            },
          });
        }

        for (let i = 2; i <= totalPage; i++) {
          const remapPayload = { ...payload, page: i };
          const newData = await grianaService.gridAreaCollections(remapPayload);
          const newGeojson = newData.geoJson;
          geoJson.features.push(...newGeojson.features);
          dispatch({
            type: GRIANA_CONSTANT.GRID_COLLECTIONS_SUCCESS,
            payload: {
              status_GET_AREA: 'LOADING',
              geojson: geoJson,
            },
          });
          if (i === totalPage) {
            dispatch({
              type: GRIANA_CONSTANT.GRID_COLLECTIONS_SUCCESS,
              payload: {
                status_GET_AREA: 'SUCCESS',
                geojson: geoJson,
              },
            });
          }
        }
      } catch (error) {
        dispatch({
          type: GRIANA_CONSTANT.GRID_COLLECTIONS_FAILED,
          payload: {
            status_GET_AREA: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },

  getGridCollectionsRight: (payload) => {
    return async (dispatch) => {
      dispatch({
        type: GRIANA_CONSTANT.GRID_COLLECTIONS_RIGHT_REQUESTED,
        payload: { status_GET_AREA_RIGHT: 'LOADING' },
      });
      try {
        const data = await grianaService.gridAreaCollections(payload);
        const totalPage = Math.ceil(data.meta.total / payload.pageSize);
        const geoJson = data.geoJson;
        if (totalPage < 2) {
          dispatch({
            type: GRIANA_CONSTANT.GRID_COLLECTIONS_RIGHT_SUCCESS,
            payload: {
              status_GET_AREA_RIGHT: 'SUCCESS',
              geojsonRight: geoJson,
            },
          });
        }

        for (let i = 2; i <= totalPage; i++) {
          const remapPayload = { ...payload, page: i };
          const newData = await grianaService.gridAreaCollections(remapPayload);
          const newGeojson = newData.geoJson;
          geoJson.features.push(...newGeojson.features);
          dispatch({
            type: GRIANA_CONSTANT.GRID_COLLECTIONS_RIGHT_SUCCESS,
            payload: {
              status_GET_AREA_RIGHT: 'LOADING',
              geojsonRight: geoJson,
            },
          });
          if (i === totalPage) {
            dispatch({
              type: GRIANA_CONSTANT.GRID_COLLECTIONS_RIGHT_SUCCESS,
              payload: {
                status_GET_AREA_RIGHT: 'SUCCESS',
                geojsonRight: geoJson,
              },
            });
          }
        }
      } catch (error) {
        dispatch({
          type: GRIANA_CONSTANT.GRID_COLLECTIONS_RIGHT_FAILED,
          payload: {
            status_GET_AREA_RIGHT: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },

  getGridDetail: (payload) => {
    return async (dispatch) => {
      dispatch({
        type: GRIANA_CONSTANT.GRID_DETAIL_REQUESTED,
        payload: { status_DETAIL: 'LOADING' },
      });
      try {
        const data = await grianaService.gridDetail(payload);
        dispatch({
          type: GRIANA_CONSTANT.GRID_DETAIL_SUCCESS,
          payload: {
            status_DETAIL: 'SUCCESS',
            gridDetail: data.result,
          },
        });
      } catch (error) {
        dispatch({
          type: GRIANA_CONSTANT.GRID_DETAIL_FAILED,
          payload: {
            status_DETAIL: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },

  getGridDetailRight: (payload) => {
    return async (dispatch) => {
      dispatch({
        type: GRIANA_CONSTANT.GRID_DETAIL_RIGHT_REQUESTED,
        payload: { status_DETAIL_RIGHT: 'LOADING' },
      });
      try {
        const data = await grianaService.gridDetail(payload);
        dispatch({
          type: GRIANA_CONSTANT.GRID_DETAIL_RIGHT_SUCCESS,
          payload: {
            status_DETAIL_RIGHT: 'SUCCESS',
            gridDetailRight: data.result,
          },
        });
      } catch (error) {
        dispatch({
          type: GRIANA_CONSTANT.GRID_DETAIL_RIGHT_FAILED,
          payload: {
            status_DETAIL_RIGHT: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },

  getPoiCollections: (payload) => {
    return async (dispatch, getState) => {
      dispatch({
        type: GRIANA_CONSTANT.POI_COLLECTIONS_REQUESTED,
        payload: { status_GET_POI: 'LOADING' },
      });
      try {
        let poiData = [];
        const data = await grianaService.poiCollections(payload);
        poiData = [...poiData, ...(data?.geoJson?.features || [])];
        const lastPage = Math.ceil(data?.meta?.total / 100 || 1);
        for (let i = 2; i <= lastPage; i++) {
          const nextData = await grianaService.poiCollections({
            ...payload,
            page: i,
          });
          poiData = [...poiData, ...(nextData?.geoJson?.features || [])];
        }
        dispatch({
          type: GRIANA_CONSTANT.POI_COLLECTIONS_SUCCESS,
          payload: {
            status_GET_POI: 'SUCCESS',
            poiCollections: [
              ...getState().features.MAPANA.GRIANA.poiCollections,
              ...poiData,
            ],
          },
        });
      } catch (error) {
        dispatch({
          type: GRIANA_CONSTANT.POI_COLLECTIONS_FAILED,
          payload: {
            status_GET_POI: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },

  getPoiCollectionsRight: (payload) => {
    return async (dispatch, getState) => {
      dispatch({
        type: GRIANA_CONSTANT.POI_COLLECTIONS_RIGHT_REQUESTED,
        payload: { status_GET_POI_RIGHT: 'LOADING' },
      });
      try {
        let poiData = [];
        const data = await grianaService.poiCollections(payload);
        poiData = [...poiData, ...(data?.geoJson?.features || [])];
        const lastPage = Math.ceil(data?.meta?.total / 100 || 1);
        for (let i = 2; i <= lastPage; i++) {
          const nextData = await grianaService.poiCollections({
            ...payload,
            page: i,
          });
          poiData = [...poiData, ...(nextData?.geoJson?.features || [])];
        }
        dispatch({
          type: GRIANA_CONSTANT.POI_COLLECTIONS_RIGHT_SUCCESS,
          payload: {
            status_GET_POI_RIGHT: 'SUCCESS',
            poiCollectionsRight: [
              ...getState().features.MAPANA.GRIANA.poiCollectionsRight,
              ...poiData,
            ],
          },
        });
      } catch (error) {
        dispatch({
          type: GRIANA_CONSTANT.POI_COLLECTIONS_RIGHT_FAILED,
          payload: {
            status_GET_POI_RIGHT: 'FAILED',
            message: error.response.data.message,
          },
        });
      }
    };
  },

  setWhiteList: (whiteListGid) => {
    return {
      type: GRIANA_CONSTANT.SET_WHITELIST_GID,
      payload: { whiteListGid },
    };
  },
  setWhiteListRight: (whiteListGidRight) => {
    return {
      type: GRIANA_CONSTANT.SET_WHITELIST_GID_RIGHT,
      payload: { whiteListGidRight },
    };
  },

  setAcceptable: (acceptable) => {
    return {
      type: GRIANA_CONSTANT.SET_ACCEPTABLE,
      payload: { acceptable },
    };
  },

  setAcceptableRight: (acceptableRight) => {
    return {
      type: GRIANA_CONSTANT.SET_ACCEPTABLE_RIGHT,
      payload: { acceptableRight },
    };
  },

  /**
   * @description For saving project object
   * @typedef ISaveProjectObject
   * @type {Object}
   * @property {number} id
   * @property {string|number} id_source
   * @property {string} name
   * @property {string|number} process_id
   * @property {string} project_type
   * @property {Object} history
   * @property {Object} history.form_set
   * @property {string} history.form_set.poi
   * @property {Object} history.form_set.density
   * @property {string|number} history.form_set.density.day
   * @property {Object} history.form_set.density.hour
   * @property {string|number} history.form_set.density.hour.max
   * @property {string|number} history.form_set.density.hour.min
   * @property {number} history.form_set.density.year
   * @property {number} history.form_set.density.month
   * @property {string} history.form_set.density.timeType
   * @property {boolean} history.form_set.density.isChecked
   * @property {string} history.form_set.density.resultType
   * @property {Object} history.form_set.project
   * @property {Object} history.form_set.project.name
   * @property {string|number} history.form_set.project.id_source
   * @property {Object} history.form_set.regency
   * @property {number} history.form_set.regency.id
   * @property {number} history.form_set.regency.code
   * @property {Object} history.form_set.scoring
   * @property {number} history.form_set.scoring.gridSize
   * @property {Object} history.form_set.scoring.categories
   * @property {number} history.form_set.scoring.categories.poi
   * @property {number} history.form_set.scoring.categories.ses
   * @property {number} history.form_set.scoring.categories.density
   * @property {number} history.form_set.scoring.categories.demography
   * @property {Object} history.form_set.province
   * @property {number} history.form_set.province.id
   * @property {number} history.form_set.province.code
   * @property {number|string} history.form_set.parameter
   * @property {Object} history.analyze_result
   * @property {number} history.id_step
   */
  saveProject: (param) => {
    return async (dispatch) => {
      dispatch({
        type: GRIANA_CONSTANT.SAVE_PROJECT_REQUESTED,
        payload: {
          status_POST: 'LOADING',
        },
      });
      try {
        let req = await grianaService.saveProject(param);
        dispatch({
          type: GRIANA_CONSTANT.SAVE_PROJECT_SUCCESS,
          payload: {
            status_POST: 'SUCCESS',
            message: req.message,
            formSaveProject: {
              ...param,
              id: req.result?.id || req?.dataInsert?.id,
            },
            data: req?.result,
          },
        });
      } catch (error) {
        dispatch({
          type: GRIANA_CONSTANT.SAVE_PROJECT_FAILED,
          payload: {
            status_POST: 'FAILED',
            message: error.response.data.message.replace(
              'Error Message : ',
              ''
            ),
          },
        });
      }
    };
  },

  /**
   * @description For Do Analyze Object
   * @typedef IPropsAnalyzeObject
   * @type {Object}
   * @property {string} province
   * @property {string} regency
   * @property {number} poi
   * @property {number} parameter
   * @property {number} id_source
   * @property {number} id_project
   * @property {Object} scoring
   * @property {number} scoring.gridSize
   * @property {Object} scoring.categories
   * @property {number} scoring.categories.poiCategory
   * @property {number} scoring.categories.demography
   * @property {number} scoring.categories.ses
   * @property {number} scoring.categories.density
   * @property {Object} peopleDensityParam
   * @property {string|number} peopleDensityParam.day
   * @property {string|number} peopleDensityParam.year
   * @property {string|number} peopleDensityParam.month
   * @property {Object} peopleDensityParam.hour
   * @property {string|number} peopleDensityParam.hour.min
   * @property {string|number} peopleDensityParam.hour.max
   * @property {'average'|'total'} peopleDensityParam.resultType
   */
  doAnalyze: (projectData, toAnalyzeReq) => {
    return async (dispatch) => {
      dispatch({
        type: GRIANA_CONSTANT.DO_ANALYZE_REQUESTED,
        payload: {
          status_ANALYZE: 'LOADING',
        },
      });
      try {
        await grianaService.saveProject(projectData);
        let analyzereq = await grianaService.doAnalyze(toAnalyzeReq);
        projectData.process_id = analyzereq.result.job_id || '';
        await grianaService.saveProject(projectData);
        dispatch({
          type: GRIANA_CONSTANT.DO_ANALYZE_SUCCESS,
          payload: {
            status_ANALYZE: 'SUCCESS',
          },
        });
      } catch (error) {
        notification.error({
          message: error.response.data.message,
        });
        dispatch({
          type: GRIANA_CONSTANT.DO_ANALYZE_FAILED,
          payload: { status_ANALYZE: 'FAILED' },
        });
      }
    };
  },

  selectGid: (gid) => {
    return (dispatch, getState) => {
      dispatch({
        type: GRIANA_CONSTANT.SELECT_GRID_TO_ARRAY,
        payload: {
          selected_gid: _.uniq([
            ...getState().features.MAPANA.GRIANA.selected_gid,
            gid,
          ]),
        },
      });
    };
  },
  selectGidRight: (gid) => {
    return (dispatch, getState) => {
      dispatch({
        type: GRIANA_CONSTANT.SELECT_GRID_TO_ARRAY,
        payload: {
          selected_gid_right: _.uniq([
            ...getState().features.MAPANA.GRIANA.selected_gid_right,
            gid,
          ]),
        },
      });
    };
  },

  setLastStep: (payload) => {
    const { lastStep = 0 } = payload;
    return {
      type: GRIANA_CONSTANT.SET_LAST_STEP,
      payload: {
        lastStep,
      },
    };
  },

  setIsShownOnMap: (payload) => {
    const { IS_SHOWN_ON_MAP = false } = payload;
    return {
      type: GRIANA_CONSTANT.SET_IS_SHOWN_ON_MAP,
      payload: {
        IS_SHOWN_ON_MAP,
      },
    };
  },
};
