import React from 'react';
import PropTypes from 'prop-types';
import './Breadcrumbs.scss';
import DynamicIcon from '@bvt-icons';

/**
 * @param {object} props
 * @param {any} props.data
 * @param {number} props.activeStep
 */
export default function Breadcrumbs(props) {
  const { activeStep, data } = props;
  return (
    <div className='step-breadcrumbs--wrapper'>
      {data.map((item, index) => {
        return (
          <div className='step-breadcrumbs--index' key={index}>
            <div className='step-breadcrumbs--text'>{item.step_name}</div>
            <div className='step-breadcrumbs--number'>
              {item.step_order === activeStep ? (
                <DynamicIcon
                  className='step-breadcrumbs--icon'
                  icon='success'
                />
              ) : (
                item.step_order
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

Breadcrumbs.propTypes = {
  activeStep: PropTypes.number,
  data: PropTypes.any,
};

Breadcrumbs.defaultProps = {
  activeStep: 0,
  data: [
    {
      id: 1,
      id_parent: 10,
      step_order: 1,
      step_name: 'Step Example 1',
      step_code: 'STEP_EXAMPLE_1',
    },
    {
      id: 2,
      id_parent: 10,
      step_order: 2,
      step_name: 'Step Example 2',
      step_code: 'STEP_EXAMPLE_2',
    },
    {
      id: 3,
      id_parent: 10,
      step_order: 3,
      step_name: 'Step Example 3',
      step_code: 'STEP_EXAMPLE_3',
    },
    {
      id: 4,
      id_parent: 10,
      step_order: 4,
      step_name: 'Step Example 4',
      step_code: 'STEP_EXAMPLE_4',
    },
    {
      id: 5,
      id_parent: 10,
      step_order: 5,
      step_name: 'Step Example 5',
      step_code: 'STEP_EXAMPLE_5',
    },
  ],
};
