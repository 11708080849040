import Resource from '../../../../../api/resource';

export class GrianaService extends Resource {
  constructor() {
    super('grid-analyze', '/api', 'api-poi', 'v2');
  }

  /**
   *
   * @param {object} filter
   * @param {number} filter.project_id
   * @param {string} filter.process_id
   * @param {number} filter.page
   * @param {number} filter.pageSize
   */
  gridAreaCollections(filter) {
    const remapFilter = {
      meta: {
        filter: {
          project_id: filter.project_id,
          process_id: filter.process_id,
        },
        page: filter.page,
        pageSize: filter.pageSize,
      },
    };
    const paramString = JSON.stringify(remapFilter);
    const params = encodeURIComponent(paramString);
    this.version = 'v2';
    this.uri = 'grid-analyze';
    this.setAdditionUri('/area-collections');
    return this.list({ params });
  }

  /**
   *
   * @param {object} payload
   * @param {number} payload.gid
   * @param {string} payload.uuid
   */
  gridDetail(payload) {
    const { gid, uuid } = payload;
    this.uri = 'grid-analyze';
    this.version = 'v2';
    this.setAdditionUri(`/properties/gid/${gid}/process-id/${uuid}`);
    return this.get();
  }

  /**
   *
   * @param {object} filter
   * @param {number} filter.project_id
   * @param {string} filter.process_id
   * @param {number} filter.page
   * @param {number} filter.limit
   * @param {number} filter.gid
   */
  poiCollections(filter) {
    const { gid, process_id, project_id, page, limit } = filter;
    const remapFilter = {
      meta: {
        filter: { project_id, process_id, gid },
        page,
        limit,
      },
    };
    this.uri = 'grid-analyze';
    this.setAdditionUri('/poi-collections');
    this.version = 'v2';
    const paramString = JSON.stringify(remapFilter);
    const params = encodeURIComponent(paramString);
    return this.list({ params });
  }

  saveProject(param) {
    this.version = 'v2';
    this.uri = 'project';
    this.additonalUri = '';
    return this.create(param);
  }

  doAnalyze(param) {
    this.version = 'v2';
    this.uri = 'grid-analyze';
    this.additonalUri = '/do-analyze/v3';

    return this.create(param);
  }
}
