import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConsumePoiService, IndustriesService } from '../service';
import { industryAction } from './industry.slice';
import { JSONcombine as InitialColor } from '../redux/GENSET_CONSUME_POI_ACTION';
import { getIcon } from '@bvt-shared/utils/iconUtils';

const conpoiService = new ConsumePoiService();
const industryService = new IndustriesService();

export const IndustryAsyncAction = {
  getList: createAsyncThunk('LI/PARSET/INDUSTRY/getList', async (_, thunk) => {
    try {
      const data = await conpoiService.getListIndustries();
      const dataWithColor = await data.results?.map((industryItem) => {
        const correspondingColorItem = InitialColor.find(
          (colorItem) => colorItem.id === industryItem.industry_id
        );
        if (correspondingColorItem) {
          return {
            id: industryItem.industry_id,
            code: industryItem.industry_code,
            name: industryItem.industry_name,
            icon: getIcon('poi', industryItem.industry_icon),
            status: industryItem.industry_status,
            initial: correspondingColorItem.initial,
            color: correspondingColorItem.color,
          };
        }
        return industryItem;
      });
      thunk.dispatch(industryAction.upsertMany(dataWithColor));
    } catch (error) {
      throw error.response?.data?.message || 'Failed get industries';
    }
  }),
  getDetail: createAsyncThunk(
    'LI/PARSET/INDUSTRY/getDetail',
    async (param, thunk) => {
      try {
        const data = await industryService.getDetailIndustry(param.id);
        if (Object.keys(data.result).length === 0) {
          throw 'Failed get industry';
        }
        thunk.dispatch(industryAction.upsertOne(data.result));
      } catch (error) {
        throw error?.response?.data?.message || 'Failed get industry';
      }
    }
  ),
  resetStatusAndMessage: createAsyncThunk(
    'LI/PARSET/INDUSTRY/resetStatusAndMessage',
    async (_, thunk) => {
      thunk.dispatch(
        industryAction.setStatus({ status: ['DETAIL', 'LIST'], type: 'IDLE' })
      );
      thunk.dispatch(industryAction.setMessage({ message: '' }));
    }
  ),
  removeAll: createAsyncThunk(
    'LI/PARSET/INDUSTRY/removeAll',
    async (_, thunk) => {
      thunk.dispatch(industryAction.removeAll());
    }
  ),
};
