/* eslint-disable max-lines */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { APP_STORE_PREFIX_CONSTANT } from '@bvt-shared/constant/APP_PREFIX_CONSTANT';


import { AsmanApiDatasource } from '../service/asmanApi.datasource';
import { ASMAN_STORE_CONSTANT } from '../constant/ASMAN_CONSTANT';
import { getIcon } from '@bvt-shared/utils/iconUtils';
import { resultByRadiusMapper } from '@bvt-features/sub-module/asman/utils/dataMappers';

const ds = new AsmanApiDatasource();

export const asmanAsyncActionList = createAsyncThunk(
  APP_STORE_PREFIX_CONSTANT + ASMAN_STORE_CONSTANT + '/asmanAsyncActionList',
  async (param, thunkApi) => {
    try {
      const resp = await ds.getListAsman(param);
      const mapedData = {
        type: 'FeatureCollection',
        features: resp.features.map(v => ({
          ...v,
          properties: {
            name: v.properties.nama_aset,
            address: v.properties.alamat,
            usage: v.properties.peruntukan,
            id: v.properties.id_gsd,
            areaWidth: parseFloat(v.properties.luas || ''),
            idleArea: v.properties.area,
            tenant: v.properties.tenant || '-',
            image: v.properties.list_foto
          }
        }))
      };
      return {
        ...resp,
        data: mapedData
      };
    } catch (error) {
      throw thunkApi.rejectWithValue(error);
    }
  }
);

export const asmanAsyncActiongAvailableRadiusInfoAndStatusAnalyze = createAsyncThunk(
  'LI/SUB-MODULE/' +
    ASMAN_STORE_CONSTANT +
    '/asmanAsyncActiongAvailableRadiusInfoAndStatusAnalyze',
  async (param, thunkApi) => {
    try {
      const res = await ds.getAvailableRadiusInfoAndStatusAnalyze(param);

      if(res?.detail?.includes('Asset not synronized')){
        throw { message: res?.detail, status: 422 };
      }

      return {
        ...res,
        data: res.radius_list || []
      };
    } catch (error) {
      throw thunkApi.rejectWithValue(error);
    }
  }
);

export const asmanAsyncActionGetDashboardResultByRadiusAndSet = createAsyncThunk(
  APP_STORE_PREFIX_CONSTANT +
    ASMAN_STORE_CONSTANT +
    '/asmanAsyncActionGetDashboardResultByRadiusAndSet',
  async (param, { rejectWithValue }) => {
    try {
      const q = new URLSearchParams();

      if (param.radius) {
        q.set('radius', String(param.radius));
      }

      q.set('type', 'asset_sima');
      const resAssetSima = ds.getDashboardResult({
        query: '?' + q,
        gsid: param.gsid
      });
      q.set('type', 'site_radius');
      const resAssetRadius = ds.getDashboardResult({
        query: '?' + q,
        gsid: param.gsid
      });

      const prm = await Promise.all([resAssetSima, resAssetRadius]);

      const detailFeatureAsset =
        prm?.[0]?.asset_sima?.features?.[0]?.properties?.properties || {};
      const detailFeatureElse =
        prm?.[1]?.site_radius?.features?.[0]?.properties?.properties || {};

      // Asman detail
      const asmanDetail = resultByRadiusMapper.mapAsmanDetail(detailFeatureAsset);

      // Education mapper
      const education = resultByRadiusMapper.mapEducation(detailFeatureElse);

      // Radius Info
      const radiusInfo = resultByRadiusMapper.mapRadiusInfo(detailFeatureElse);

      // Gender info
      const genderInfo = resultByRadiusMapper.mapGenderInfo(detailFeatureElse);

      // Ses Info
      const sesInfo = resultByRadiusMapper.mapSesInfo(detailFeatureElse);

      // Profession Info
      const professionInfo = resultByRadiusMapper.mapProfessionInfo(detailFeatureElse);

      // Zoning info
      const zoningInfo = resultByRadiusMapper.mapZoningInfo(detailFeatureElse);

      // Demography catchment
      const demographyCatchementInfo = resultByRadiusMapper.mapDemoCatchInfo(detailFeatureElse);

      // Land Value
      const landValueInfo = resultByRadiusMapper.mapLandValueInfo(detailFeatureElse);

      // Top info
      const topPoiInfo = resultByRadiusMapper.mapTopPoiInfo(detailFeatureElse);

      // Brand By industry info
      const brandByIndustryInfo = resultByRadiusMapper.mapBrandByIndustryInfo(detailFeatureElse);

      // Property Info
      const propertyPriceInfo = ['apartment', 'ruko', 'housing', 'office', 'coworking_space']
        .map(propertyType => resultByRadiusMapper.mapPropertyPrice(detailFeatureElse, propertyType))
        .filter(propertyPrices => propertyPrices.length > 0);
      // console.log('propertyPriceInfo', propertyPriceInfo);

      // Geojson for isochrone
      const dataGeojson = {
        type: 'FeatureCollection',
        features: []
      };
      if (prm?.[1]?.site_radius?.features?.[0]?.geometry) {
        dataGeojson?.features?.push({
          type: 'Feature',
          geometry: prm?.[1]?.site_radius?.features?.[0]?.geometry,
          properties: {}
        });
      }

      return {
        message: '',
        description: '',
        geojson: {
          type: 'FeatureCollection',
          features: []
        },
        meta: {},
        name: 'DatasourceResponse',
        payload: {},
        data: {
          selectedgsid: param.gsid || '',
          selectedRadius: param.radius,
          asmanDetail,
          education,
          radiusInfo,
          genderInfo,
          sesInfo,
          professionInfo,
          zoningInfo,
          demographyCatchementInfo,
          landValueInfo,
          topPoiInfo,
          brandByIndustryInfo,
          propertyPriceInfo,
          geojson: dataGeojson
        }
      };
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const asmanAsyncActionGetDashboardPoiCollection = createAsyncThunk(
  APP_STORE_PREFIX_CONSTANT +
    ASMAN_STORE_CONSTANT +
    '/asmanAsyncActionGetDashboardPoiCollection',
  async (param, { rejectWithValue }) => {
    try {
      const q = new URLSearchParams();

      if (param.radius) {
        q.set('radius', String(param.radius));
      }

      const [respPoiRadius, respPoiProperty] = await Promise.allSettled([
        ds.getDashboardResult({
          query: '?type=poi_radius&' + q,
          gsid: param.gsid
        }),
        ds.getDashboardResult({
          query: '?type=poi_property&' + q,
          gsid: param.gsid
        })
      ]);

      const returnData = [];

      if (respPoiRadius.status === 'fulfilled') {
        const dt = respPoiRadius?.value?.poi_radius?.features?.map(v => ({
          geometry: v.geometry,
          properties: {
            address: v.properties.properties?.address,
            category: {
              id: v.properties?.category_id,
              label: v.properties.properties?.category_name
            },
            group: v.properties.properties?.group_name,
            icon: getIcon('poi', `/Category/${v.properties.category_id}.svg`),
            nearestPublicTransport:
              v.properties.properties?.nearest_public_transport,
            name: v.properties.properties?.poi_name,
            price: v.properties.properties?.price,
            height: v.properties.properties?.Building_height
          },
          type: v.type,
          bbox: v.bbox,
          id: v.id
        }));

        returnData.push({
          category: 1,
          geojson: {
            type: 'FeatureCollection',
            features: dt
          }
        });
      }
      if (respPoiProperty.status === 'fulfilled') {
        const dt = respPoiProperty?.value?.poi_property?.features?.map(v => ({
          geometry: v.geometry,
          properties: {
            address: v.properties.properties?.address,
            category: {
              id: v.properties?.category_id,
              label: v.properties.properties?.category_name
            },
            group: v.properties.properties?.group_name,
            icon: getIcon('poi', `/Category/${v.properties.category_id}.svg`),
            nearestPublicTransport:
              v.properties.properties?.nearest_public_transport,
            name: v.properties.properties?.poi_name,
            price: v.properties.properties?.price,
            height: v.properties.properties?.Building_height
          },
          type: v.type,
          bbox: v.bbox,
          id: v.id
        }));

        returnData.push({
          category: 2,
          geojson: {
            type: 'FeatureCollection',
            features: dt
          }
        });
      }

      return {
        message: '',
        description: '',
        data: returnData
      };
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
