import React from 'react';
import { Button, Tooltip } from 'antd';

import './MainMapControl.component.scss';

export const MainMapControlComponent = React.forwardRef((props, ref) => {
  const { data, onClick } = props;
  const classChecker = (currentIndex, length) => {
    if (currentIndex === 0 && length > 1) {
      return 'top';
    } else if (currentIndex === length - 1 && length > 1) {
      return 'bottom';
    } else if (length > 1) {
      return 'middle';
    }
    return null;
  };
  return (
    <div className='MainMapControlcomponent__wrapper' ref={ref}>
      {data &&
        data.map((v, vidx) => (
          <div className='MainMapControlcomponent__inner' key={vidx}>
            {v?.map((v2, v2idx) => (
              <Tooltip
                destroyTooltipOnHide
                key={v2idx}
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                placement='left'
                title={!v2.disabled && v2.tooltip}
                trigger={['hover']}
              >
                <Button
                  className={`MainMapControlcomponent__button MainMapControlcomponent__button--
                  ${classChecker(v2idx, v.length)} ${
                v2.disabled && 'MainMapControlcomponent__button--disabled'
              }`}
                  onClick={() => onClick && !v2.disabled && onClick(v2.key)}
                >
                  {v2.children}
                </Button>
              </Tooltip>
            ))}
          </div>
        ))}
    </div>
  );
});

MainMapControlComponent.displayName = 'MainMapControlComponent';
