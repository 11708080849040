import { useDispatch, useSelector } from 'react-redux';
import { LEGEND_ACTION } from '../store/legend.slice';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

export const useLegend = () => {
  const state = useSelector(
    /**
     * @param {ReturnType<typeof import('../../../../redux/rootStore').rootStore.getState>} state
     */
    (state) => state.features.MAINMAP.LEGEND
  );

  const dispatch = useDispatch();

  /**
   * @description For legend list
   * @typedef ILegendItem
   * @type {object}
   * @property {string|number} key
   * @property {'CIRCLE'|'SQUARE'|'POLY'|'ICON'} type
   * @property {React.ReactElement} icon
   * @property {string} title
   * @property {string} color
   */

  /**
   * @param {object} param
   * @param {Array<ILegendItem>} param.data
   */
  const pushData = (param) => {
    dispatch(LEGEND_ACTION.pushDataToLegend(param));
  };

  /**
   *
   * @param {boolean} param
   */
  const toggleOpenLegend = (param) => {
    dispatch(LEGEND_ACTION.toggleOpenLegend({ isOpen: param }));
  };

  const clearData = () => {
    dispatch(LEGEND_ACTION.clearLegendData());
  };

  /**
   * @param {Array<string | number>} param
   */
  const removeDataByKey = (param) => {
    dispatch(LEGEND_ACTION.removeDataByIds({ key: param }));
  };

  /**
   * @param {Array<string | number>} param
   */
  const removeDataByPrefix = (param) => {
    dispatch(LEGEND_ACTION.removeDataByPrefix({ prefix: param }));
  };

  /**
   * @param {boolean} param
   */
  const isLoadingData = (param) => {
    dispatch(LEGEND_ACTION.onLoadLegendData({ isLoading: param }));
  };

  return {
    state,
    pushData,
    toggleOpenLegend,
    clearData,
    removeDataByKey,
    removeDataByPrefix,
    isLoadingData,
  };
};
