import { Divider } from 'antd';
import React from 'react';
import HomeLogo from '../../component/HomeLogo';
import MenuList from '../../component/MenuList';
import Notification from '../../component/Notification';
import ProjectName from '../../component/ProjectName';
import { UserAction } from '../../component/User';
import './MenuBarContainer.scss';

export default function MenuBarContainer() {
  return (
    <div className='menu-bar'>
      <div className='menu-bar__left'>
        <HomeLogo />
        <MenuList />
      </div>
      <div className='menu-bar__middle'>
        <ProjectName />
      </div>
      <div className='menu-bar__right'>
        <Divider type='vertical' />
        <Notification />
        <UserAction />
      </div>
    </div>
  );
}
