import { useAuth } from '@bvt-features/auth/hooks';
import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
/**
 *
 */
export default function AuthGateContainer() {
  const auth = useAuth();

  return auth.state.data.isAuthenticated && auth.state.data.lokasiintelligenceAccess ? (
    <Outlet />
  ) : (
    <Navigate to={'/401'} />
  );
}
