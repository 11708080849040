import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { IconClose } from '@bvt-icons';
import { linkBoxModule, linkBoxSetup } from './constans';
import { useNavigate } from 'react-router';
import { useAuth } from '@bvt-features/auth/hooks';

/**
 * Popup modules
 * @copyright PT Bhumi Varta Technology
 * @memberof organisms
 * @author Faisal <ahmad.faisal@bvarta.com>
 * @param {Object} props
 * @param {Function} props.handleCancel
 * @param {Boolean} props.show
 */

function PopupModules(props) {
  const { handleCancel, setShow, show } = props;
  const AUTH = useAuth();

  const navigate = useNavigate();

  const handleNavigate = (module) => {
    setShow(false);
    if (module.text === 'Grid Analysis') {
      navigate('/app/map/map-analytic/grid-analysis?step=0');
    }
    if (module.text === 'Site Profiling') {
      navigate('/app/map/map-analytic/site-profiling?step=0');
    }
    if (module.text === 'Site Profiling Mandala') {
      navigate('/app/map/map-analytic/site-profiling-mandala?step=0');
    }
    if (module.text === 'Setup Group POI') {
      navigate('/app/menu/parameter-setup/poi');
    }
    if (module.text === 'Setup Parameter') {
      navigate('/app/menu/parameter-setup/thematic');
    }
  };

  return (
    <Modal
      className='modalPopupModules'
      closeIcon={<IconClose background='white' />}
      footer={null}
      onCancel={handleCancel}
      title='Choose your modules'
      visible={show}
      zIndex={10000}
    >
      <div className='popupModules'>
        {AUTH.hasAccess('MAPANA') &&
          <>
            <div className='header'>Select module</div><div className='content'>
              {linkBoxModule.map((module, index) => (
                AUTH.hasAccess(module.permission) && <div
                  className={`module ${module.disabledIcon ? 'disabled' : ''}`}
                  key={index}
                  onClick={() => !module.disabledIcon && handleNavigate(module)}
                >
                  <div className='icon'>
                    {module.disabledIcon ? module?.disabledIcon : module.icon}
                  </div>
                  <div className='text'>{module.text}</div>
                </div>
              ))}
            </div>
          </>
        }
        {AUTH.hasAccess('GENSET') &&
          <>
            <div className='header'>Setup</div><div className='content'>
              {linkBoxSetup.map((module, index) => (
                AUTH.hasAccess(module.permission) && <div
                  className={`module ${module.disabledIcon ? 'disabled' : ''}`}
                  key={index}
                  onClick={() => !module.disabledIcon && handleNavigate(module)}
                >
                  <div className='icon'>
                    {module.disabledIcon ? module?.disabledIcon : module.icon}
                  </div>
                  <div className='text'>{module.text}</div>
                </div>
              ))}
            </div>
          </>
        }
      </div>
    </Modal>
  );
}

PopupModules.defaultProps = {
  handleCancel: () => {
    return;
  },
  setShow: () => {
    return;
  },
  show: false,
};

PopupModules.propTypes = {
  handleCancel: PropTypes.func,
  setShow: PropTypes.func,
  show: PropTypes.bool,
};

export default PopupModules;
