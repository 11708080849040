import  { useEffect } from 'react';
import { usePlace } from '../../hooks/usePlace';
import { useMap } from 'react-leaflet';
import * as Leaflet from 'leaflet';

export function getBounds(value) {
  return Leaflet.geoJSON(value).getBounds();
}

export function MapSearchBoundingMap() {
  const PLACE = usePlace();
  const initialMap = useMap();

  const listBoundingBox = [
    {
        country_id:2,
        "bounding_box":[
            [20.0295791692,100.408463169],
            [55.5514834662,170.543137242]
        ]
    },
    {
        country_id:3,
        "bounding_box":[
            [6.59975962975,98.170435826],
            [25.3520633001,110.33526981]
        ]
    },
    {
        country_id:1,
        "bounding_box":[
            [-16.59408141271846, 63.25195312500001],
            [16.088042220148818, 163.83300781250003],
        ]
    }
  ]

  useEffect(() => {
    const data = PLACE?.state.country_mandala;
    const bounding = listBoundingBox.find(({country_id}) => country_id == data.country_id)
    if(!bounding) return
    initialMap.setMaxBounds(bounding.bounding_box);
    initialMap.flyToBounds(bounding.bounding_box);
    
    // initialMap.setZoom(5)
  }, [PLACE.state.country_mandala]);

  return null;
}
