import INTERACTIVE_MAP_CONSTANT from '../constant';

const initialState = {
  set: {},
};

export function DIRECTION(state = initialState, action) {
  switch (action.type) {
    case INTERACTIVE_MAP_CONSTANT.DIRECTION:
      return action.payload;
    default:
      return state;
  }
}
