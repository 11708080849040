import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@bvt-atoms';
/**
 * @version 1
 * @copyright Bhumi Varta Technology
 * @memberof molecules
 * @author Randa <m.randa@bvarta.com>
 * @param {object} props
 * @param {string} props.text
 * @param {Boolean} props.value
 * @param {object} props.handler
 * @param {Function} props.handler.onChange
 * @param {'start'| 'end'} props.textPosition
 * @param {string} props.className
 * @param {string} props.colorActive
 * @param {object} props.size
 * @param {Number} props.size.width
 * @param {Number} props.size.height
 * @param {Number} props.size.ballWidth
 * @param {Number} props.size.ballHeight
 * @param {Number} props.size.ballLeft
 * @param {import('react').CSSProperties} props.style
 */
export const InputSwitch = (props) => {
  const [width, setWidth] = useState(32);
  const [height, setHeight] = useState(16);
  const [ballWidth, setBallWidth] = useState(12);
  const [ballHeight, setBallHeight] = useState(12);
  const [ballLeft, setBallLeft] = useState(18);

  const PopoverContent = () => {
    return (
      <div className='SwitchInput container-popover'>
        <div className='arrow arrow---down' />
        <p className='SwitchInput container-popover-text'>
          Contact our sales to subscribe
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (typeof props.size.width === 'number') {
      setWidth(props.size.width);
    }
    if (typeof props.size.height === 'number') {
      setHeight(props.size.height);
    }
    if (typeof props.size.ballWidth === 'number') {
      setBallWidth(props.size.ballWidth);
    }
    if (typeof props.size.ballHeight === 'number') {
      setBallHeight(props.size.ballHeight);
    }
    if (typeof props.size.ballLeft === 'number') {
      setBallLeft(props.size.ballLeft);
    }
  }, []);
  return (
    <>
      {props.secondClassName === 'disabled' ? (
        <Popover
          className='SwitchInput popover-wrapper'
          content={<PopoverContent />}
          header={false}
          placement='top-left'
          trigger='hover'
        >
          <div
            className={`SwitchInput__wrapper ${props.className}`}
            style={{ ...props.style }}
          >
            {props.text && props.textPosition === 'start' && (
              <div className='SwitchInput__text--start'>{props.text}</div>
            )}
            <div
              className={`SwitchInput__track ${props.secondClassName}`}
              onClick={() => props.handler.onChange(!props.value)}
              style={{
                background: props.value ? props.colorActive : '#E3E3E9',
                width: `${width}px`,
                height: `${height}px`,
              }}
            >
              <div
                className='SwitchInput__ball'
                style={{
                  left: props.value ? `${ballLeft}px` : '2px',
                  width: `${ballWidth}px`,
                  height: `${ballHeight}px`,
                }}
              />
            </div>
            {props.text && props.textPosition === 'end' && (
              <div className='SwitchInput__text--end'>{props.text}</div>
            )}
          </div>
        </Popover>
      ) : (
        <div
          className={`SwitchInput__wrapper ${props.className}`}
          style={{ ...props.style }}
        >
          {props.text && props.textPosition === 'start' && (
            <div className='SwitchInput__text--start'>{props.text}</div>
          )}
          <div
            className={`SwitchInput__track`}
            onClick={() => props.handler.onChange(!props.value)}
            style={{
              background: props.value ? props.colorActive : '#E3E3E9',
              width: `${width}px`,
              height: `${height}px`,
            }}
          >
            <div
              className='SwitchInput__ball'
              style={{
                left: props.value ? `${ballLeft}px` : '2px',
                width: `${ballWidth}px`,
                height: `${ballHeight}px`,
              }}
            />
          </div>
          {props.text && props.textPosition === 'end' && (
            <div className='SwitchInput__text--end'>{props.text}</div>
          )}
        </div>
      )}
    </>
  );
};

InputSwitch.propTypes = {
  className: PropTypes.string,
  colorActive: PropTypes.string,
  handler: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  secondClassName: PropTypes.string,
  size: PropTypes.objectOf({
    height: PropTypes.number,
    ballHeight: PropTypes.number,
    ballWidth: PropTypes.number,
    ballLeft: PropTypes.number,
  }),
  style: PropTypes.object,
  text: PropTypes.string,
  textPosition: PropTypes.oneOf(['start', 'end']),
  value: PropTypes.bool,
};
InputSwitch.defaultProps = {
  className: '-',
  colorActive: '#007E75',
  handler: {
    onChange: () => {},
  },
  size: {
    width: 32,
    height: 16,
    ballHeight: 12,
    ballWidth: 12,
    ballLeft: 18,
  },
  style: {},
  text: '',
  textPosition: 'start',
  value: false,
};
