import React from 'react';
import DynamicIcon from '@bvt-icons';
import './LegendContainer.scss';
import { useMap } from 'react-leaflet';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import { useLegend } from '@bvt-features/mainmap/legend/hooks/useLegend';
import { SymbolType } from '../../component/SymbolType';
import { Spin } from 'antd';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

export const LegendContainer = () => {
  const LEGEND = useLegend();
  const initialMap = useMap();
  const handleOpenLegend = () => {
    LEGEND.toggleOpenLegend(!LEGEND.state?.isOpen);
  };

  const mapp = useMainMap();

  const EmptyContent = () => {
    return <li className='bvt-map__legend__content__li center'>NO CONTENT</li>;
  };

  /**
   * @description For legend list
   * @typedef ILegendItem
   * @type {object}
   * @property {string|number} key
   * @property {'CIRCLE'|'SQUARE'|'POLY'|'ICON'} type
   * @property {React.ReactElement} icon
   * @property {string} title
   * @property {string} color
   */

  /**
   * @param {object} props
   * @param {Array<ILegendItem>} props.data
   */
  const FillContent = (props) => {
    const { data } = props;
    return data?.map((item, index) => {
      return (
        <li className='bvt-map__legend__content__li' key={index}>
          <div className='bvt-map__legend__content__li--icon'>
            <SymbolType
              color={item?.color}
              icon={item?.icon}
              key={item?.key}
              type={item?.type}
            />
          </div>
          <div className='bvt-map__legend__content__li--desc'>
            {item?.title || '-'}
          </div>
        </li>
      );
    });
  };

  return (
    <div
      className={`bvt-map__legend`}
      onMouseEnter={() => initialMap.scrollWheelZoom.disable()}
      onMouseLeave={() => initialMap.scrollWheelZoom.enable()}
      style={{
        right: mapp.state.activeMenu
          ? mapp.state.activeMenuWidth + 8 + 'px'
          : '0.5rem',
      }}
    >
      <div className='bvt-map__legend__wrapper'>
        <div
          className={`bvt-map__legend__content ${
            LEGEND.state?.isOpen ? 'show' : ''
          } scroll`}
        >
          {LEGEND.state?.isLoading ? (
            <Spin className='bvt-map__legend__content__loader' />
          ) : (
            <ul className='bvt-map__legend__content__ul'>
              {LEGEND.state?.data?.length === 0 ? (
                <EmptyContent />
              ) : (
                <FillContent
                  data={LEGEND.state?.data?.map((v) => {
                    return {
                      key: v?.key,
                      title: v?.title,
                      icon: v?.icon,
                      color: v?.color,
                      type: v?.type,
                    };
                  })}
                />
              )}
            </ul>
          )}
        </div>
        <div className='bvt-map__legend__action'>
          <button
            className={`bvt-map__legend__button ${
              LEGEND.state?.isOpen ? 'show' : ''
            }`}
            onClick={handleOpenLegend}
          >
            <div className='bvt-map__legend__button__container'>
              {LEGEND.state?.isOpen ? 'Hide Legend' : 'Show Legend'}
              <DynamicIcon
                className={`bvt-map__legend__button--arrow ${
                  LEGEND.state?.isOpen ? 'show' : ''
                }`}
                height='0.588rem'
                icon='arrow'
                width='0.588rem'
              />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
