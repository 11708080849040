import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Select } from 'antd';
import DynamicIcon from '@bvt-icons';
import { dummyData } from './dummyData';

/**
 * @version 1
 * @copyright Bhumi Varta Technology
 * @author Malik <malik.ibrahim@bvarta.com>
 * @param { object } props
 * @param { Boolean } props.allowClear
 * @param { string } props.className
 * @param { Array<{label?:string, value?:string, }> } props.data
 * @param { string|Number } props.defaultValue
 * @param { ()=>void } props.onChange
 * @param { ()=>void } props.onDropdownVisibleChange
 * @param { string } props.suffixIcon
 * @param { Boolean } props.disabled
 * @param { string|Number } props.value
 */

export const InputSelectOption = (props) => {
  const {
    allowClear,
    className,
    data,
    defaultValue,
    onChange,
    onDropdownVisibleChange,
    suffixIcon,
    disabled,
    value,
    bordered = false,
  } = props;
  return (
    <Select
      allowClear={allowClear}
      bordered={bordered}
      className={className}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
      onDropdownVisibleChange={onDropdownVisibleChange}
      options={data}
      popupClassName={className + '__dropdown-wrap'}
      showAction={['click']}
      suffixIcon={suffixIcon}
      value={value}
    />
  );
};

InputSelectOption.defaultProps = {
  allowClear: true,
  className: 'select-option',
  data: dummyData,
  defaultValue: '',
  disabled: false,
  onChange: () => {},
  onDropdownVisibleChange: () => {},
  suffixIcon: (
    <DynamicIcon height='0.375rem' icon='arrowfilled' width='0.563rem' />
  ),
  value: '',
};

InputSelectOption.propTypes = {
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.array,
  defaultValue: oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onDropdownVisibleChange: PropTypes.func,
  suffixIcon: PropTypes.element,
  value: oneOfType([PropTypes.string, PropTypes.number]),
};
