import Marker from '@bvt-features/mainmap/cluster/component/Marker';
import { useSIPRO } from '@bvt-features/mapana/sipro/hook';
import { ID_SOURCE_CONSTANT } from '@bvt-shared/constant/ID_SOURCE_CONSTANT';
import React, { Fragment, useEffect } from 'react';
import { LayerGroup, useMap } from 'react-leaflet';
import { useDroppableMarker } from '../hooks/useDroppbaleMarker';
import { usePlace } from '@bvt-features/mainmap/place/hooks/usePlace';

export default function DroppableMarker() {
  const PLACE = usePlace();
  const initialMap = useMap();
  const droppable_marker = useDroppableMarker();
  const sipro = useSIPRO();
  const idMap = initialMap.getContainer().id;

  const updateMapView = (activeMarkers) => {
    if (activeMarkers.length > 0) {
      const activeMarker = activeMarkers[activeMarkers.length - 1];
      initialMap?.setView([activeMarker?.latitude, activeMarker?.longitude], 15);
    }
  };

  useEffect(() => {
    const point = droppable_marker.state.point;
    if (point) {
      const coordinate = initialMap.containerPointToLatLng(point);
      droppable_marker.setCoordinate([
        coordinate?.lat?.toString(),
        coordinate?.lng?.toString(),
      ]);
    }
  }, [droppable_marker.state.point]);

  useEffect(() => {
    const coordinate = droppable_marker.state.coordinate;
    if (coordinate?.length === 2) {
      droppable_marker.checkIfAreaIsValid({
        id_source: ID_SOURCE_CONSTANT,
        latitude: coordinate?.[0],
        longitude: coordinate?.[1],
        countryId:PLACE.state.country_mandala.country_id
      });
    }
  }, [droppable_marker.state.coordinate]);

  useEffect(() => {
    const histories = droppable_marker.state?.historyList;
    const index = droppable_marker.state?.activeMarkerHistoryIndex || 0;
    if (histories?.length > 0) {
      const history = histories?.[index];
      initialMap?.setView([history?.lat, history?.long], 15);
    }
  }, [
    droppable_marker.state?.historyList,
    droppable_marker.state?.activeMarkerHistoryIndex
  ]);

  useEffect(() => {
    if (idMap?.includes('right')) {
      updateMapView(droppable_marker.state?.activeMarkersRight || []);
    } else {
      updateMapView(droppable_marker.state?.activeMarkers || []);
    }
  }, [
    droppable_marker.state?.activeMarkers,
    droppable_marker.state?.activeMarkersRight,
    sipro.state.status_GET_AREA
  ]);

  const Render = () => {
    return (
      <LayerGroup>
        <div
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{ width: '100%', height: '100%', zIndex: 1000 }}
        >
          {droppable_marker.state?.historyList?.length > 0 && (
            <Marker
              children={droppable_marker?.siteName || ''}
              colorIcon={droppable_marker.state?.marker_color}
              draggable={droppable_marker.state?.draggable}
              iconName={droppable_marker.state?.marker_icon}
              key={droppable_marker?.id || 1}
              loading={
                droppable_marker.state?.status_PLACE === 'LOADING' ||
                sipro.state.status_GET_POI === 'LOADING'
              }
              position={[
                droppable_marker.state?.historyList[
                  droppable_marker.state?.activeMarkerHistoryIndex
                ]?.lat,
                droppable_marker.state?.historyList[
                  droppable_marker.state?.activeMarkerHistoryIndex
                ]?.long,
              ]}
              setPosition={(e) => {
                droppable_marker.checkIfAreaIsValid({
                  id_source: ID_SOURCE_CONSTANT,
                  latitude: e?.lat?.toString(),
                  longitude: e?.lng?.toString(),
                  countryId:PLACE.state.country_mandala.country_id
                });
              }}
            />
          )}
          {!idMap?.includes('right') &&
            droppable_marker.state?.activeMarkers?.length > 0 &&
            droppable_marker.state?.activeMarkers?.map((marker) => (
              <Marker
                children={marker?.siteName || ''}
                colorIcon={marker?.IconColor}
                draggable={droppable_marker.state?.draggable}
                iconName={marker?.icon_type}
                key={marker?.id || 1}
                loading={
                  droppable_marker.state?.status_PLACE === 'LOADING' ||
                  sipro.state.status_GET_POI === 'LOADING'
                }
                position={[marker?.latitude, marker?.longitude]}
                setPosition={(e) => {
                  droppable_marker.checkIfAreaIsValid({
                    id_source: ID_SOURCE_CONSTANT,
                    latitude: e?.lat?.toString(),
                    longitude: e?.lng?.toString(),
                    countryId:PLACE.state.country_mandala.country_id
                  });
                }}
              />
            ))}
          {idMap?.includes('right') &&
            droppable_marker.state?.activeMarkersRight?.length > 0 &&
            droppable_marker.state?.activeMarkersRight?.map((marker) => (
              <Marker
                children={marker?.siteName || ''}
                colorIcon={marker?.IconColor}
                draggable={droppable_marker.state?.draggable}
                iconName={marker?.icon_type}
                key={marker?.id || 1}
                loading={
                  droppable_marker.state?.status_PLACE === 'LOADING' ||
                  sipro.state.status_GET_POI === 'LOADING'
                }
                position={[marker?.latitude, marker?.longitude]}
                setPosition={(e) => {
                  droppable_marker.checkIfAreaIsValid({
                    id_source: ID_SOURCE_CONSTANT,
                    latitude: e?.lat?.toString(),
                    longitude: e?.lng?.toString(),
                    countryId:PLACE.state.country_mandala.country_id
                  });
                }}
              />
            ))}
        </div>
      </LayerGroup>
    );
  };

  return <Fragment>{initialMap ? <Render /> : null}</Fragment>;
}
