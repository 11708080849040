import React from 'react';
import PropTypes from 'prop-types';
import './statusButton.scss';
/**
 *
 * @param {object} props
 * @param {()=>void} props.onClick
 * @param {boolean} props.visible
 * @param {boolean} props.isLoading
 */
export function StatusButton(props) {
  const { onClick, visible, isLoading } = props;
  return (
    <button className='task-analyze StatusButton' onClick={onClick}>
      {isLoading && (
        <svg
          className='statusButton__loading-icon'
          height='1rem'
          viewBox='0 0 15 15'
          width='1rem'
        >
          <g transform='translate(-1078.276 -305.421)'>
            <path
              d='M7.5,15A7.5,7.5,0,0,1,0,7.5,7.5,7.5,0,0,1,12.8,2.2a7.5,7.5,0,0,1,0,10.607A7.445,7.445,0,0,1,7.5,15Zm0-12.858A5.356,5.356,0,1,0,12.856,7.5,5.362,5.362,0,0,0,7.5,2.142Z'
              fill='#06534d'
              transform='translate(1078.276 305.421)'
            />
            <path
              d='M7.5,15A7.506,7.506,0,0,1,0,7.5,7.469,7.469,0,0,1,1.016,3.725L3,4.591A5.355,5.355,0,1,0,7.5,2.14V0a7.5,7.5,0,0,1,5.3,12.8A7.436,7.436,0,0,1,7.5,15Z'
              fill='#fff'
              transform='translate(1078.276 305.421)'
            />
          </g>
        </svg>
      )}
      <div className='statusButton__text'>Status</div>
      <svg
        className={`statusButton__drop-icon ${
          visible && 'statusButton__drop-icon--active'
        } `}
        fill='#fff'
        height='0.375rem'
        viewBox='0 0 11 6'
        width='0.75rem'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          color='#fff'
          d='M4.763.8A1,1,0,0,1,6.237.8l3.227,3.52A1,1,0,0,1,8.727,6H2.273a1,1,0,0,1-.737-1.676Z'
          transform='translate(11 6) rotate(180)'
        />
      </svg>
    </button>
  );
}
StatusButton.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  visible: PropTypes.bool,
};
