import { useFormik } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import './DatextStepFourThematic.scss';
import * as Yup from 'yup';
import { BaseSelectedCategoryCard } from '../BaseSelectedCategoryCard';
import { Dropdown } from '@bvt-shared/component/Dropdown';
import {
  cityLevelRegion,
  districtLevelRegion,
} from '@bvt-features/datex/constant/THEMATIC_RULE';
import { usePlace } from '@bvt-features/mainmap/place/hooks/usePlace';
import { getIcon } from '@bvt-shared/utils/iconUtils';
import DropdownInter from '@bvt-features/mainmap/place/component/DropdownInter';

/**
 * @copyright PT Bhumi Varta TEchnology
 * @author Malik<malik.ibrahim@bvarta.com>
 */

/**
 * @typedef IBaseKeyLabelIcon
 * @type {Object}
 * @property {string|number} key
 * @property {string} label
 * @property {string} icon
 */

/**
 * @typedef IBaseKeyValue
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 */

/**
 * @typedef IAreaObject
 * @type {Object}
 * @property {number} province
 * @property {number} city
 * @property {number} district
 * @property {number} village
 */

/**
 * @param {Object} props
 * @param {IAreaObject} props.data
 * @param {ICategoryObject} props.selectedCategories
 * @param {IBaseKeyLabelIcon} props.selectedCategories
 * @param {IBaseKeyValue} props.selectedSubcategories
 * @param {(valid:boolean)=>void} props.onValidInvalid
 * @param {boolean} props.loadingProvince
 * @param {Array<IBaseKeyValue>} props.provinceList
 * @param {(search:string)=>void} props.onSearchProvince
 * @param {boolean} props.loadingCity
 * @param {Array<IBaseKeyValue>} props.cityList
 * @param {(search:string)=>void} props.onSearchCity
 * @param {boolean} props.loadingDistrict
 * @param {Array<IBaseKeyValue>} props.districtList
 * @param {(search:string)=>void} props.onSearchDistrict
 * @param {boolean} props.loadingVillage
 * @param {Array<IBaseKeyValue>} props.villageList
 * @param {(search:string)=>void} props.onSearchVillage
 * @param {(data:IAreaObject)=>void} props.onChange
 */

export const DatextStepFourThematicRinjani = (props) => {
  const {
    data,
    onValidInvalid,
    cityList,
    onSearchCity,
    districtList,
    onSearchDistrict,
    provinceList,
    onSearchVillage,
    onChange,
    selectedCategories,
    selectedSubcategories,
  } = props;

  const PLACE = usePlace();

  const FORMIK = useFormik({
    initialValues: {
      province: undefined,
      city: undefined,
      district: undefined,
      village: undefined,
    },
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      province: Yup.number().required(),
    }),
  });

  const [inputProvince, setInputProvince] = useState('');
  const [inputCity, setInputCity] = useState('');
  const [inputDistrict, setInputDistrict] = useState('');

  useEffect(() => {
    FORMIK.setFieldValue('province', data?.province);
    FORMIK.setFieldValue('city', data?.city);
    FORMIK.setFieldValue('district', data?.district);
    FORMIK.setFieldValue('village', data?.village);
    FORMIK.validateForm();
  }, [data]);

  useEffect(() => {
    if (Object.keys(FORMIK.errors)?.length > 0 || PLACE.state.status_GET === 'LOADING') {
      onValidInvalid && onValidInvalid(false);
    } else {
      onValidInvalid && onValidInvalid(true);
    }
  }, [FORMIK, PLACE.state.status_GET]);

  const internalDebouncedSearchCity = useCallback(
    debounce(onSearchCity, 300),
    []
  );

  const internalDebouncedSearchDistrict = useCallback(
    debounce(onSearchDistrict, 300),
    []
  );

  const listProvince = () => {
    switch(PLACE.state.country_mandala.country_id){
      case(2):
        return provinceList.filter((item)=>item.label == "Kanto")
      case(3):
        return provinceList.filter((item)=>item.label == "Ho Chi Minh")
      default:
        return provinceList
    }
  }

  const listCity = () => {
    switch(PLACE.state.country_mandala.country_id){
      case(2):
        return cityList.filter((item)=>["Tokyo","Chiba"].includes(item.label))
      default:
        return cityList
    }
  }

  const getPlaceholder = (type) => {
    const countryId = PLACE.state.country_mandala.country_id
    const listPlaceholder = {
      1:{"province":"Province","city":"City","district":"District"},
      2:{"province":"Region","city":"Prefectur","district":"District"},
      3:{"province":"Province","city":"District","district":"Commune"}
    }
    return listPlaceholder[countryId][type]
  }

  return (
    <div className='DatextStepFourThematic'>
      <BaseSelectedCategoryCard
        description={`in ${selectedCategories?.label}`}
        icon={getIcon('thematic', selectedCategories?.icon)}
        showDescription={true}
        title={selectedSubcategories?.label}
      />
      <div className='DatextStepFourThematic__search'>
        <DropdownInter
          handler={{
            select: (obj) => {
              onChange &&
                onChange({
                  province: obj?.id,
                  city: undefined,
                  district: undefined,
                  village: undefined,
                  thematicArea: obj?.name,
                  thematicRegionCode: obj?.code,
                });
              obj &&
                obj?.id &&
                internalDebouncedSearchCity('', obj?.id);
            },
            clear: () => {
              onChange &&
                onChange({
                  province: undefined,
                  city: undefined,
                  district: undefined,
                  village: undefined,
                  thematicArea: undefined,
                  thematicRegionCode: undefined,
                });
            },
            input: setInputProvince,
          }}
          inputValue={
            provinceList?.find((v) => v?.value === FORMIK.values?.province)
              ?.label ?? inputProvince
          }
          options={listProvince().map((v) => ({
            id: v?.value,
            name: v?.label,
            code: v?.code,
            status: true
          }))}
          placeholder={getPlaceholder("province")}
          dispayName={`name`}
          displayId={`id`}
          selected={{
            id: provinceList?.find((v) => v?.value === FORMIK.values?.province)
              ?.value,
            name: provinceList?.find(
              (v) => v?.value === FORMIK.values?.province
            )?.label,
            code: provinceList?.find(
              (v) => v?.value === FORMIK.values?.province
            )?.code,
          }}
          type='provinsi'
          validation={true}
        />
        {cityLevelRegion.includes(
          selectedSubcategories?.label?.toLowerCase()
        ) ||
        districtLevelRegion.includes(
          selectedSubcategories?.label?.toLowerCase()
        ) ||
        selectedCategories?.label.toLowerCase() !== 'economics' && !(PLACE.state.country_mandala.country_id == 3 && selectedSubcategories.label == "Gender") ? (
            <DropdownInter
              className='DatextStepFourThematic__select'
              handler={{
                select: (obj) => {
                  onChange &&
                  onChange({
                    province: FORMIK.values?.province,
                    city: obj?.id,
                    district: undefined,
                    village: undefined,
                    thematicArea: obj?.name,
                    thematicRegionCode: obj?.code,
                  });
                  obj &&
                  obj?.id &&
                  internalDebouncedSearchDistrict('', obj?.id);
                },
                clear: () => {
                  onChange &&
                  onChange({
                    province: FORMIK.values?.province,
                    city: undefined,
                    district: undefined,
                    village: undefined,
                    thematicArea: undefined,
                    thematicRegionCode: undefined,
                  });
                },
                input: setInputCity,
              }}
              inputValue={
                cityList?.find((v) => v?.value === FORMIK.values?.city)?.label ??
              inputCity
              }
              options={listCity().map((v) => ({
                id: v?.value,
                name: v?.label,
                code: v?.code,
                status: true
              }))}
              dispayName={`name`}
              displayId={`id`}
              placeholder={getPlaceholder("city")}
              selected={{
                id: cityList?.find((v) => v?.value === FORMIK.values?.city)
                  ?.value,
                name: cityList?.find((v) => v?.value === FORMIK.values?.city)
                  ?.label,
                code: cityList?.find((v) => v?.value === FORMIK.values?.city)
                  ?.code,
              }}
              type='kota'
              validation={true}
            />
          ) : null}

        {((districtLevelRegion.includes(selectedSubcategories?.label?.toLowerCase()) 
        || selectedCategories?.label.toLowerCase() !== 'economics')) && PLACE.state.country_mandala.country_id == 1 && (
          <DropdownInter
            className='DatextStepFourThematic__select'
            handler={{
              select: (obj) => {
                onChange &&
                  onChange({
                    province: FORMIK.values?.province,
                    city: FORMIK.values?.city,
                    district: obj?.id,
                    village: undefined,
                    thematicArea: obj?.name,
                    thematicRegionCode: obj?.code,
                  });
              },
              clear: () => {
                onChange &&
                  onChange({
                    province: FORMIK.values?.province,
                    city: FORMIK.values?.city,
                    district: undefined,
                    village: undefined,
                    thematicArea: undefined,
                    thematicRegionCode: undefined,
                  });
              },
              input: setInputDistrict,
            }}
            inputValue={
              districtList?.find((v) => v?.value === FORMIK.values?.district)
                ?.label ?? inputDistrict
            }
            options={districtList.map((v) => ({
              id: v?.value,
              name: v?.label,
              code: v?.code,
              status: true
            }))}
            placeholder={getPlaceholder("district")}
            dispayName={`name`}
            displayId={`id`}
            selected={{
              id: districtList?.find(
                (v) => v?.value === FORMIK.values?.district
              )?.value,
              name: districtList?.find(
                (v) => v?.value === FORMIK.values?.district
              )?.label,
              code: districtList?.find(
                (v) => v?.value === FORMIK.values?.district
              )?.code,
            }}
            type='kecamatan'
          />
        )}
      </div>
    </div>
  );
};
