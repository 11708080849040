import { IconChevron } from '@bvt-assets/icon/icon-chevron';
import { IconSearchTypeTwo } from '@bvt-assets/icon/icon-search-2';
import { Input } from 'antd';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { BaseSelectedPOICategoryCard } from '../BaseSelectedPOICategoryCard';
import './DatexStepPOIAreaSelection.scss';
import * as Yup from 'yup';
import { SelectOption } from '@bvt-shared/component/SelectOption';
// import { GeoJsonAdvanceMap } from '../GeoJsonAdvanceMap/GeoJsonAdvanceMap';
// import { usePlace } from '@bvt-features/mainmap/place/hooks/usePlace';

/**
 * @copyright PT Bhumi Varta TEchnology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @typedef ICategoryObject
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 * @property {React.ReactElement} icon
 */

/**
 * @typedef IBaseKeyValue
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 */

/**
 * @typedef IAreaObject
 * @type {Object}
 * @property {number} province
 * @property {number} city
 * @property {number} district
 * @property {number} village
 */

/**
 * @param {Object} props
 * @param {IAreaObject} props.data
 * @param {(valid:boolean)=>void} props.onValidInvalid
 * @param {IBaseKeyValue} props.category
 * @param {IBaseKeyValue} props.subcategory
 * @param {IBrandObject} props.brand
 * @param {boolean} props.loadingProvince
 * @param {Array<IBaseKeyValue>} props.provinceList
 * @param {(search:string)=>void} props.onSearchProvince
 * @param {boolean} props.loadingCity
 * @param {Array<IBaseKeyValue>} props.cityList
 * @param {(search:string)=>void} props.onSearchCity
 * @param {boolean} props.loadingDistrict
 * @param {Array<IBaseKeyValue>} props.districtList
 * @param {(search:string)=>void} props.onSearchDistrict
 * @param {boolean} props.loadingVillage
 * @param {Array<IBaseKeyValue>} props.villageList
 * @param {(search:string)=>void} props.onSearchVillage
 * @param {(data:IAreaObject)=>void} props.onChange
 */

export const DatexStepPOIAreaSelection = (props) => {
  const {
    data,
    onValidInvalid,
    category,
    subcategory,
    brand,
    loadingCity,
    cityList,
    onSearchCity,
    loadingDistrict,
    districtList,
    onSearchDistrict,
    loadingProvince,
    provinceList,
    onSearchProvince,
    loadingVillage,
    villageList,
    onSearchVillage,
    onChange,
  } = props;

  const FORMIK = useFormik({
    initialValues: {
      province: undefined,
      city: undefined,
      district: undefined,
      village: undefined,
    },
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      province: Yup.number().required(),
    }),
  });

  // const PLACE = usePlace();

  useEffect(() => {
    FORMIK.setFieldValue('province', data?.province);
    FORMIK.setFieldValue('city', data?.city);
    FORMIK.setFieldValue('district', data?.district);
    FORMIK.setFieldValue('village', data?.village);
    FORMIK.validateForm();
  }, [data]);

  useEffect(() => {
    if (Object.keys(FORMIK.errors)?.length > 0) {
      onValidInvalid && onValidInvalid(false);
    } else {
      onValidInvalid && onValidInvalid(true);
    }
  }, [FORMIK]);


  const internalDebouncedSearchProvince = useCallback(
    debounce(onSearchProvince, 300),
    []
  );

  const internalDebouncedSearchCity = useCallback(
    debounce(onSearchCity, 300),
    []
  );

  const internalDebouncedSearchDistrict = useCallback(
    debounce(onSearchDistrict, 300),
    []
  );

  const internalDebouncedSearchVillage = useCallback(
    debounce(onSearchVillage, 300),
    []
  );

  return (
    <div className='DatexStepPOIAreaSelection'>
      <BaseSelectedPOICategoryCard
        description={[
          { key: subcategory?.key, value: subcategory?.value },
          { key: brand?.key, value: brand?.value },
        ]}
        header={'Currently Selected'}
        icon={category?.icon}
        showDescription={true}
        title={category?.value}
      />
      <div className='DatexStepPOIAreaSelection__search'>
        <SelectOption
          className='DatexStepPOIAreaSelection__select'
          dropdownRender={(menu) => (
            <React.Fragment>
              <Input
                className='DatexStepPOIAreaSelection__select--search'
                onChange={(e) => {
                  internalDebouncedSearchProvince(e.target.value);
                }}
                placeholder='Search'
                suffix={<IconSearchTypeTwo />}
              />
              {menu}
            </React.Fragment>
          )}
          loading={loadingProvince}
          onChange={(e, obj) => {
            onChange &&
              onChange({
                province: e,
                city: undefined,
                district: undefined,
                village: undefined,
                poiArea: obj?.label
              });
            internalDebouncedSearchCity('', e);
          }}
          options={provinceList || []}
          placeholder='Province'
          suffixIcon={<IconChevron />}
          value={provinceList?.find(
            (v) => v?.value === FORMIK.values?.province
          )}
        />
        <SelectOption
          className='DatexStepPOIAreaSelection__select'
          disabled={!FORMIK.values?.province}
          dropdownRender={(menu) => (
            <React.Fragment>
              <Input
                className='DatexStepPOIAreaSelection__select--search'
                onChange={(e) => {
                  internalDebouncedSearchCity(
                    e.target.value,
                    FORMIK.values?.province
                  );
                }}
                placeholder='Search'
                suffix={<IconSearchTypeTwo />}
              />
              {menu}
            </React.Fragment>
          )}
          loading={loadingCity}
          onChange={(e, obj) => {
            onChange &&
              onChange({
                province: FORMIK.values?.province,
                city: e,
                district: undefined,
                village: undefined,
                poiArea: obj?.label
              });
            internalDebouncedSearchDistrict(
              '',
              e
            );
          }}
          options={cityList || []}
          placeholder='City'
          suffixIcon={<IconChevron />}
          value={cityList?.find((v) => v?.value === FORMIK.values?.city)}
        />
        <SelectOption
          className='DatexStepPOIAreaSelection__select'
          disabled={!FORMIK.values?.city}
          dropdownRender={(menu) => (
            <React.Fragment>
              <Input
                className='DatexStepPOIAreaSelection__select--search'
                onChange={(e) => {
                  internalDebouncedSearchDistrict(
                    e.target.value,
                    FORMIK.values?.city
                  );
                }}
                placeholder='Search'
                suffix={<IconSearchTypeTwo />}
              />
              {menu}
            </React.Fragment>
          )}
          loading={loadingDistrict}
          onChange={(e, obj) => {
            onChange &&
              onChange({
                province: FORMIK.values?.province,
                city: FORMIK.values?.city,
                district: e,
                village: undefined,
                poiArea: obj?.label
              });
            internalDebouncedSearchVillage('', e);
          }}
          options={FORMIK.values?.city ? districtList : []}
          placeholder='District'
          suffixIcon={<IconChevron />}
          value={districtList?.find(
            (v) => v?.value === FORMIK.values?.district
          )}
        />
        <SelectOption
          className='DatexStepPOIAreaSelection__select'
          disabled={!FORMIK.values?.district}
          dropdownRender={(menu) => (
            <React.Fragment>
              <Input
                className='DatexStepPOIAreaSelection__select--search'
                onChange={(e) => {
                  internalDebouncedSearchVillage(
                    e.target.value,
                    FORMIK.values?.district
                  );
                }}
                placeholder='Search'
                suffix={<IconSearchTypeTwo />}
              />
              {menu}
            </React.Fragment>
          )}
          loading={loadingVillage}
          onChange={(e, obj) => {
            onChange &&
              onChange({
                province: FORMIK.values?.province,
                city: FORMIK.values?.city,
                district: FORMIK.values?.district,
                village: e,
                poiArea: obj?.label
              });
          }}
          options={villageList || []}
          placeholder='Village'
          suffixIcon={<IconChevron />}
          value={villageList?.find((v) => v?.value === FORMIK.values?.village)}
        />
      </div>
    </div>
  );
};
