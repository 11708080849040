import React, { useEffect } from 'react';
import { Radio, Skeleton } from 'antd';
import { DatexNoData } from '@bvt-features/datex/component/DatexNoData';
import './BaseOptionListItemThematic.scss';
import { Popover } from '@bvt-atoms';

/**
 * @company PT Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 */

/**
 * @typedef IBaseKeyValueIcon
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 * @property {string} icon
 */

/**
 * @typedef IBaseKeyValue
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 */

/**
 *
 * @param {Object} props
 * @param {Array<IBaseKeyValueIcon>} props.listItem
 * @param {(valid:Boolean)=>void} props.onValidOrInvalid
 * @param {(data:IBaseKeyValue)=>void} props.onChange
 * @param {IBaseKeyValue} props.selectedItem
 * @param {boolean} props.isLoading
 * @param {boolean} props.validation
 *
 */

export const BaseOptionListItemThematic = (props) => {
  const {
    selectedItem,
    listItem,
    isLoading,
    onChange,
    onValidOrInvalid,
    validation,
  } = props;

  useEffect(() => {
    if (selectedItem && selectedItem.key) {
      onValidOrInvalid && onValidOrInvalid(true);
    } else {
      onValidOrInvalid && onValidOrInvalid(false);
    }
  }, [selectedItem]);

  function compareStatus(a, b) {
    const statusA = a?.status || '';
    const statusB = b?.status || '';

    return statusB.localeCompare(statusA);
  }

  const PopoverContent = () => {
    return (
      <div className='BaseOptionListItemThematic container-popover'>
        <div className='BaseOptionListItemThematic arrow arrow---down' />
        <p className='BaseOptionListItemThematic container-popover-text'>
          Contact our sales to subscribe
        </p>
      </div>
    );
  };

  return (
    <div className='BaseOptionListItemThematic'>
      {isLoading
        ? Array.from([1, 2, 3, 4, 5]).map((v) => (
          <Skeleton.Input
            active={true}
            block={true}
            key={v}
            loading={isLoading}
            paragraph={false}
            style={{ height: '2rem', marginTop: '1rem' }}
            type='image'
          />
        ))
        : null}
      {!isLoading && !listItem?.length > 0 && (
        <DatexNoData title='Subcategory name not found' />
      )}
      <Radio.Group
        className='BaseOptionListItemThematic__radio-group'
        value={selectedItem?.key}
      >
        <div
          className='BaseOptionListItemThematic__radio-group--space'
          direction='vertical'
        >
          {listItem?.sort(compareStatus)?.map((w, i) => {
            return validation && !w.status ? (
              <Popover
                className='BaseOptionListItemThematic popover-wrapper'
                content={<PopoverContent />}
                header={false}
                key={i}
                placement='top-left'
                trigger='hover'
              >
                <div
                  className='BaseOptionListItemThematic__radio--wrapper'
                  key={i}
                >
                  <Radio
                    className={`BaseOptionListItemThematic__radio--item ${
                      selectedItem?.key === w?.key ? 'checked' : ''
                    }`}
                    disabled
                    onChange={() => {
                      onChange && onChange(w);
                    }}
                    value={w?.key}
                  >
                    <div className='BaseOptionListItemThematic__radio-wrapper'>
                      <div className='BaseOptionListItemThematic__radio-wrapper--left'>
                        <div className='BaseOptionListItemThematic__radio-item--title'>
                          {w?.label}
                        </div>
                      </div>
                    </div>
                  </Radio>
                </div>
              </Popover>
            ) : (
              <div
                className='BaseOptionListItemThematic__radio--wrapper'
                key={i}
              >
                <Radio
                  className={`BaseOptionListItemThematic__radio--item ${
                    selectedItem?.key === w?.key ? 'checked' : ''
                  }`}
                  onChange={() => {
                    onChange && onChange(w);
                  }}
                  value={w?.key}
                >
                  <div className='BaseOptionListItemThematic__radio-wrapper'>
                    <div className='BaseOptionListItemThematic__radio-wrapper--left'>
                      <div className='BaseOptionListItemThematic__radio-item--title'>
                        {w?.label}
                      </div>
                    </div>
                  </div>
                </Radio>
              </div>
            );
          })}
        </div>
      </Radio.Group>
    </div>
  );
};
