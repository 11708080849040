import React from 'react';
import PropTypes from 'prop-types';
import { Row, Tooltip } from 'antd';
import { DonutChart } from '@bvt-atoms';

export default function ProgressBox(props) {
  const { className, data, round, title } = props;
  if (round) {
    return <DonutChart data={data} title={title} />;
  } else {
    return (
      <div className='progress-box'>
        <div className={'progress-box__inner ' + className}>
          <Row>
            {data.map((item, index) => {
              return (
                <Tooltip key={index} title={item.type}>
                  <div
                    className={'progress-box__content '}
                    style={{
                      backgroundColor: item.color,
                      width: item.percent + '%',
                    }}
                  >
                    <span className='progress-box__text'>{item.type}</span>
                  </div>
                </Tooltip>
              );
            })}
          </Row>
        </div>
      </div>
    );
  }
}

ProgressBox.defaultProps = {
  className: '',
  data: [],
  round: false,
  title: '',
};

ProgressBox.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  round: PropTypes.bool,
  title: PropTypes.string,
};
