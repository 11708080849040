import React, { useState } from 'react';
import DynamicIcon from '@bvt-icons';
import { formattingDateTime } from '@bvt-utils';

import { NOTIFICATION_DUMMY } from '../../constant/NOTIFICATION_DUMMY';
import './Notification.scss';
import { Popover } from 'antd';
import { Popup } from '@bvt-atoms';

export default function Notification() {
  const [visibility, setVisibility] = useState(false);
  const [popup, setPopup] = useState(false);

  const ListNotification = (
    <div className={`menu-bar__notification__list `}>
      <table className='menu-bar__notification__table'>
        <tbody>
          {NOTIFICATION_DUMMY.map((item, index) => {
            return (
              <tr
                key={index}
                onClick={() => {
                  setVisibility(false);
                  setPopup(true);
                }}
              >
                <td>
                  <DynamicIcon
                    className='menu-bar__notification__icon'
                    height='1.25rem'
                    icon='headphone'
                    width='1.25rem'
                  />
                </td>
                <td>
                  <p>{item.from}</p>
                </td>
                <td>
                  <p>{formattingDateTime(item.created_at)}</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='menu-bar__notification__button'>
        <button onClick={() => setVisibility(false)}>
          <p>Show All</p>
        </button>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Popover
        className='menu-bar__notification__popover'
        content={ListNotification}
        onOpenChange={() => setVisibility((visibility) => !visibility)}
        open={visibility}
        placement='bottom'
        trigger='click'
      >
        <div
          className={`menu-bar__notification ${visibility ? 'selected' : ''}`}
          hidden
        >
          <DynamicIcon height='1.25rem' icon='notification' width='1.125rem' />
        </div>
      </Popover>
      <Popup
        cancelText='Close'
        className='menu-bar__notification__detail'
        handler={{
          onHide: () => {
            setPopup(false);
          },
        }}
        headerText='Display Message'
        show={popup}
        submitButton={false}
      >
        <div className='menu-bar__notification__detail__wrapper'>
          <div className='menu-bar__notification__detail__header'>
            <div className='menu-bar__notification__detail__header--left'>
              <p>Title</p>
              <b>Free Trial for 30 Days</b>
            </div>
            <div className='menu-bar__notification__detail__header--right'>
              <p>From</p>
              <b>Administrator</b>
            </div>
          </div>
          <div className='menu-bar__notification__detail__body date'>
            <span>Date</span>
            <b>23 October 2019</b>
          </div>
          <div className='menu-bar__notification__detail__body message'>
            <p>Dear customers.</p>
            <br />
            <p>Congratulations!,</p>
            <p>
              For existing customers, we have provided a trial feature for our
              new module, which can be used for free. To try it, please click
              the following link:
            </p>
            <br />
            <br />
            <p>Thanks,</p>
            <p>Administrator</p>
          </div>
        </div>
      </Popup>
    </React.Fragment>
  );
}
