export const YEAR_LIST = [
  {
    label: '2024',
    value: 2024,
  },
  {
    label: '2023',
    value: 2023,
  },
  {
    label: '2022',
    value: 2022,
  },
  {
    label: '2021',
    value: 2021,
  },
  {
    label: '2020',
    value: 2020,
  },
];
