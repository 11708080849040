import { useFormik } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import './DatextStepFourThematic.scss';
import * as Yup from 'yup';
import { BaseSelectedCategoryCard } from '../BaseSelectedCategoryCard';
import { Dropdown } from '@bvt-shared/component/Dropdown';
import {
  cityLevelRegion,
  districtLevelRegion,
} from '@bvt-features/datex/constant/THEMATIC_RULE';
import { usePlace } from '@bvt-features/mainmap/place/hooks/usePlace';
import { getIcon } from '@bvt-shared/utils/iconUtils';

/**
 * @copyright PT Bhumi Varta TEchnology
 * @author Malik<malik.ibrahim@bvarta.com>
 */

/**
 * @typedef IBaseKeyLabelIcon
 * @type {Object}
 * @property {string|number} key
 * @property {string} label
 * @property {string} icon
 */

/**
 * @typedef IBaseKeyValue
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 */

/**
 * @typedef IAreaObject
 * @type {Object}
 * @property {number} province
 * @property {number} city
 * @property {number} district
 * @property {number} village
 */

/**
 * @param {Object} props
 * @param {IAreaObject} props.data
 * @param {ICategoryObject} props.selectedCategories
 * @param {IBaseKeyLabelIcon} props.selectedCategories
 * @param {IBaseKeyValue} props.selectedSubcategories
 * @param {(valid:boolean)=>void} props.onValidInvalid
 * @param {boolean} props.loadingProvince
 * @param {Array<IBaseKeyValue>} props.provinceList
 * @param {(search:string)=>void} props.onSearchProvince
 * @param {boolean} props.loadingCity
 * @param {Array<IBaseKeyValue>} props.cityList
 * @param {(search:string)=>void} props.onSearchCity
 * @param {boolean} props.loadingDistrict
 * @param {Array<IBaseKeyValue>} props.districtList
 * @param {(search:string)=>void} props.onSearchDistrict
 * @param {boolean} props.loadingVillage
 * @param {Array<IBaseKeyValue>} props.villageList
 * @param {(search:string)=>void} props.onSearchVillage
 * @param {(data:IAreaObject)=>void} props.onChange
 */

export const DatextStepFourThematic = (props) => {
  const {
    data,
    onValidInvalid,
    cityList,
    onSearchCity,
    districtList,
    onSearchDistrict,
    provinceList,
    onSearchVillage,
    onChange,
    selectedCategories,
    selectedSubcategories,
  } = props;

  const PLACE = usePlace();

  const FORMIK = useFormik({
    initialValues: {
      province: undefined,
      city: undefined,
      district: undefined,
      village: undefined,
    },
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      province: Yup.number().required(),
    }),
  });

  const [inputProvince, setInputProvince] = useState('');
  const [inputCity, setInputCity] = useState('');
  const [inputDistrict, setInputDistrict] = useState('');

  useEffect(() => {
    FORMIK.setFieldValue('province', data?.province);
    FORMIK.setFieldValue('city', data?.city);
    FORMIK.setFieldValue('district', data?.district);
    FORMIK.setFieldValue('village', data?.village);
    FORMIK.validateForm();
  }, [data]);

  useEffect(() => {
    if (Object.keys(FORMIK.errors)?.length > 0 || PLACE.state.status_GET === 'LOADING') {
      onValidInvalid && onValidInvalid(false);
    } else {
      onValidInvalid && onValidInvalid(true);
    }
  }, [FORMIK, PLACE.state.status_GET]);

  const internalDebouncedSearchCity = useCallback(
    debounce(onSearchCity, 300),
    []
  );

  const internalDebouncedSearchDistrict = useCallback(
    debounce(onSearchDistrict, 300),
    []
  );

  const internalDebouncedSearchVillage = useCallback(
    debounce(onSearchVillage, 300),
    []
  );

  return (
    <div className='DatextStepFourThematic'>
      <BaseSelectedCategoryCard
        description={`in ${selectedCategories?.label}`}
        icon={getIcon('thematic', selectedCategories?.icon)}
        showDescription={true}
        title={selectedSubcategories?.label}
      />
      <div className='DatextStepFourThematic__search'>
        <Dropdown
          className='DatextStepFourThematic__select'
          handler={{
            select: (obj) => {
              onChange &&
                onChange({
                  province: obj?.id_provinsi,
                  city: undefined,
                  district: undefined,
                  village: undefined,
                  thematicArea: obj?.nama_provinsi,
                  thematicRegionCode: obj?.kode_provinsi,
                });
              obj &&
                obj?.id_provinsi &&
                internalDebouncedSearchCity('', obj?.id_provinsi);
            },
            clear: () => {
              onChange &&
                onChange({
                  province: undefined,
                  city: undefined,
                  district: undefined,
                  village: undefined,
                  thematicArea: undefined,
                  thematicRegionCode: undefined,
                });
            },
            input: setInputProvince,
          }}
          inputValue={
            provinceList?.find((v) => v?.value === FORMIK.values?.province)
              ?.label ?? inputProvince
          }
          options={provinceList.map((v) => ({
            id_provinsi: v?.value,
            nama_provinsi: v?.label,
            kode_provinsi: v?.code,
            status: v?.disabled
          }))}
          placeholder='Province'
          selected={{
            id: provinceList?.find((v) => v?.value === FORMIK.values?.province)
              ?.value,
            name: provinceList?.find(
              (v) => v?.value === FORMIK.values?.province
            )?.label,
            code: provinceList?.find(
              (v) => v?.value === FORMIK.values?.province
            )?.code,
          }}
          type='provinsi'
          validation={true}
        />
        {cityLevelRegion.includes(
          selectedSubcategories?.label?.toLowerCase()
        ) ||
        districtLevelRegion.includes(
          selectedSubcategories?.label?.toLowerCase()
        ) ||
        selectedCategories?.label.toLowerCase() !== 'economics' ? (
            <Dropdown
              className='DatextStepFourThematic__select'
              handler={{
                select: (obj) => {
                  onChange &&
                  onChange({
                    province: FORMIK.values?.province,
                    city: obj?.id_kota,
                    district: undefined,
                    village: undefined,
                    thematicArea: obj?.nama_kota,
                    thematicRegionCode: obj?.kode_kota,
                  });
                  obj &&
                  obj?.id_kota &&
                  internalDebouncedSearchDistrict('', obj?.id_kota);
                },
                clear: () => {
                  onChange &&
                  onChange({
                    province: FORMIK.values?.province,
                    city: undefined,
                    district: undefined,
                    village: undefined,
                    thematicArea: undefined,
                    thematicRegionCode: undefined,
                  });
                },
                input: setInputCity,
              }}
              inputValue={
                cityList?.find((v) => v?.value === FORMIK.values?.city)?.label ??
              inputCity
              }
              options={cityList.map((v) => ({
                id_kota: v?.value,
                nama_kota: v?.label,
                kode_kota: v?.code,
                status: v?.disabled
              }))}
              placeholder='City'
              selected={{
                id: cityList?.find((v) => v?.value === FORMIK.values?.city)
                  ?.value,
                name: cityList?.find((v) => v?.value === FORMIK.values?.city)
                  ?.label,
                code: cityList?.find((v) => v?.value === FORMIK.values?.city)
                  ?.code,
              }}
              type='kota'
              validation={true}
            />
          ) : null}
        {(districtLevelRegion.includes(
          selectedSubcategories?.label?.toLowerCase()
        ) ||
          selectedCategories?.label.toLowerCase() !== 'economics') && (
          <Dropdown
            className='DatextStepFourThematic__select'
            handler={{
              select: (obj) => {
                onChange &&
                  onChange({
                    province: FORMIK.values?.province,
                    city: FORMIK.values?.city,
                    district: obj?.id_kecamatan,
                    village: undefined,
                    thematicArea: obj?.nama_kecamatan,
                    thematicRegionCode: obj?.kode_kecamatan,
                  });
                obj &&
                  obj?.id_kecamatan &&
                  internalDebouncedSearchVillage('', obj?.id_kecamatan);
              },
              clear: () => {
                onChange &&
                  onChange({
                    province: FORMIK.values?.province,
                    city: FORMIK.values?.city,
                    district: undefined,
                    village: undefined,
                    thematicArea: undefined,
                    thematicRegionCode: undefined,
                  });
              },
              input: setInputDistrict,
            }}
            inputValue={
              districtList?.find((v) => v?.value === FORMIK.values?.district)
                ?.label ?? inputDistrict
            }
            options={districtList.map((v) => ({
              id_kecamatan: v?.value,
              nama_kecamatan: v?.label,
              kode_kecamatan: v?.code,
            }))}
            placeholder='District'
            selected={{
              id: districtList?.find(
                (v) => v?.value === FORMIK.values?.district
              )?.value,
              name: districtList?.find(
                (v) => v?.value === FORMIK.values?.district
              )?.label,
              code: districtList?.find(
                (v) => v?.value === FORMIK.values?.district
              )?.code,
            }}
            type='kecamatan'
          />
        )}
      </div>
    </div>
  );
};
