import React, { useEffect, useState } from 'react';
import { BaseCollapsePOI } from '../BaseCollapsePOI';
import { BaseSelectedPOICategoryCard } from '../BaseSelectedPOICategoryCard';
import { DatexStepPOIEditBrand } from '../DatexStepPOIEditBrand';
import './DatexStepPOICategoryCollection.scss';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 * @author Malik<malik.ibrahim@bvarta.com>
 */

/**
 * @typedef IBaseKeyValueIcon
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 * @property {string} icon
 */

/**
 * @typedef TDataPoi
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 * @property {string} icon
 * @property {Array<{key:String|Number,label:String,icon:String, brands: Array, criterias: Array}>} categories
 */

/**
 * @typedef IBrandObject
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 */

/**
 * @typedef ICriteriaObject
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 */

/**
 *
 * @param {Object} props
 * @param {Object} props.data
 * @param {IBaseKeyValueIcon} props.data.poiCategory
 * @param {IBaseKeyValueIcon} props.data.poiIndustry
 * @param {Array<TDataPoi>} props.data.poiGroup
 * @param {(valid:Boolean)=>void} props.onValidOrInvalid
 * @param {Array<IBrandObject>} props.selectedBrands
 * @param {Array<ICriteriaObject>} props.selectedCriterias
 * @param {(data:{poiGroup:IBaseKeyValueIcon,poiCategory: IBaseKeyValueIcon,poiBrands:Array<Number|String>,poiCriterias:Array<String>})=>void}  props.onCheckCategory
 * @param {(open:Boolean)=>void} props.onOpenEdit
 * @param {Boolean} props.openEdit
 * @param {IBaseKeyValueIcon} props.selectedGroup
 * @param {IBaseKeyValueIcon} props.selectedCategories
 * @param {({beforeSaveSelectedBrands:Array<String|Number>,beforeSaveSelectedCriterias:Array<String>})=>void} props.onSetDefaultValue
 *
 */

export const DatexStepPOICategoryCollection = (props) => {
  const {
    onValidOrInvalid,
    onCheckCategory,
    data,
    onOpenEdit,
    openEdit,
    onSetDefaultValue,
    selectedCategories,
    selectedGroup,
    selectedBrands,
    selectedCriterias,
  } = props;

  const [state, setState] = useState({
    filter: {
      keyword: '',
      type: undefined,
    },
    selectedBrands: [],
    selectedCriterias: [],
  });

  const [brandList, setBrandList] = useState([]);
  const [criteriaList, setCriteriaList] = useState([]);
  const [filteredBrandList, setFilteredBrandList] = useState([]);
  const [filteredCriteriaList, setFilteredCriteriaList] = useState([]);
  const [activeTab, setActiveTab] = useState('brand');

  const internalHandleOnClear = () => {
    if (activeTab === 'brand') {
      onCheckCategory &&
        onCheckCategory({
          poiBrands: [],
        });
    }
    if (activeTab === 'criteria') {
      onCheckCategory &&
        onCheckCategory({
          poiCriterias: [],
        });
    }
  };

  const internalHandleOnSelectAll = () => {
    if (activeTab === 'brand') {
      onCheckCategory &&
        onCheckCategory({
          poiBrands: [...brandList.map((el) => el.brand_id)],
        });
    }
    if (activeTab === 'criteria') {
      onCheckCategory &&
        onCheckCategory({
          poiCriterias: [...criteriaList.map((el) => el.criteria_id)],
        });
    }
  };

  useEffect(() => {
    if (state.filter) {
      setFilteredBrandList(
        brandList.filter((el) =>
          el?.brand_name
            ?.toLowerCase()
            ?.includes(state.filter.keyword.toLowerCase())
        )
      );
      setFilteredCriteriaList(
        criteriaList.filter((el) =>
          el?.criteria_value
            .toLowerCase()
            ?.includes(state.filter.keyword.toLowerCase())
        )
      );
    }
  }, [state.filter.keyword]);

  useEffect(() => {
    setState({
      ...state,
      selectedBrands: selectedBrands,
      selectedCriterias: selectedCriterias,
    });
  }, [selectedBrands, selectedCriterias]);

  useEffect(() => {
    if (!(filteredBrandList.length > 0 && filteredCriteriaList.length > 0)) {
      data?.poiGroup?.forEach((v) => {
        if (v?.key === selectedGroup?.key) {
          v?.categories?.forEach((w) => {
            if (w.key === selectedCategories?.key) {
              setBrandList(w?.brands || []);
              setFilteredBrandList(
                w?.brands?.filter((el) =>
                  el?.brand_name
                    ?.toLowerCase()
                    ?.includes(state.filter.keyword.toLowerCase() || '')
                ) || []
              );
              setCriteriaList(w?.criterias || []);
              setFilteredCriteriaList(
                w?.criterias?.filter((el) =>
                  el?.criteria_value
                    .toLowerCase()
                    ?.includes(state.filter.keyword.toLowerCase() || '')
                ) || []
              );
            }
          });
        }
      });
    }
  }, [data]);

  return (
    <div className='DatexStepPOICategoryCollection'>
      <BaseSelectedPOICategoryCard
        header={
          openEdit ? 'Editing Brands & Criteria for:' : 'Currently Selected'
        }
        icon={openEdit ? data?.poiCategory?.icon : data?.poiIndustry?.icon}
        onClear={internalHandleOnClear}
        onSelectAll={internalHandleOnSelectAll}
        showAction={openEdit}
        showDescription={openEdit}
        title={openEdit ? data?.poiCategory?.value : data?.poiIndustry?.label}
      />
      {openEdit ? (
        <DatexStepPOIEditBrand
          brandList={filteredBrandList.map((el) => {
            return {
              key: el.brand_id,
              value: el.brand_name,
            };
          })}
          criteriaList={filteredCriteriaList.map((el) => {
            return {
              key: el.criteria_id,
              value: el.criteria_value,
            };
          })}
          filter={state?.filter}
          onFilter={(e) => {
            setState({
              ...state,
              filter: {
                ...state?.filter,
                ...e,
              },
            });
          }}
          onSelectBrands={(e) => {
            let tempBrands = e;
            let tempBrandLabels = [];
            if (state.filter.keyword) {
              const BrandListWithKeyword = filteredBrandList.map(
                (el) => el.brand_id
              );
              const shoulRemoveID = BrandListWithKeyword.filter(
                (el) => !e.includes(el)
              );
              tempBrands = [
                ...new Set([
                  ...state.selectedBrands.filter(
                    (el) => !shoulRemoveID.includes(el)
                  ),
                  ...e,
                ]),
              ];

              setState({
                ...state,
                selectedBrands: tempBrands,
              });
            } else {
              setState({
                ...state,
                selectedBrands: e,
              });
            }
            tempBrandLabels = filteredBrandList
              .filter((el) => tempBrands.includes(el.brand_id))
              .map((el) => el?.brand_name);
            onCheckCategory &&
              onCheckCategory({
                poiBrands: tempBrands,
                poiBrandLabels: tempBrandLabels,
              });
          }}
          onSelectCriterias={(e) => {
            let resultCriterias = e;
            let tempCriteriaLabels = [];
            if (state.filter.keyword) {
              const criteriaListWithKeyword = filteredCriteriaList.map(
                (el) => el.criteria_id
              );
              const shoulRemoveID = criteriaListWithKeyword.filter(
                (el) => !e.includes(el)
              );
              resultCriterias = [
                ...new Set([
                  ...state.selectedCriterias.filter(
                    (el) => !shoulRemoveID.includes(el)
                  ),
                  ...e,
                ]),
              ];
              setState({
                ...state,
                selectedCriterias: resultCriterias,
              });
            } else {
              setState({
                ...state,
                selectedCriterias: e,
              });
            }
            tempCriteriaLabels = filteredCriteriaList
              .filter((el) => resultCriterias.includes(el.criteria_id))
              .map((el) => `${el?.criteria_key} | ${el?.criteria_value}`);
            onCheckCategory &&
              onCheckCategory({
                poiCriterias: resultCriterias,
                poiCriteriaLabels: tempCriteriaLabels,
              });
          }}
          onSetDefaultValue={(params) => {
            onSetDefaultValue && onSetDefaultValue(params);
          }}
          onTabChange={(e) => {
            setActiveTab(e);
          }}
          onValidOrInvalid={onValidOrInvalid}
          selectedBrands={state.selectedBrands}
          selectedCriterias={state.selectedCriterias}
        />
      ) : (
        <BaseCollapsePOI
          checked={{
            poiGroup: selectedGroup || {},
            poiCategory: selectedCategories || {},
          }}
          groupList={data?.poiGroup || []}
          onCheck={(e) => {
            setBrandList(e.brandList);
            setFilteredBrandList(e.brandList);
            setCriteriaList(e.criteriaList);
            setFilteredCriteriaList(e.criteriaList);
            onCheckCategory &&
              onCheckCategory({
                poiGroup: {
                  key: e?.poiGroup?.key,
                  value: e?.poiGroup?.value,
                  icon: e?.poiGroup?.icon,
                },
                poiCategory: {
                  key: e?.poiCategory?.key,
                  value: e?.poiCategory?.value,
                  icon: e?.poiCategory?.icon,
                  index: e?.poiCategory?.index + 1,
                },
                poiBrands: [...e.brandList.map((el) => el.brand_id)],
                poiCriterias: [...e.criteriaList.map((el) => el.criteria_id)],
                poiBrandLabels: [...e.brandList.map((el) => el.brand_name)],
                poiCriteriaLabels: [
                  ...e.criteriaList.map(
                    (el) => `${el?.criteria_key} | ${el?.criteria_value}`
                  ),
                ],
              });
          }}
          onEdit={() => {
            onOpenEdit && onOpenEdit(true);
          }}
          onValidInvalid={onValidOrInvalid}
          selectedBrands={selectedBrands}
          selectedCriterias={selectedCriterias}
        />
      )}
    </div>
  );
};
