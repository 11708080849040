import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For legend list
 * @typedef ILegendItem
 * @type {object}
 * @property {string|number} key
 * @property {'CIRCLE'|'SQUARE'|'POLY'|'ICON'} type
 * @property {React.ReactElement} icon
 * @property {string} title
 * @property {string} color
 */

/**
 * @typedef initialStateModel
 * @type {object}
 * @property {Array<ILegendItem>} data
 * @property {boolean} isOpen
 * @property {boolean} isLoading
 */

/**
 * @type {initialStateModel} initialState
 */
const initialState = {
  data: [],
  isOpen: false,
  isLoading: false,
};

export const legend_slice = createSlice({
  name: 'LI/mainmap/legend',
  initialState,
  reducers: {
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {ILegendItem} action.payload.data
     */
    pushDataToLegend(state, action) {
      const {
        payload: { data },
      } = action;
      state.data = [...state.data, ...data];
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {object} action.payload.isOpen
     */
    toggleOpenLegend(state, action) {
      const {
        payload: { isOpen },
      } = action;
      state.isOpen = isOpen;
    },
    clearLegendData(state) {
      state.data = [];
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {Array<number | string>} action.payload.key
     */
    removeDataByIds(state, action) {
      const { key } = action.payload;
      const filteredData = _.filter(state.data, (v) => !key.includes(v.key));
      state.data = filteredData;
    },
    /**
     * @param {Object} action
     * @param {Object} action.payload
     * @param {string} action.payload.prefix
     */
    removeDataByPrefix(state, action) {
      const { prefix } = action.payload;
      const filteredData = _.filter(
        state.data,
        (v) => !v?.key?.includes(prefix)
      );
      state.data = filteredData;
    },
    /**
     * @param {Object} action
     * @param {Object} action.payload
     * @param {boolean} action.payload.isLoading
     */
    onLoadLegendData(state, action) {
      const { isLoading } = action.payload;
      state.isLoading = isLoading;
    },
  },
});

export const { reducer: LEGEND_REDUCER, actions: LEGEND_ACTION } = legend_slice;
