import INTERACTIVE_PROJECT_CONSTANT from './constant';

const initialState = {
  name: '',
  project_type: '',
  history: {
    form_set: {},
    analyze_result: {},
    id_step: 0,
  },
};

export function TEMP_PROJECT(state = initialState, action) {
  switch (action.type) {
    case INTERACTIVE_PROJECT_CONSTANT.TEMP_PROJECT:
      return action.payload;
    default:
      return state;
  }
}
