import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { poiSearchAsyncAction } from './poiSearch.asyncAction';

const adapter = createEntityAdapter({
  selectId: (item) => item.poi_id,
  sortComparer: (a, b) => a.poi_name.localeCompare(b.poi_name)
});

const initialState = {
  entities: {},
  ids: [],
  message: '',
  meta: {},
  selectedId: null,
  isShowAll: false,
  status: {
    LIST: 'IDLE',
  },
};

const poiSearchSlice = createSlice({
  name: 'LI/INDUSTRY',
  initialState,
  reducers: {
    addone: adapter.addOne,
    addMany: adapter.addMany,
    setOne: adapter.setOne,
    setMany: adapter.setMany,
    setAll: adapter.setAll,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    setMessage: (state, action) => {
      const { payload } = action;
      state.message = payload.message || '';
    },
    setStatus: (state, action) => {
      const { payload } = action;
      if (payload.status) {
        payload.status.map((v) => {
          state.status[v] = payload.type;
        });
      }
    },
    setSelectedId: (state, action) => {
      const { payload } = action;
      state.selectedId = payload.id;
    },
    setIsShowAll: (state, action) => {
      const { payload } = action;
      state.isShowAll = payload.isShowAll;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(poiSearchAsyncAction.getList.pending, (state) => {
        state.status.LIST = 'LOADING';
      })
      .addCase(poiSearchAsyncAction.getList.rejected, (state, { error }) => {
        state.status.LIST = 'FAILED';
        state.message = error.message || 'Failed get POI List';
      })
      .addCase(poiSearchAsyncAction.getList.fulfilled, (state) => {
        state.status.LIST = 'SUCCESS';
        state.message = 'Succesfully get POI List';
      });
  }
});

export const { reducer: poiSearchReducer, actions: poiSearchAction } = poiSearchSlice;
