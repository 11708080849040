/* eslint-disable indent */
import { Button } from 'antd';
import React, { useState, useEffect } from 'react';
import './BaseDraggableCardDatex.scss';
import { IconEye } from '@bvt-assets/icon/icon-eye';
import { IconHighlight } from '@bvt-assets/icon/icon-highlight';
import { IconPencilOutlined } from '@bvt-assets/icon/icon-pencilOutlined';
import { InputSelectOption } from '@bvt-shared/component/InputSelectOption';
import { IconTriangleDown } from '@bvt-assets/icon/icon-triangleDown';
import { IconTriangleUp } from '@bvt-assets/icon/icon-triangleUp';
import { IconProcessing } from '@bvt-assets/icon/icon-processing';
import { CarDatexTelcoDataDetail } from '../CardDatexTelcoDataDetail';
import { IconTelcoData } from '@bvt-assets/icon/icon-telcoData';
import { IconPOIData } from '@bvt-assets/icon/icon-poiData';
import { IconTrash } from '@bvt-assets/icon/icon-trash';
import { IconMapMarker } from '@bvt-assets/icon/icon-mapMarker';
import { CardDatexPOIDataDetail } from '../CardDatexPOIDataDetail';
import { PreviewDetailPOIData } from '../PreviewDetailPOIData';
import { IconThematicData } from '@bvt-assets/icon/icon-thematicData';
import { RangeSlider } from '@bvt-atoms';
import { CardDatexThematicDataDetail } from '../CardDatexThematicDataDetail';
import { PreviewDetailThematicData } from '../PreviewDetailThematicData/PreviewDetailThematicData';
import { ImageBox } from '@bvt-shared/component/ImageBox';

/**
 * @copyright Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 * @param {Object} props
 * @param {Object} props.detail
 * @param {String} props.detail.area
 * @param {String} props.detail.date
 * @param {String} props.detail.time
 * @param {Array<{time: string, counts: number}>}  props.detail.densityArray
 * @param {Array<string>}  props.detail.time_list
 * @param {Number} props.detail.totalDevices
 * @param {String} props.detail.catchmentTimeType
 * @param {String} props.detail.catchmentBy
 * @param {String} props.detail.poiIndustry
 * @param {String} props.detail.poiGroup
 * @param {String} props.detail.poiCategory
 * @param {Array<{key:String|Number,value:String,total:Number}>} props.detail.poiData
 * @param {React.ReactElement} props.detail.poiCategoryIcon
 * @param {String} props.detail.poiBrand
 * @param {String} props.detail.poiCriteria
 * @param {String} props.detail.poiArea
 * @param {Number} props.detail.poiTotal
 * @param {Object} props.detail.thematicCategory
 * @param {Number} props.detail.thematicCategory.id
 * @param {String} props.detail.thematicCategory.label
 * @param {Object} props.detail.thematicSubCategory
 * @param {Number} props.detail.thematicSubCategory.id
 * @param {String} props.detail.thematicSubCategory.label
 * @param {String} props.detail.thematicItems
 * @param {String} props.detail.thematicItemsLabel
 * @param {String} props.detail.thematicSubCategory.additional
 * @param {Object} props.detail.thematicRecord
 * @param {number} props.detail.thematicRecord.id
 * @param {string} props.detail.thematicRecord.name
 * @param {number} props.detail.thematicRecord.value
 * @param {string} props.detail.thematicRecord.color
 * @param {Array<string|number>} props.detail.thematicYears
 * @param {Object} props.selectOption
 * @param {Array} props.selectOption.data
 * @param {String} props.selectOption.value
 * @param {'IDLE' | 'LOADING' | 'SUCCESS' | 'FAILED'} props.isLoading
 * @param {Boolean} props.isShowVisibility
 * @param {'TELCO'} props.type
 * @param {Function} props.onDelete
 * @param {(val: String) => void} props.onSelectOptionChange
 * @param {Function} props.onEdit
 * @param {Function} props.onHighlight
 * @param {(val: Boolean) => void} props.onVisible
 * @param {(val: number) => void} props.onSliderChange


 */
const BaseDraggableCardDatex = (props) => {
  const {
    selectOption,
    type,
    onDelete,
    onEdit,
    onHighlight,
    onVisible,
    onSelectOptionChange,
    onSliderChange,
    options,
    detail,
    isLoading,
    isShowVisibility,
    id,
  } = props;

  const [toggleArrowUpDown, setToggleArrowUpDown] = useState(false);
  const [toggleCardDetail, setToggleCardDetail] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [dataSource, setDataSource] = useState([]);

  const handleOnChangeSelect = (val) => {
    onSelectOptionChange && onSelectOptionChange(val);
  };

  const handleToggleDetail = () => {
    isShowVisibility && setToggleCardDetail(!toggleCardDetail);
  };

  const internalHandleOnVisible = () => {
    setToggleCardDetail(false);
    onVisible && onVisible(!isShowVisibility);
  };

  const handleLoading = () => {
    if (isLoading === 'LOADING') {
      return (
        <div className='BaseCardDatex__body-right-loading'>
          <IconProcessing />
        </div>
      );
    } else if (isLoading !== 'LOADING' && toggleCardDetail) {
      return <IconTriangleUp />;
    } else {
      return <IconTriangleDown />;
    }
  };

  useEffect(() => {
    if (detail?.densityArray) {
      let densityArray = detail.densityArray;
      if (detail?.catchmentBy?.toLowerCase() !== 'average') {
        densityArray = densityArray.filter((v) =>
          detail?.time_list?.includes(v?.time)
        );
      }
      const max = Math.max(densityArray.map((item) => item.counts));
      const tempArray = [];
      densityArray.forEach((item, index) => {
        let dateTime = new Date(item.time).getDate();
        if (
          ['Specific Time', 'Popular Time'].includes(detail?.catchmentTimeType)
        ) {
          dateTime = `${('0' + new Date(item.time).getHours()).slice(-2)}.${(
            '0' + new Date(item.time).getMinutes()
          ).slice(-2)}`;
        }
        tempArray.push({
          key: index,
          date: dateTime,
          total: item.counts,
          max: max,
        });
      });
      setDataSource(tempArray);
    }
  }, [detail.densityArray]);

  useEffect(() => {
    if (options?.layerActiveId === id) {
      setToggleCardDetail(true);
    } else {
      setToggleCardDetail(false);
    }
  }, [options?.layerActiveId]);

  useEffect(() => {
    if (isLoading === 'LOADING') {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [isLoading]);

  const classIfDisabled = checkIfDisabled(isShowVisibility, isDisabled);

  return (
    <div aria-disabled={true} className='BaseCardDatex__container'>
      <div className='BaseCardDatex__main'>
        <div
          className={`BaseCardDatex__left${
            !isShowVisibility ? '--disabled' : ''
          }`}
        >
          <svg
            fill='none'
            height='5'
            viewBox='0 0 4 5'
            width='4'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='1.9998' cy='2.37919' fill='#A0A6A9' r='1.81034' />
          </svg>
          <svg
            fill='none'
            height='5'
            viewBox='0 0 4 5'
            width='4'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='1.9998' cy='2.37919' fill='#A0A6A9' r='1.81034' />
          </svg>
          <svg
            fill='none'
            height='5'
            viewBox='0 0 4 5'
            width='4'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='1.9998' cy='2.37919' fill='#A0A6A9' r='1.81034' />
          </svg>
        </div>
        <div className='BaseCardDatex__right'>
          <div className='BaseCardDatex__header'>
            <div className={`BaseCardDatex__header-left${classIfDisabled}`}>
              {type === 'TELCO' && <IconTelcoData />}
              {(type === 'POI' || type === 'POI_RINJANI') && <IconPOIData />}
              {['THEMATIC_RINJANI', 'THEMATIC'].includes(type) && (
                <IconThematicData />
              )}
              <span
                className={`BaseCardDatex__header-left-text${classIfDisabled}`}
              >
                {type === 'TELCO' && 'Telco Data'}
                {(type === 'POI' || type === 'POI_RINJANI') &&
                  'Point of Interest'}
                {['THEMATIC_RINJANI', 'THEMATIC'].includes(type) && 'Thematic'}
              </span>
            </div>
            <div className='BaseCardDatex__header-right'>
              <Button
                className={`BaseCardDatex__header-right-btn${classIfDisabled}`}
                disabled={!isShowVisibility || isDisabled}
                onClick={onHighlight}
              >
                <IconHighlight />
              </Button>
              <Button
                className={`BaseCardDatex__header-right-btn${
                  !isShowVisibility && !isDisabled ? '--vsb-lock' : ''
                }${isDisabled ? '--disabled' : ''}`}
                onClick={internalHandleOnVisible}
              >
                <IconEye />
              </Button>
              <Button
                className={`BaseCardDatex__header-right-btn${classIfDisabled}`}
                disabled={!isShowVisibility || isDisabled}
                onClick={onEdit}
              >
                <IconPencilOutlined />
              </Button>
              <Button
                className={`BaseCardDatex__header-right-btn${classIfDisabled}`}
                disabled={!isShowVisibility || isDisabled}
                onClick={onDelete}
              >
                <IconTrash />
              </Button>
            </div>
          </div>
          {detail?.thematicYears?.length > 1 && isLoading !== 'LOADING' ? (
            <div className='BaseCardDatex__body-top'>
              <span className='BaseCardDatex__body-top-title'>Year</span>
              <RangeSlider
                height={'.625rem'}
                max={detail?.thematicYears?.length - 1}
                min={0}
                onChange={(v) => {
                  onSliderChange && onSliderChange(Number(v));
                }}
                step={1}
                value={detail?.thematicSelectedYearIndex}
                width={'100%'}
              />
              <div className='BaseCardDatex__body-top-years'>
                {detail?.thematicYears?.map((item, idx) => {
                  const id = idx++;
                  return <span key={id}>{item}</span>;
                })}
              </div>
            </div>
          ) : null}
          <div className='BaseCardDatex__body'>
            <div className='BaseCardDatex__body-left'>
              {type === 'TELCO' && (
                <React.Fragment>
                  <ImageBox className='BaseCardDatex__body-left__icon' src='legend-telco'/>
                  <div className='BaseCardDatex__body-left-info'>
                    <span className='BaseCardDatex__body-left-info-text-title'>
                      {detail?.telcoDataType}
                    </span>
                  </div>
                </React.Fragment>
              )}
              {(type === 'POI' || type === 'POI_RINJANI') && (
                <React.Fragment>
                  <img alt='' src={detail?.poiCategoryIcon} width={36} />
                  <div className='BaseCardDatex__body-left-info'>
                    <span className='BaseCardDatex__body-left-info-text-title'>
                      {detail?.poiCategory}
                    </span>
                    <span className='BaseCardDatex__body-left-info-text-subtitle'>
                      {detail?.poiGroup}
                    </span>
                  </div>
                  <div className='BaseCardDatex__body-left-info--label'>
                    <IconMapMarker />
                    <span>{detail?.poiArea}</span>
                  </div>
                </React.Fragment>
              )}
              {['THEMATIC_RINJANI', 'THEMATIC'].includes(type) && (
                <div className='BaseCardDatex__body-left-thematic'>
                  <InputSelectOption
                    allowClear={false}
                    className='BaseCardDatex__body-left__select-option'
                    data={selectOption.data}
                    disabled={!isShowVisibility}
                    onChange={handleOnChangeSelect}
                    onDropdownVisibleChange={() =>
                      setToggleArrowUpDown(!toggleArrowUpDown)
                    }
                    suffixIcon={
                      toggleArrowUpDown ? (
                        <IconTriangleUp />
                      ) : (
                        <IconTriangleDown />
                      )
                    }
                    value={selectOption.value}
                  />
                  <div className='BaseCardDatex__body-left-thematic-group'>
                    <div className='BaseCardDatex__body-left-info'>
                      <span className='BaseCardDatex__body-left-info-text-title'>
                        {detail?.thematicSubCategory?.label}
                      </span>
                      <span className='BaseCardDatex__body-left-info-text-subtitle'>
                        {detail?.thematicCategory?.label}
                      </span>
                    </div>
                    <div className='BaseCardDatex__body-left-info--label'>
                      <IconMapMarker />
                      <span>{detail?.thematicArea}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='BaseCardDatex__body-right'>
              <div
                className='BaseCardDatex__body-right-icon'
                onClick={() => handleToggleDetail()}
                style={{ cursor: !isShowVisibility ? 'not-allowed' : '' }}
              >
                {handleLoading()}
              </div>
            </div>
          </div>
        </div>
      </div>
      {toggleCardDetail && type === 'TELCO' && (
        <CarDatexTelcoDataDetail
          area={detail?.area}
          catchmentBy={
            detail?.catchmentBy
              ? `
            ${detail?.catchmentBy?.[0]?.toUpperCase()}${detail?.catchmentBy?.substring(
                  1
                )}
          `
              : detail?.catchmentBy
          }
          catchmentTimeType={detail?.catchmentTimeType}
          dataSource={dataSource}
          date={detail?.date}
          gridDetail={options?.layerActiveId === id ? options?.gridDetail: {}}
          layerLegend={detail?.telcoLayerLegend}
          time={detail?.time}
          totalDevices={detail?.totalDevices}
        />
      )}
      {toggleCardDetail && (type === 'POI' || type === 'POI_RINJANI') && (
        <React.Fragment>
          <PreviewDetailPOIData
            area={detail?.poiArea}
            brand={detail?.poiBrand}
            category={detail?.poiCategory}
            categoryIcon={detail?.poiCategoryIcon}
            data={detail?.poiData}
            total={detail?.poiTotal}
          />
          <CardDatexPOIDataDetail
            brand={detail?.poiBrand}
            category={detail?.poiCategory}
            criteria={detail?.poiCriteria}
            group={detail?.poiGroup}
            industry={detail?.poiIndustry}
            total={detail?.poiTotal}
          />
        </React.Fragment>
      )}
      {toggleCardDetail && ['THEMATIC', 'THEMATIC_RINJANI'].includes(type) && (
        <React.Fragment>
          <CardDatexThematicDataDetail
            data={[
              {
                key: 'Data Type',
                value: 'Thematic',
              },
              {
                key: 'Category',
                value: detail?.thematicCategory?.label,
              },
              {
                key: 'Subcategory',
                value: detail?.thematicSubCategory?.label,
              },
              {
                key: 'Items',
                value: detail?.thematicItemsLabel?.join(', '),
              },
            ]}
          />
          {detail?.thematicRecord ? (
            <PreviewDetailThematicData
              data={detail?.thematicRecord}
              labelTotal='Total'
              total={detail?.thematicRecord?.reduce(
                (sum, el) => sum + el?.count,
                0
              )}
            />
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
};

const checkIfDisabled = (isShowVisibility, isDisabled) => {
  return !isShowVisibility || isDisabled ? '--disabled' : '';
};

export { BaseDraggableCardDatex };
