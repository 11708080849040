import React, { Fragment, useEffect } from 'react';
import { usePlace } from '../../hooks/usePlace';
import { GeoJSON, useMap, Popup } from 'react-leaflet';
import _, { isEmpty } from 'lodash';
import * as Leaflet from 'leaflet';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import { useProject } from '@bvt-features/mapp/project/hooks/useProject';

export function getBounds(value) {
  return Leaflet.geoJSON(value).getBounds();
}

export function GeoJsonMapAdvanceSearch() {
  const PLACE = usePlace();
  const initialMap = useMap();
  const MAINMAP = useMainMap();
  const PROJECT = useProject()

  const styleFinder = _.find(
    MAINMAP.state.layerVisibilityList,
    (v) => v.key === 'ADM_BOUNDARY'
  );

  /**
   * @type {import('leaflet').PathOptions}
   */
  const Style = {
    color: '#748DA6',
    stroke: 1,
    fillColor: '#748DA6',
    fillOpacity: styleFinder?.isActive ? styleFinder?.opacity : 0,
    opacity: styleFinder?.isActive ? styleFinder?.opacity : 0,
  };

  useEffect(() => {
    const data = PLACE?.state.advance.list;
    const step_last = PROJECT.state.detail.id_last_step
    if (data?.polygon_provinsi) {
      if (!isEmpty(data.polygon_provinsi) !== false) {
        !(PLACE.state.country_mandala.country_id !== 1 && step_last === 7) && initialMap.flyToBounds(getBounds(data.polygon_provinsi));
        PLACE.setGeojson({ provinsi: data?.polygon_provinsi });
      }
    }
    if (data?.polygon_kota) {
      if (!isEmpty(data.polygon_kota) !== false) {
        !(PLACE.state.country_mandala.country_id !== 1 && step_last === 7) && initialMap.flyToBounds(getBounds(data.polygon_kota));
        PLACE.setGeojson({ kota: data?.polygon_kota });
      }
    }

    if (data?.polygon_kecamatan) {
      if (!isEmpty(data.polygon_kecamatan) !== false) {
        !(PLACE.state.country_mandala.country_id !== 1 && step_last === 7) && initialMap.flyToBounds(getBounds(data.polygon_kecamatan));
        PLACE.setGeojson({ kecamatan: data?.polygon_kecamatan });
      }
    }
    if (data?.polygon_desa) {
      if (!isEmpty(data.polygon_desa) !== false) {
        !(PLACE.state.country_mandala.country_id !== 1 && step_last === 7) && initialMap.flyToBounds(getBounds(data.polygon_desa));
        PLACE.setGeojson({ desa: data?.polygon_desa });
      }
    }
  }, [PLACE.state.advance]);

  useEffect(() => {
    if (PLACE?.state?.geojson?.provinsi === null) {
      MAINMAP.removeLayer('ADM_BOUNDARY');
    } else {
      MAINMAP.registerLayer({
        isActive: true,
        key: 'ADM_BOUNDARY',
        opacity: 0.3,
        title: 'Adminstrative boundary',
      });
    }


    return () => {
      MAINMAP.removeLayer('ADM_BOUNDARY');
    };
  }, [PLACE.state.geojson.provinsi]);

  const Render = () => {
    return (
      <React.Fragment>
        {!isEmpty(PLACE.state.geojson.provinsi) && (
          <GeoJSON
            data={
              !isEmpty(PLACE.state.geojson.provinsi) !== null
                ? PLACE.state.geojson.provinsi
                : null
            }
            ref={(ref)=>{
              if(ref && ref.setZIndex){
                ref.setZIndex(1);
              }
            }}
            style={() => Style}
          >
            <Popup closeButton={false}>
              <center>
                {!isEmpty(PLACE.state.geojson.provinsi) !== false
                  ? PLACE.state.geojson.provinsi.properties.displayName
                  : null}
              </center>
            </Popup>
          </GeoJSON>
        )}
        {!isEmpty(PLACE.state.geojson.kota) && (
          <GeoJSON
            data={
              !isEmpty(PLACE.state.geojson.kota) !== null
                ? PLACE.state.geojson.kota
                : null
            }
            ref={(ref)=>{
              if(ref && ref.setZIndex){
                ref.setZIndex(1);
              }
            }}
            style={() => Style}
          >
            <Popup closeButton={false}>
              <center>
                {!isEmpty(PLACE.state.geojson.kota) !== false
                  ? PLACE.state.geojson.kota.properties.displayName
                  : null}
              </center>
            </Popup>
          </GeoJSON>
        )}
        {!isEmpty(PLACE.state.geojson.kecamatan) && (
          <GeoJSON
            data={
              !isEmpty(PLACE.state.geojson.kecamatan) !== null
                ? PLACE.state.geojson.kecamatan
                : null
            }
            ref={(ref)=>{
              if(ref && ref.setZIndex){
                ref.setZIndex(1);
              }
            }}
            style={() => Style}
          >
            <Popup closeButton={false}>
              <center>
                {!isEmpty(PLACE.state.geojson.kecamatan) !== false
                  ? PLACE.state.geojson.kecamatan.properties.displayName
                  : null}
              </center>
            </Popup>
          </GeoJSON>
        )}
        {!isEmpty(PLACE.state.geojson.desa) && (
          <GeoJSON
            data={
              !isEmpty(PLACE.state.geojson.desa) !== null
                ? PLACE.state.geojson.desa
                : null
            }
            ref={(ref)=>{
              if(ref && ref.setZIndex){
                ref.setZIndex(1);
              }
            }}
            style={() => Style}
          >
            <Popup closeButton={false}>
              <center>
                {!isEmpty(PLACE.state.geojson.desa) !== false
                  ? PLACE.state.geojson.desa.properties.displayName
                  : null}
              </center>
            </Popup>
          </GeoJSON>
        )}
      </React.Fragment>
    );
  };
  return (
    <Fragment>
      {initialMap && styleFinder?.isActive ? <Render /> : null}
    </Fragment>
  );
}
