import React, { useEffect } from 'react';
import { usePlace } from '../../../hooks/usePlace';
import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import '../Dropdown.scss';
import Dropdown from '../../Dropdown';
import DropdownInter from '../../DropdownInter';

export function SearchCityInter(props) {
  const PLACE = usePlace();
  const {show = "all"} = props
  useEffect(() => {
    if (props.idProvince) {
      getData('', props.idProvince);
    }
  }, [props.idProvince]);

  useEffect(() => {
    if (!isEmpty(PLACE.state.params.kota)) {
      props.isCity && props.isCity(PLACE.state.params.kota);
    }
  }, [PLACE.state.params.kota]);

  const getData = (value, idProvince) => {
    const params = {
      meta: {
        filter: { keyword: value,parent_id: idProvince, country_id:PLACE.state.country_mandala.country_id  }
      },
    };
    PLACE.get_city_inter({ params });
  };

  const getDataDebounce = debounce(getData, 800);

  const getPlaceholderCity = () => {
    const countryId = PLACE.state.country_mandala.country_id
    const listNameRegion = ["City","Prefecture","District"]
    return listNameRegion[countryId-1]
  }

  const listOption = () => {
    if(show == "all"){
      return PLACE.state.city?.list
    }else {
      if(PLACE.state.country_mandala.country_id == 2){
        return PLACE.state.city?.list?.filter((item)=>["Tokyo"].includes(item.name))
      }else if(PLACE.state.country_mandala.country_id == 3){
        return PLACE.state.city?.list
      }
    }
  }

  return (
      <DropdownInter
        disabled={isEmpty(PLACE.state.params.provinsi)}
        handler={{
          select: (ctx) => {
            PLACE.setParams({ type: 'kota', ...ctx });
            PLACE.removeParams({ kecamatan: null });
            PLACE.removeParams({ desa: null });
            props.isCity && props.isCity(ctx);
            props?.setDistrict?.(null);
            props?.setVillage?.(null);
          },
          clear: () => {
            PLACE.removeParams({ kota: null });
            PLACE.removeParams({ kecamatan: null });
            PLACE.removeParams({ desa: null });
            props.isCity && props.isCity(null);
            props.setDistrict && props.setDistrict(null);
            props.setVillage && props.setVillage(null);
          },
          search: (value) => {
            getDataDebounce(value, props.idProvince);
          },
        }}
        isLoading={PLACE.state.city?.status_GET === 'LOADING'}
        options={listOption()}
        placeholder={getPlaceholderCity()}
        dispayName={`name`}
        displayId={`id`}
        selected={PLACE.state.params.kota && {
          id: PLACE.state.params.kota[`id`],
          name: PLACE.state.params.kota[`name`],
          code: PLACE.state.params.kota[`$code`],
          status: true,
        }}
        type='kota'
      />
  );
}

SearchCityInter.propTypes = {
  data: PropTypes.object,
  idProvince: PropTypes.number,
  isCity: PropTypes.func,
  setDistrict: PropTypes.func,
  setVillage: PropTypes.func,
  validation: PropTypes.bool,
  show:PropTypes.string
};
