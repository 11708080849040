import React, { useState } from 'react';
import { Empty, Modal } from 'antd';

import { SharedBaseCardTypeOneComponent } from '@bvt-shared/component/SharedBaseCardTypeOne.component';
import './AsmanCardPropertyPrice.component.scss';

export const AsmanCardPropertyPriceComponent = props => {
  const { data, isLoading } = props;
  const [state, setState] = useState({ detail: [] });
  return (
    <SharedBaseCardTypeOneComponent
      isLoading={isLoading}
      showIcon
      title={
        <React.Fragment>
          <span>Property Price</span>
        </React.Fragment>
      }
    >
      {data && data?.length > 0 ? (
        data?.map((v, idx) => (
          <div className='AsmanCardPropertyPriceComponent__content' key={`price-${idx}`}>
            {v.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Property</th>
                    <th>
                      <div className='AsmanCardPropertyPriceComponent__row'>
                        <div className='AsmanCardPropertyPriceComponent__line' />
                        Type
                      </div>
                    </th>
                    <th>
                      <div className='AsmanCardPropertyPriceComponent__row'>
                        <div className='AsmanCardPropertyPriceComponent__line' />
                        Price
                      </div>
                    </th>
                    <th>
                      <div className='AsmanCardPropertyPriceComponent__row'>
                        <div className='AsmanCardPropertyPriceComponent__line' />
                        Total
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {v.map(item => (
                    <tr key={item.name}>
                      <td className='AsmanCardPropertyPriceComponent__text'>
                        {item.name}
                      </td>
                      <td className='AsmanCardPropertyPriceComponent__text'>
                        {item.type}
                      </td>
                      <td className='AsmanCardPropertyPriceComponent__text'>
                        <div className='AsmanCardPropertyPriceComponent__row'>
                          {item.price?.min?.toLocaleString('id', {
                            currency: 'IDR',
                            style: 'currency'
                          })}{' '}
                          -{' '}
                          {item.price?.max?.toLocaleString('id', {
                            currency: 'IDR',
                            style: 'currency'
                          })}
                          <div className='AsmanCardPropertyPriceComponent__grow' />
                          <div className='AsmanCardPropertyPriceComponent__unit'>
                            {item.unit}
                          </div>
                        </div>
                      </td>
                      <td className='AsmanCardPropertyPriceComponent__text'>
                        <div className='AsmanCardPropertyPriceComponent__row'>
                          {item.list?.length}
                          <div className='AsmanCardPropertyPriceComponent__grow' />
                          <svg
                            fill='none'
                            height='25'
                            onClick={() =>
                              setState({
                                detail: item.list || [],
                                title: item.name
                              })
                            }
                            style={{ cursor: 'pointer' }}
                            viewBox='0 0 24 25'
                            width='24'
                          >
                            <circle cx='6' cy='12.0078' fill='#07827C' r='2'/>
                            <circle cx='12' cy='12.0078' fill='#07827C' r='2'/>
                            <circle cx='18' cy='12.0078' fill='#07827C' r='2'/>
                            <rect height='23' rx='3.5' stroke='#D7DAE2' width='23' x='0.5' y='0.507812'/>
                          </svg>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Empty />
              </div>
            )}
          </div>
        ))
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Empty />
        </div>
      )}
      <Modal
        className='AsmanCardPropertyPriceComponent__modal'
        footer={[]}
        onCancel={() => setState({ detail: [] })}
        open={state.detail.length > 0}
        title={
          <div className='AsmanCardPropertyPriceComponent__modal-title'>
            <b>{state.title}</b>&nbsp;
            <span>Property Detail</span>
          </div>
        }
      >
        {state.detail.map((v, idx) => (
          <div
            className='AsmanCardPropertyPriceComponent__modal-list'
            key={v.name}
          >
            <div className='AsmanCardPropertyPriceComponent__modal-list-title'>
              <div>
                <b>
                  {idx + 1}. {v.name}
                </b>
              </div>
              <div>
                Price:{' '}
                <b>
                  {v.price?.toLocaleString('id', {
                    currency: 'IDR',
                    style: 'currency'
                  })}
                </b>
              </div>
            </div>
            <div className='AsmanCardPropertyPriceComponent__modal-list-address'>
              {v.address}
            </div>
            {v.landArea && (
              <div>
                Land Area : <b>{v.landArea}</b>
              </div>
            )}
            {v.floorArea && (
              <div>
                Floor Area : <b>{v.floorArea}</b>
              </div>
            )}
            {v.facilities && (
              <div>
                Facilitites : <b>{v.facilities}</b>
              </div>
            )}
          </div>
        ))}
      </Modal>
    </SharedBaseCardTypeOneComponent>
  );
};
