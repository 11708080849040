import React, { useEffect, useRef } from 'react';
import { notification } from 'antd';
import _ from 'lodash';
import { useAdvanceSearch } from '../../hook/useAdvanceSearch';
import { useMainMap } from '@bvt-features/sub-module/mainmap/hook/useMainMap';
import { AdvanceSearchMapSearchInputComponent } from '../../component/AdvanceSearchMapSearchInput.component';
import { AdvanceSearchMapSearchModalComponent } from '../../component/AdvanceSearchMapSearchModal.component';

export const AdvanceSearchContainer = () => {
  const {
    state: {
      isAdvanceModalOpen,
      status_GET,
      status_GEOJSON,
      status_SEARCH,
      data: { options, selected: value, searchOptions },
    },
    toggleModalOpen,
    getProvince,
    getRegency,
    getDistrict,
    getVillage,
    setSelectedAdministrativeValue,
    getGeoJSON,
    getSearchByKeyword,
    resetStatus,
  } = useAdvanceSearch();
  const mainmap = useMainMap();

  const inputWrapRef = useRef(null);

  useEffect(() => {
    if (inputWrapRef) {
      mainmap.disableMapEvent(inputWrapRef.current);
    }
    return () => {
      resetStatus();
    };
  }, []);

  useEffect(() => {
    if (isAdvanceModalOpen && !((options.province?.length || 0) > 0)) {
      const params = {
        meta: { page: 1, pageSize: 100 },
      };
      getProvince({ params });
    }
  }, [isAdvanceModalOpen]);

  useEffect(() => {
    if (status_GET === 'FAILED') {
      notification.error({
        message: 'Failed to get administrative area',
        description: '',
        placement: 'bottomRight',
      });
    }
  }, [status_GET]);

  useEffect(() => {
    if (status_GEOJSON === 'LOADING') {
      notification.info({
        message: 'Fetching Adminstrative Area Data',
        description: 'This might take a while',
        placement: 'bottomRight',
      });
    }
    if (status_GEOJSON === 'SUCCESS') {
      notification.success({
        message: 'Administrative Area Succesfully Fetched',
        placement: 'bottomRight',
      });
    }
  }, [status_GEOJSON]);

  const internalHandleOnchange = (e) => {
    if (e.province && e.province !== value.regency?.value) {
      const params = {
        meta: { filter: { id_province: e.province }, page: 1, pageSize: 100 },
      };
      getRegency({ params });
    }
    if (e.regency && e.regency !== value.regency?.value) {
      const params = {
        meta: { filter: { id_city: e.regency }, page: 1, pageSize: 100 },
      };
      getDistrict({ params });
    }
    if (e.district && e.regency !== value.regency?.value) {
      const params = {
        meta: { filter: { id_district: e.district }, page: 1, pageSize: 100 },
      };
      getVillage({ params });
    }
  };

  const internalHandleConfirm = (e) => {
    setSelectedAdministrativeValue({
      level: 'province',
      value: { value: e.province },
    });
    setSelectedAdministrativeValue({
      level: 'regency',
      value: { value: e.regency },
    });
    setSelectedAdministrativeValue({
      level: 'district',
      value: { value: e.district },
    });
    setSelectedAdministrativeValue({
      level: 'village',
      value: { value: e.village },
    });
    toggleModalOpen(false);
    const params = {
      meta: {
        filter: {
          id_province: e.province,
          id_city: e.regency,
          id_district: e.district,
          id_village: e.village,
          id_source: 6,
        },
      },
    };
    getGeoJSON({ params });
  };

  const internalHandleSearch = (e) => {
    setSelectedAdministrativeValue({
      level: 'province',
      manualSet: true,
      value: {},
    });
    const params = {
      meta: {
        filter: {
          id_province: '',
          id_city: '',
          id_district: '',
          id_village: '',
          id_source: 6,
        },
      },
    };
    const selectedSearch = _.find(searchOptions, (v) => v.value === e);
    if (selectedSearch?.type === 'PROVINCE') {
      params.meta.filter.id_province = e;
      getGeoJSON({
        params,
      });
    }
    if (selectedSearch?.type === 'REGENCY') {
      params.meta.filter.id_city = e;

      getGeoJSON({
        params,
      });
    }
    if (selectedSearch?.type === 'DISTRICT') {
      params.meta.filter.id_district = e;
      getGeoJSON({
        params,
      });
    }
    if (selectedSearch?.type === 'VILLAGE') {
      params.meta.filter.id_village = e;
      getGeoJSON({
        params,
      });
    }
  };

  const internalHandleOnSearch = (e) => {
    const params = {
      meta: {
        filter: { keyword: e, id_source: 6 },
        pageSize: 9999,
        page: 1,
      },
    };
    getSearchByKeyword({ params });
  };
  return (
    <React.Fragment>
      <AdvanceSearchMapSearchInputComponent
        isAdvanceLoading={status_GEOJSON === 'LOADING'}
        isSearchLoading={status_SEARCH === 'LOADING'}
        onAdvanceClick={() => toggleModalOpen(true)}
        onSearch={internalHandleOnSearch}
        onSearchClick={internalHandleSearch}
        ref={inputWrapRef}
        searchOptions={searchOptions}
      />
      <AdvanceSearchMapSearchModalComponent
        isLoading={status_GET === 'LOADING'}
        isOpen={isAdvanceModalOpen}
        onCancel={() => toggleModalOpen(false)}
        onChange={internalHandleOnchange}
        onConfirm={internalHandleConfirm}
        options={{
          village: options.village,
          district: options.district,
          province: options.province,
          regency: options.regency,
        }}
        value={{
          district: value.district?.value,
          province: value.province?.value,
          regency: value.regency?.value,
          village: value.village?.value,
        }}
      />
    </React.Fragment>
  );
};
