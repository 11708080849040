import { RangeSlider } from '@bvt-atoms';
import { InputSwitch } from '@bvt-shared/component/InputSwitch';
import { Space } from 'antd';
import React from 'react';
import './MainMapLayerVisibilityItem.scss';

/**
 * @author Randa<m.randa@bvarta.com>
 * @author Malik<malik.ibrahim@bvarta.com>
 * @param {object} props
 * @param {string} props.title
 * @param {array<string>} props.description
 * @param {boolean} props.isActive
 * @param {number} props.opacity
 * @param {(e:{title:string,isActive:boolean,opacity:number})=>void} props.onChange
 */
export const MainMapLayerVisibilityItem = (props) => {
  const { isActive, opacity, title, onChange, description } = props;
  return (
    <div className='feature-mainmap MainMapLayerVisibilityItem'>
      <div className='MainMapLayerVisibilityItem__title'>{title}</div>
      <Space size={4} split={<div className='MainMapLayerVisibilityItem__subtitle-dot'/>}>
        {description?.map((item, idx) => (
          <div className='MainMapLayerVisibilityItem__subtitle-text' key={idx}>{item?.length > 35 ? `${item.substring(0, 35)}...` : item}</div>
        ))}
      </Space>

      <div className='MainMapLayerVisibilityItem__content'>
        <div style={{ width: '100%' }}>
          <RangeSlider
            max={100}
            min={0}
            onChange={(v) =>
              onChange({ isActive: true, opacity: v / 100, title: title })
            }
            value={opacity * 100}
          />
        </div>
        <InputSwitch
          handler={{
            onChange: () =>
              onChange({ isActive: !isActive, opacity: opacity, title: title }),
          }}
          style={{ flex: '20%', justifyContent: 'flex-end' }}
          value={isActive}
        />
      </div>
    </div>
  );
};
