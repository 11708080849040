/* eslint-disable max-len */
/* eslint-disable indent */
import { STEP_ACTION } from '@bvt-features/mapp/step/redux';
import { ID_SOURCE_CONSTANT } from '@bvt-shared/constant/ID_SOURCE_CONSTANT';
import { useDispatch, useSelector } from 'react-redux';
import { SIPRO_ACTION, SIPRO_CONSTANT } from '../../redux';
import { isEmpty } from 'lodash';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta <renta.yustika@bvarta.com>
 */

/**
 * @typedef {Object} SAVE_OBJ
 * @property {number} id
 * @property {string} name
 * @property {string} project_type
 * @property {string} process_id
 * @property {string} id_source
 * @property {number} id_user
 * @property {object} history
 * @property {object} history.form_set
 * @property {object} history.form_set.administrative
 * @property {number} history.form_set.administrative.province
 * @property {number} history.form_set.administrative.regency
 * @property {number} history.form_set.administrative.district
 * @property {number} history.form_set.administrative.village
 * @property {Array} history.form_set.markers
 * @property {Object} history.form_set.vehicleAndDataType
 * @property {Object} history.form_set.vehicleAndDataType.vehicleOrwalking
 * @property {string} history.form_set.vehicleAndDataType.vehicleOrwalking.value
 * @property {string} history.form_set.vehicleAndDataType.vehicleOrwalking.text
 * @property {Object} history.form_set.vehicleAndDataType.radiusBasedOn
 * @property {string} history.form_set.vehicleAndDataType.radiusBasedOn.value
 * @property {string} history.form_set.vehicleAndDataType.radiusBasedOn.text
 * @property {Object} history.form_set.vehicleAndDataType.distanceOrtime
 * @property {Object} history.form_set.vehicleAndDataType.distanceOrtime.type
 * @property {number} history.form_set.vehicleAndDataType.distanceOrtime.type.inputValue
 * @property {string} history.form_set.vehicleAndDataType.distanceOrtime.type.alias
 * @property {string} history.form_set.vehicleAndDataType.distanceOrtime.type.objValue
 * @property {Array} history.form_set.vehicleAndDataType.distanceOrtime.valueDistance
 * @property {Array} history.form_set.vehicleAndDataType.distanceOrtime.valueTime
 * @property {number} history.form_set.vehicleAndDataType.unit
 * @property {Object} history.form_set.poi
 * @property {Array} history.form_set.poi.selected
 * @property {Object} history.form_set.parameter
 * @property {number} history.form_set.parameter.id
 * @property {Object} history.form_set.parameter.detail
 * @property {Object} history.form_set.density
 * @property {Object} history.form_set.density.timeType
 * @property {boolean} history.form_set.density.isChecked
 * @property {number} history.form_set.density.year
 * @property {number} history.form_set.density.month
 * @property {number} history.form_set.density.day
 * @property {Object} history.form_set.density.time
 * @property {number} history.form_set.density.time.min
 * @property {number} history.form_set.density.time.max
 * @property {string} history.form_set.density.resultType
 * @property {number} history.form_set.id_step
 * @property {number} history.form_set.step_order
 */

export function useSIPRO() {
  const dispatch = useDispatch();

  /**
   * @typedef {Object} SIPRO_REDUCER
   * @property {string} [status_GET]
   * @property {string} [status_DETAIL]
   * @property {string} [status_GET_AREA]
   * @property {string} [status_GET_POI]
   * @property {string} [status_POST]
   * @property {string} [status_PUT]
   * @property {string} [status_DELETE]
   * @property {string} [message]
   * @property {Array} [data]
   * @property {number} [step]
   * @property {number} [lastStep]
   */

  /**
   *  @type {SIPRO_REDUCER} SIPRO_REDUCER
   */
  const state = useSelector((state) => state.features.MAPANA.SIPRO);

  const createRenameProject = (last) => {
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;

    if (last === 0) {
      setLastStep(1);
      urlParams.set('step', 1);
      window.history.replaceState({}, '', `${url}`);
      dispatch(STEP_ACTION.setStep({ step: 1 }));
    } else {
      setLastStep(last);
      urlParams.set('step', last);
      window.history.replaceState({}, '', `${url}`);
      dispatch(STEP_ACTION.setStep({ step: last }));
    }
  };

  const nextStep = () => {
    let remapSetStep = state.step < 7 ? state.step + 1 : 7;
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;
    urlParams.set('step', remapSetStep);
    window.history.replaceState({}, '', `${url}`);
    dispatch(SIPRO_ACTION.setStep({ step: remapSetStep }));
    dispatch(STEP_ACTION.setStep({ step: remapSetStep }));
    setLastStep(remapSetStep);
  };

  const prevStep = () => {
    let remapSetStep = state.step > -1 ? state.step - 1 : 0;
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;
    remapSetStep === 0
      ? urlParams.delete('step')
      : urlParams.set('step', remapSetStep);

    window.history.replaceState({}, '', `${url}`);
    dispatch(SIPRO_ACTION.setStep({ step: remapSetStep }));
    dispatch(STEP_ACTION.setStep({ step: remapSetStep }));
    setLastStep(remapSetStep);
  };

  /**
   *
   * @param {number} payload
   */
  const setStep = (payload) => {
    dispatch(SIPRO_ACTION.setStep({ step: payload }));
  };

  /**
   *
   * @param {number} payload
   */
  const setStepOrder = (payload) => {
    dispatch(SIPRO_ACTION.setStepOrder({ steporder: payload }));
  };

  /**
   * @param {SAVE_OBJ} payload
   */
  const remapLocalFormValueToService = (payload) => ({
    id: payload?.id || null,
    name: payload?.name || null,
    project_type: payload?.project_type || 'Analysis - Site Profiling',
    process_id: payload?.process_id || null,
    id_source: payload?.id_source || ID_SOURCE_CONSTANT,
    history: {
      form_set: {
        project: {
          id: payload?.id,
          name: payload?.name,
          id_source: payload?.id_source || ID_SOURCE_CONSTANT,
        },
        administrative: {
          country: payload?.administrative?.country,
          province: payload?.administrative?.province,
          regency: payload?.administrative?.regency,
          district: payload?.administrative?.district,
          village: payload?.administrative?.village,
        },
        markers: payload?.markers || [],
        vehicleAndDataType: {
          vehicleOrwalking: {
            value: payload?.vehicleAndDataType?.vehicleOrwalking?.value,
            text: payload?.vehicleAndDataType?.vehicleOrwalking?.text,
          },
          radiusBasedOn: {
            value: payload?.vehicleAndDataType?.radiusBasedOn?.value,
            text: payload?.vehicleAndDataType?.radiusBasedOn?.text,
          },
          distanceOrtime: {
            type: {
              inputValue:
                payload?.vehicleAndDataType?.distanceOrtime?.value?.length,
              alias: payload?.vehicleAndDataType?.distanceOrtime?.unit,
              objValue:
                payload?.vehicleAndDataType?.distanceOrtime?.unit === 'time'
                  ? 'valueTime'
                  : 'valueDistance',
            },
            valueDistance:
              payload?.vehicleAndDataType?.distanceOrtime?.unit === 'distance'
                ? payload?.vehicleAndDataType?.distanceOrtime?.value
                : [],
            valueTime:
              payload?.vehicleAndDataType?.distanceOrtime?.unit === 'time'
                ? payload?.vehicleAndDataType?.distanceOrtime?.value
                : [],
          },
          unit: payload?.vehicleAndDataType?.unit,
        },
        poi: {
          selected: payload?.poi?.selected || [],
        },
        parameter: {
          id: payload?.parameter?.id || [],
        },
        density: {
          timeType: payload?.density?.timeType || 'specific',
          isChecked: payload?.density?.isChecked || false,
          year: payload?.density?.year || '',
          month: payload?.density?.month || '',
          day: payload?.density?.day || '',
          time: {
            min: payload?.density?.time?.min ?? '',
            max: payload?.density?.time?.max ?? '',
          },
          resultType: payload?.density?.resultType || 'total',
        },
      },
      analyze_result: payload?.analyze_result || {},
      id_step: payload?.id_step || 1,
      step_order: payload?.history?.step_order || 1,
    },
  });

  /**
   * @param {SAVE_OBJ} payload
   */
  const doAnalyze = (payload) => {
    const payloadProject = remapLocalFormValueToService(payload);
    const coordinate =
      payloadProject?.history?.form_set?.markers?.length > 0
        ? payloadProject?.history?.form_set?.markers.map((e) => ({
            name: e.siteName,
            coordinate: e.coordinate,
          }))
        : [];
    const density = payloadProject?.history?.form_set?.density;
    const density_date = density?.isChecked
      ? `${density?.year}${density?.month > 0
        ? `-${density?.month > 9
          ? density?.month
          : `0${density?.month}`}`
        : ''}${density?.day > 0
        ? `-${density?.day > 9
          ? density?.day
          : `0${density?.day}`}`
        : ''}`
      : '';
    const payloadForAnalyze = {
      id_project: parseInt(payloadProject?.id),
      coordinate: coordinate,
      mode: payloadProject?.history?.form_set?.vehicleAndDataType?.radiusBasedOn
        ?.value,
      profile:
        payloadProject?.history?.form_set?.vehicleAndDataType?.vehicleOrwalking
          ?.value,
      distance:
        payloadProject?.history?.form_set?.vehicleAndDataType?.distanceOrtime
          ?.type?.alias === 'distance'
          ? payloadProject?.history?.form_set?.vehicleAndDataType
              ?.distanceOrtime?.valueDistance
          : payloadProject?.history?.form_set?.vehicleAndDataType
              ?.distanceOrtime?.valueTime,
      drive_base:
        payloadProject?.history?.form_set?.vehicleAndDataType?.distanceOrtime
          ?.type?.alias,
      time:
        payloadProject?.history?.form_set?.vehicleAndDataType?.distanceOrtime
          ?.type?.alias === 'time'
          ? payloadProject?.history?.form_set?.vehicleAndDataType
              ?.distanceOrtime?.valueTime
          : payloadProject?.history?.form_set?.vehicleAndDataType
              ?.distanceOrtime?.valueDistance,
      poi: payloadProject?.history?.form_set?.poi?.selected,
      parameter: payload?.parameter?.id || [],
      id_source_data: ID_SOURCE_CONSTANT,
      use_density: payloadProject?.history?.form_set?.density?.isChecked,
      density_date: density_date,
      id_user: payload?.id_user,
      density_hourly: false,
      jam_min: '',
      jam_max: '',
      type: payloadProject?.history?.form_set?.density?.resultType
    };

    dispatch(SIPRO_ACTION.doAnalyze(payloadProject, payloadForAnalyze));
  };

  const doAnalyzeMandala = (payload) => {
    const payloadProject = remapLocalFormValueToService(payload);
    const coordinate =
      payloadProject?.history?.form_set?.markers?.length > 0
        ? payloadProject?.history?.form_set?.markers.map((e) => ({
            name: e.siteName,
            coordinate: e.coordinate,
          }))
        : [];
    // const density_date = payloadProject?.history?.form_set?.density?.isChecked
    //   ? `${payloadProject?.history?.form_set?.density?.year}-${
    //       payloadProject?.history?.form_set?.density?.month > 9
    //         ? payloadProject?.history?.form_set?.density?.month
    //         : `0${payloadProject?.history?.form_set?.density?.month}`
    //     }-${
    //       payloadProject?.history?.form_set?.density?.day > 9
    //         ? payloadProject?.history?.form_set?.density?.day
    //         : `0${payloadProject?.history?.form_set?.density?.day}`
    //     }`
    //   : '';
    const payloadForAnalyze = {
      id_project: parseInt(payloadProject?.id),
      coordinate: coordinate,
      mode: payloadProject?.history?.form_set?.vehicleAndDataType?.radiusBasedOn
        ?.value,
      profile:
        payloadProject?.history?.form_set?.vehicleAndDataType?.vehicleOrwalking
          ?.value,
      distance:
        payloadProject?.history?.form_set?.vehicleAndDataType?.distanceOrtime
          ?.type?.alias === 'distance'
          ? payloadProject?.history?.form_set?.vehicleAndDataType
              ?.distanceOrtime?.valueDistance
          : payloadProject?.history?.form_set?.vehicleAndDataType
              ?.distanceOrtime?.valueTime,
      drive_base:
        payloadProject?.history?.form_set?.vehicleAndDataType?.distanceOrtime
          ?.type?.alias,
      time:
        payloadProject?.history?.form_set?.vehicleAndDataType?.distanceOrtime
          ?.type?.alias === 'time'
          ? payloadProject?.history?.form_set?.vehicleAndDataType
              ?.distanceOrtime?.valueTime
          : payloadProject?.history?.form_set?.vehicleAndDataType
              ?.distanceOrtime?.valueDistance,
      poi: payloadProject?.history?.form_set?.poi?.selected,
      parameter: [payload?.parameter?.id],
      id_source_data: ID_SOURCE_CONSTANT,
      id_user: payload?.id_user,
      country_id: payload?.administrative?.country
    };

    dispatch(SIPRO_ACTION.doAnalyzeMandala(payloadProject, payloadForAnalyze));
  };

  /**
   * @param {SAVE_OBJ} payload
   */
  const saveProject = (payload) => {
    let remapPayload = remapLocalFormValueToService(payload);
    dispatch(SIPRO_ACTION.saveProject(remapPayload));
  };

  /**
   *
   * @param {object} payload
   * @param {number} payload.project_id
   * @param {string} payload.process_id
   * @param {number} payload.page
   * @param {number} payload.limit
   * @param {number} payload.gid
   */
  const getPoiCollections = (payload) => {
    dispatch(SIPRO_ACTION.getSitePoiCollections(payload));
  };

  /**
   *
   * @param {object} payload
   * @param {number} payload.project_id
   * @param {string} payload.process_id
   * @param {number} payload.page
   * @param {number} payload.limit
   * @param {number} payload.gid
   */
  const getAreaCollections = (payload) => {
    dispatch(SIPRO_ACTION.getSiteAreaCollections(payload));
  };

  const filterAreaByMarkerAndDistance = (payload) => {
    dispatch(SIPRO_ACTION.filterAreaCollection(payload));
  };

  const filterAreaByMarkerAndDistanceRight = (payload) => {
    dispatch(SIPRO_ACTION.filterAreaCollectionRight(payload));
  };
  const resetAll = () => {
    dispatch({ type: SIPRO_CONSTANT.RESET_ALL });
  };

  const resetStatus = () => {
    dispatch({ type: SIPRO_CONSTANT.RESET_STATUS });
  };

  const setIsShownOnMap = (payload) => {
    dispatch(SIPRO_ACTION.setIsShownOnMap({ IS_SHOWN_ON_MAP: payload }));
  };

  const resetCollection = () => {
    dispatch({ type: SIPRO_CONSTANT.RESET_COLLECTION });
  };

  /**
   *
   * @param {number} param
   */
  const setLastStep = (param) => {
    dispatch(SIPRO_ACTION.setLastStep({ lastStep: param }));
  };

  /**
   * Check validity for next if hit direct url
   */
  const isValidForNext = (payload, step) => {
    const step2 = !isEmpty(payload?.markers);
    const step3 = !!payload?.vehicleAndDataType?.radiusBasedOn?.value &&
      !!payload?.vehicleAndDataType?.distanceOrtime?.value &&
      !!payload?.vehicleAndDataType?.unit;
    const step4 = payload?.poi?.selected?.length > 0;

    switch (step) {
      case 3:
        return step2;
      case 4:
        return step2 && step3;
      case 5:
        return step2 && step3 && step4;
      case 6:
        return step2 && step3 && step4;
      default:
        return false;
    }
  };

  return {
    state,
    createRenameProject,
    doAnalyze,
    doAnalyzeMandala,
    saveProject,
    setStep,
    setStepOrder,
    getAreaCollections,
    getPoiCollections,
    filterAreaByMarkerAndDistance,
    filterAreaByMarkerAndDistanceRight,
    resetAll,
    resetStatus,
    nextStep,
    prevStep,
    setIsShownOnMap,
    resetCollection,
    setLastStep,
    isValidForNext,
  };
}
