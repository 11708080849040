import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthService } from '../service';
import { permissionMapper } from '../helper';
import * as _ from 'lodash';

const authService = new AuthService();

export const authAsyncAction = {
  login: createAsyncThunk('LI/AUTH/login', async (param) => {
    try {
      await authService.login(param);
      const checkProfile = await authService.checkProfile();
      const setting = await authService.getSetting();
      const lokasiintelligence = checkProfile?.result?.users_group?.permissions;
      const permissions = permissionMapper(lokasiintelligence || {});
      const lokasiintelligenceAccess =
        _.find(permissions, (v) => v.key === 'MAPANA')?.isAllowed ||
        _.find(permissions, (v) => v.key === 'DATEX')?.isAllowed;

      if (!lokasiintelligenceAccess) {
        await authService.logout(); // clear cookies if has no access to lokasiintelligence
      }

      return {
        isAuthReady: true,
        isAuthenticated: true,
        user: checkProfile?.result,
        setting: setting?.result,
        permissions,
        lokasiintelligenceAccess,
      };
    } catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  }),
  logout: createAsyncThunk('LI/AUTH/logout', async (_, thunk) => {
    await authService.logout();
    thunk.dispatch({ type: 'LI/AUTH/unauthorized' });
    return {};
  }),
  checkToken: createAsyncThunk('LI/AUTH/checkToken', async () => {
    const checkProfile = await authService.checkProfile();
    const setting = await authService.getSetting();
    const lokasiintelligence = checkProfile?.result?.users_group?.permissions;
    const permissions = permissionMapper(lokasiintelligence || {});
    const lokasiintelligenceAccess =
      _.find(permissions, (v) => v.key === 'MAPANA')?.isAllowed ||
      _.find(permissions, (v) => v.key === 'DATEX')?.isAllowed;

    if (!lokasiintelligenceAccess) {
      await authService.logout(); // clear cookies if has no access to lokasiintelligence
    }

    return {
      isAuthReady: true,
      isAuthenticated: true,
      user: checkProfile?.result,
      setting: setting?.result,
      permissions,
      lokasiintelligenceAccess,
    };
  }),
  forgetPassword: createAsyncThunk('LI/AUTH/forgot-password', async (param) => {
    let resp = await authService.forgetPassword(param);
    return {
      message: resp?.data?.message,
    };
  }),
  resetPasswordByToken:createAsyncThunk('LI/AUTH/resetPasswordByToken',async(param)=>{
    try {
      return await authService.resetPasswordByToken(param);
    } catch (error) {
      throw error?.response?.data;
    }
  }),
  getSetting:createAsyncThunk('LI/AUTH/getSetting',async()=>{
    try {
      return await authService.getSetting();
    } catch (error) {
      throw new Error(error?.response?.data?.messsage);
    }
  }),
  updateSetting:createAsyncThunk('LI/AUTH/updateSetting',async (param)=>{
    try {
      return  await authService.updateSetting(param);
    } catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  }),
  checkTokenResetPassword:createAsyncThunk('LI/AUTH/checkTokenResetPassword', async (param) => {
    try {
      return await authService.checkTokenResetPassword(param);
    } catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  })
};
