export const dummyData = [
  {
    label: 'All Type',
    value: 'all_type',
  },
  {
    label: 'Site Profiling',
    value: 'site_profiling',
  },
  {
    label: 'Business Profiling',
    value: 'business_profiling',
  },
  {
    label: 'Grid Profiling',
    value: 'grid_profiling',
  },
];
