import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import DOMPurify from 'dompurify';
import { Button } from '@bvt-atoms';

export default function MainWhatsNew(props) {
  const { data } = props;
  return (
    <div className='whats-new'>
      <Row className='whats-new__header'>
        <span className='text-bold'>Updates</span>
      </Row>
      <Row className='whats-new__title'>
        <p>What's New</p>
      </Row>
      <Row className='whats-new__content'>
        <div
          className='whats-new__content__text'
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data.whats_new.desc),
          }}
        />
      </Row>
      <Row className='whats-new__add'>
        <p>Additional Information</p>
      </Row>
      <Row className='whats-new__add__list'>
        <Col span={8}>
          <p className='text-bold'>Updated</p>
          <p>{data.whats_new.updated_at}</p>
        </Col>
        <Col span={14}>
          <p className='text-bold'>People used</p>
          <p>{data.people_used}</p>
        </Col>
      </Row>
      <Row className='whats-new__add__list'>
        <Col span={8}>
          <p className='text-bold'>Requires browser</p>
          <ul>
            {data.requires_browser.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        </Col>
        <Col span={14}>
          <p className='text-bold'>Current version</p>
          <p>{data.current_version}</p>
        </Col>
      </Row>
      <Row className='whats-new__add__list'>
        <Col>
          <p className='text-bold'>Report</p>
        </Col>
      </Row>
      <Row className='whats-new__add__list'>
        <Button className='button__white button__medium' key={'launch'}>
          <p>Send feedback about this module</p>
        </Button>
      </Row>
    </div>
  );
}

MainWhatsNew.defaultProps = {
  data: {},
};

MainWhatsNew.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
};
