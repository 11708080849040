import React, { Fragment } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BaseDraggableCardDatex } from '../BaseDraggableCardDatex/BaseDraggableCardDatex';
import './CardListItemDatex.scss';
import PropTypes from 'prop-types';
// import _ from 'lodash';
import IMAGE_CABINET from '@bvt-assets/images/CabinetIllustration.png';
import { TIME_TYPE } from '@bvt-features/datex/constant/TIME_TYPE';
import _, { isArray } from 'lodash';

/**
 * @copyright Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 * @param {object} props
 * @param {Array<import('@bvt-features/datex/store/datex.slice').TDataLayerObject>} props.data
 * @param {Function} props.onChange
 * @param {Function} props.onEdit
 * @param {(ID: Number) => void} props.onDelete
 * @param {(ID: Number, Val: String) => void} props.onSelectOptionChange
 * @param {(ID: Number, Val: number) => void} props.onSliderChange
 * @param {Function} props.onHighlight
 * @param {(ID: Number, Val: Boolean) => void} props.onVisible
 * @param {StyleSheet} props.style
 * @param {String} props.className
 * @param {object} props.options
 * @param {number} props.options.layerActiveId
 * @param {object} props.options.gridDetail

 */

export const CardListItemDatex = (props) => {
  const {
    data,
    onChange,
    onDelete,
    onEdit,
    onHighlight,
    onVisible,
    options,
    style,
    className,
    onSelectOptionChange,
    onSliderChange,
  } = props;

  const reorderIndexMapping = (newArr) => {
    let nData = [];
    for (let idx = 0; idx < newArr.length; idx++) {
      nData[idx] = {
        ...newArr[idx],
        index: idx + 1,
      };
    }
    return nData;
  };

  const generateStyle = (color) => {
    return {
      minWidth: '1rem',
      minHeight: '1rem',
      backgroundColor: color,
      opacity: '0.7',
      borderRadius: '0.375rem',
    };
  };

  const internalHandleChange = (indexBefore, indexAfter) => {
    const reorderedData = Array.from(data);
    const [removed] = reorderedData.splice(indexBefore, 1);
    reorderedData.splice(indexAfter, 0, removed);
    let newReodered = reorderedData.map((v, index) => ({ ...v, sort: index }));
    onChange &&
      onChange(reorderIndexMapping(newReodered), newReodered[indexAfter]);
  };

  const internalHandleOnDelete = (ID, catchmentBy) => {
    onDelete && onDelete({ ID, catchmentBy });
  };

  const internalHandleOnVisible = (id, val) => {
    onVisible && onVisible(id, val);
  };

  const internalHandleOnHighlight = (ctx) => {
    onHighlight && onHighlight(ctx);
  };

  const internalHandleOnEdit = (ID) => {
    onEdit && onEdit(ID);
  };

  const internalHandleOnSelectOptionChange = (ID, val) => {
    onSelectOptionChange && onSelectOptionChange(ID, val);
  };

  const internalHandleOnSliderChange = (ID, val) => {
    onSliderChange && onSliderChange(ID, val);
  };

  const mappingLegend = (data, options) => {
    return data?.map((v, idx) => ({ ...v, hex: options[idx]?.hex })) || [];
  };

  return (
    <Fragment>
      {data && data.length > 0 && (
        <DragDropContext
          onDragEnd={(e) =>
            internalHandleChange(e.source.index, e.destination.index)
          }
        >
          <Droppable droppableId='DraggableListTelcoData'>
            {(provider) => (
              <div
                {...provider.droppableProps}
                className={'CardListItemDatex__container ' + className}
                ref={provider.innerRef}
                style={{ ...style }}
              >
                {data.map((ctx, index) => {
                  return (
                    <Draggable
                      draggableId={'DraggableListTelcoData-' + String(ctx?.id)}
                      index={index}
                      key={`${ctx?.type}${ctx?.id}`}
                    >
                      {(provider) => (
                        <div
                          {...provider.draggableProps}
                          className='CardListItemDatex__card'
                          ref={provider.innerRef}
                          style={{
                            '--DraggableListCardIconColor': ctx?.meta?.color
                              ? ctx?.meta?.color
                              : '#c7c7c7',
                            ...provider.draggableProps.style,
                          }}
                          {...provider.dragHandleProps}
                        >
                          <BaseDraggableCardDatex
                            detail={{
                              ...ctx?.result?.layerSummary,
                              poiIndustry: ctx?.poiIndustry?.value,
                              poiCategory: ctx?.poiCategory?.value,
                              poiCategoryIcon: ctx?.poiCategory?.icon,
                              poiGroup: ctx?.poiGroup?.value,
                              poiArea: ctx?.poiArea,
                              poiBrand: ctx?.poiBrandLabels?.join(', '),
                              poiCriteria: ctx?.poiCriteriaLabels?.join(', '),
                              poiData: ctx?.result?.counter,
                              poiTotal: ctx?.result?.poi?.totalFeatures,
                              thematicCategory: ctx?.thematicCategory,
                              thematicSubCategory: ctx?.thematicSubCategory,
                              thematicItems:
                                ctx?.result?.[
                                  ctx?.thematicSelectedYearIndex ?? 0
                                ]?.item?.map((el) => el?.item_id) ?? [],
                              thematicItemsLabel:
                                ctx?.result?.[
                                  ctx?.thematicSelectedYearIndex ?? 0
                                ]?.item?.map((el) => el?.item_label) ?? [],
                              thematicArea: ctx?.thematicArea,
                              thematicSelectedYearIndex:
                                ctx?.thematicSelectedYearIndex,
                              thematicYears: isArray(ctx?.result)
                                ? ctx?.result?.map((el) => el?.note)
                                : [],
                              thematicRecord: ctx?.result?.[
                                ctx?.thematicSelectedYearIndex ?? 0
                              ]?.record?.map((el, idx) => ({
                                id: idx,
                                name: el?.name,
                                count: el?.value,
                                color: el?.color ?? '#FF1010',
                              })),
                              catchmentTimeType: _.find(TIME_TYPE, {
                                value: ctx?.timeType,
                              })?.label,
                              telcoLayerLegend: mappingLegend(
                                ctx?.result?.legend,
                                ctx?.options?.data
                              ),
                              telcoDataType: _.capitalize(ctx?.dataType),
                            }}
                            id={ctx?.id}
                            isLoading={ctx?.status_ANALYZE}
                            isShowVisibility={ctx?.isShowVisibility}
                            onDelete={() =>
                              internalHandleOnDelete(
                                ctx?.id,
                                ctx?.catchmentType ??
                                  ctx?.poiCategory?.value ??
                                  ctx?.thematicSubCategory?.label
                              )
                            }
                            onEdit={() => internalHandleOnEdit(ctx?.id)}
                            onHighlight={() => internalHandleOnHighlight(ctx)}
                            onSelectOptionChange={(val) => {
                              internalHandleOnSelectOptionChange(ctx?.id, val);
                            }}
                            onSliderChange={(val) =>
                              internalHandleOnSliderChange(ctx?.id, val)
                            }
                            onVisible={(val) => {
                              internalHandleOnVisible(ctx?.id, val);
                            }}
                            options={options}
                            selectOption={{
                              data: ctx?.options?.data?.map((vCtx) => {
                                return {
                                  value: vCtx?.class_name,
                                  label: (
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                        flexDirection: 'row',
                                      }}
                                    >
                                      <div style={generateStyle(vCtx?.hex)} />{' '}
                                      <span className='hide-label'>
                                        {vCtx?.name}
                                      </span>
                                    </div>
                                  ),
                                };
                              }),
                              value:
                                ctx?.options?.value ||
                                ctx?.options?.data[0]?.class_name,
                            }}
                            type={ctx?.type}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provider.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {data && data.length === 0 && (
        <div className='feature-map LayoutDatexSidebarWrapper__body__no-layer'>
          <img
            alt='Cabinet i'
            className='feature-map LayoutDatexSidebarWrapper__body__no-layer__img'
            src={IMAGE_CABINET}
          />
          <span className='feature-map LayoutDatexSidebarWrapper__body__no-layer__title'>
            You have no layers yet
          </span>
          <span className='feature-map LayoutDatexSidebarWrapper__body__no-layer__desc'>
            Click ‘Add layer data’ to create a new layer
          </span>
        </div>
      )}
    </Fragment>
  );
};

CardListItemDatex.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  style: PropTypes.object,
};
