import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PopupForm } from '@bvt-organisms';
import RenameForm from '../RenameForm/RenameForm';
import { Popup } from '@bvt-atoms';

export default function PopupRenameForm(props) {
  const [popupSuccess, setPopupSuccess] = useState(false);
  const [popupError, setPopupError] = useState(false);

  const handleSubmit = (e) => {
    props.onSubmit && props.onSubmit(e);
    props.setShowPopup && props.setShowPopup(false);
  };
  const handleCancel = () => {
    props.setShowPopup && props.setShowPopup(false);
  };

  useEffect(() => {
    if (props.status === 'SUCCESS') {
      props.reFetch && props.reFetch();
      setPopupSuccess(true);
    } else if (props.status === 'FAILED') {
      setPopupError(true);
    }
  }, [props.status]);
  return (
    <React.Fragment>
      <PopupForm
        cancelButton={false}
        closeButton={true}
        footer={false}
        formRender={() => (
          <RenameForm
            defaultValue={props.defaultValue}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            placeholder={props.placeholder}
            sumbitText={props.buttonConfirmText}
          />
        )}
        handler={{
          onHide: handleCancel,
        }}
        headerText={props.headerText}
        show={props.show}
        submitButton={false}
      />
      <Popup
        body={{
          icon: 'successcheck',
          desc: props.popupDesc ? props.popupDesc : <React.Fragment>
            <p>Your changes has been</p>
            <p>successfully saved!</p>
          </React.Fragment>,
          title: 'Success!',
          titleClass: 'text-bold',
        }}
        cancelText='Abort'
        footer={false}
        handler={{
          onHide: () => {
            setPopupSuccess(false);
          },
          onSubmit: () => {
            setPopupSuccess(false);
          },
        }}
        headerText={props.popupText ? props.popupText : 'Rename'}
        show={popupSuccess}
      />
      <Popup
        body={{
          icon: 'errorcheck',
          desc: (
            <React.Fragment>
              <p>{props.errorMessage}</p>
            </React.Fragment>
          ),
          title: 'Failed!',
          titleClass: 'text-bold',
        }}
        cancelText='Abort'
        footer={false}
        handler={{
          onHide: () => {
            setPopupError(false);
          },
          onSubmit: () => {
            setPopupError(false);
          },
        }}
        headerText='Rename'
        show={popupError}
      />
    </React.Fragment>
  );
}

PopupRenameForm.defaultProps = {
  buttonConfirmText: 'Rename',
  defaultValue: '',
  errorMessage: '',
  headerText: 'Rename parameter',
  onSubmit: undefined,
  placeholder: 'name parameter',
  reFetch: undefined,
  setShowPopup: undefined,
  show: false,
  status: 'IDLE',
};

PopupRenameForm.propTypes = {
  buttonConfirmText: PropTypes.string,
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  headerText: PropTypes.string,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  reFetch: PropTypes.func,
  setShowPopup: PropTypes.func,
  show: PropTypes.bool,
  status: PropTypes.oneOf(['IDLE', 'SUCCESS', 'FAILED', 'LOADING']),
};
