import { Popup } from '@bvt-atoms';
import { useAuth } from '@bvt-features/auth/hooks';
import DynamicIcon from '@bvt-icons';
import { Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { USER_ACTION_LIST } from '../../constant/USER_ACTION_LIST';
import './User.scss';
import userPhoto from '@bvt-images/userDefaultPhoto.png';
import { useSound } from '@bvt-hooks';

export default function User() {
  const [visibility, setVisibility] = useState(false);
  const [logout, setLogout] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);

  const AUTH = useAuth();
  const { playSound } = useSound(AUTH.state?.data?.setting?.sound_url);
  const navigate = useNavigate();

  const s3URL = '/api/api-uploads3/v2/photo-user-company/display-photo/';

  useEffect(() => {
    if (!startAnimation && visibility) {
      setStartAnimation(true);
    }
  }, [visibility]);

  const navigateTo = (path = '') => {
    navigate({
      pathname: path,
    });
  };

  const InfoList = (
    <div className='menu-bar__user--info-list'>
      {USER_ACTION_LIST.map((item, index) => {
        return (
          <li
            className='menu-bar__user--li'
            key={index}
            onClick={() => {
              const path = `/app/main/account/${item.slug}`;
              navigateTo(path);
            }}
          >
            <DynamicIcon
              className='menu-bar__user--li-icon'
              height='0.75rem'
              icon={item.icon}
              width='0.75rem'
            />
            <p>{item.name}</p>
          </li>
        );
      })}
      <div className='menu-bar__user__logout'>
        <button
          onClick={() => {
            playSound();
            setVisibility((visibility) => !visibility);
            setStartAnimation(false);
            setLogout(true);
          }}
        >
          <p>Logout</p>
          <DynamicIcon
            className='menu-bar__user__logout--icon'
            height='1rem'
            icon='logout'
            width='1rem'
          />
        </button>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Popover
        className='menu-bar__user__popover'
        content={InfoList}
        onOpenChange={() => setVisibility((visibility) => !visibility)}
        open={visibility}
        placement='bottom'
        trigger='click'
      >
        <div className={`menu-bar__user ${visibility ? 'selected' : ''}`}>
          <div className={`menu-bar__user__left`}>
            <p className='menu-bar__user--name'>
              {AUTH.state?.data?.user?.username}
            </p>
          </div>
          <div className='menu-bar__user__right'>
            <DynamicIcon
              className={`menu-bar__user--arrow ${
                visibility ? 'selected' : ''
              } ${startAnimation ? 'active' : ''}`}
              height='1rem'
              icon='arrowfilled'
              width='1rem'
            />
            {AUTH.state?.data?.user?.photo_path === 'bnVsbA==' ? (
              <img
                alt='user default photo'
                className='menu-bar__user--photo'
                src={userPhoto}
              />
            ) : (
              <img
                alt='user default photo'
                className='menu-bar__user--photo'
                src={`${s3URL}${AUTH.state?.data?.user?.photo_path}`}
              />
            )}
          </div>
        </div>
      </Popover>
      <Popup
        body={{
          icon: 'confirmlogout',
          desc: <p>Are you sure want to log out?</p>,
        }}
        footerButton={{
          rightClass: 'red',
        }}
        handler={{
          onHide: () => {
            setLogout(false);
          },
          onSubmit: () => {
            const path = '/auth/logout';
            setLogout(false);
            navigateTo(path);
          },
        }}
        headerText='Confirm'
        show={logout}
        submitText='Logout'
      />
    </React.Fragment>
  );
}
