import React from 'react';
import AuthGateContainer from '@bvt-features/auth/container/AuthGateContainer';
import { BasePageHeadTag } from '@bvt-shared/component/BasePageHeadTag';
import { LayoutMainMapContainer } from '@bvt-features/mainmap/mainmap/container/LayoutMainMapContainer';
import Page from '../../../pages/bvt';
/** @type {import('react-router').RouteObject} */
export const RoutesAppMap = {
  path: 'map',
  element: (
    <React.Fragment>
      <LayoutMainMapContainer>
        <BasePageHeadTag title='LI :: Map' />
        <AuthGateContainer />
      </LayoutMainMapContainer>
    </React.Fragment>
  ),
  children: [
    {
      index: true,
    },
    {
      path: 'map-analytic',
      element: (
        <React.Fragment>
          <BasePageHeadTag title='LI :: Map Analytics' />
          <Page.Mapana />
        </React.Fragment>
      ),
      children: [
        {
          path: 'grid-analysis',
          element: (
            <React.Fragment>
              <BasePageHeadTag title='LI :: Map Analytics :: Grid Analysis' />
              <Page.CreateEditGridAnalysis />
            </React.Fragment>
          ),
        },
      ],
    },
    {
      path: 'map-analytic',
      children: [
        {
          path: 'site-profiling',
          element: (
            <React.Fragment>
              <BasePageHeadTag title='LI :: Map Analytics :: Site Profiling' />
              <Page.CreateEditSiteProfiling />
            </React.Fragment>
          ),
        },
      ],
    },
    {
      path: 'map-analytic',
      children: [
        {
          path: 'site-profiling-mandala',
          element: (
            <React.Fragment>
              <BasePageHeadTag title='LI :: Map Analytics :: Site Profiling Mandala' />
              <Page.CreateEditSiteProfilingMandala />
            </React.Fragment>
          ),
        },
      ],
    },
    {
      path: 'map-browsing',
      children: [],
    },
  ],
};
