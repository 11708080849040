import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import './PopupCardTaskAnalyzeProcessStatus.scss';
import { StatusButton } from '../statusButton/statusButton';
import { TaskProcessItem } from '../TaskProcessItem';
import taskAnalyzeNoData from '@bvt-images/taskAnalyzeNoData.png';

/**
 * @param {Object} props Props of component
 * @param {Array<{id:number|string,elapsedTime:string,projectName:string,projectType:string,status:'SUCCESS'|'FAILED' | 'PROCCESING'}>} props.data data
 * @param {boolean} props.visible variable for set active popover
 * @param {boolean} props.isLoading variable for loading
 * @param {()=>void} props.onClick handler for cancel
 * @param {()=>void} props.onTogglePop handler for cancel
 */
export function PopupCardTaskAnalyzeProcessStatus(props) {
  const { data, onClick, visible, onTogglePop, isLoading } = props;
  return (
    <Popover
      content={
        <div className='task-analyze PopupCardTaskAnalyzeProcessStatus'>
          <div className='PopupCardTaskAnalyzeProcessStatus__card'>
            <div className='PopupCardTaskAnalyzeProcessStatus__card-header'>
              <div className='PopupCardTaskAnalyzeProcessStatus__card--header--text'>
                Processing list
              </div>
              <div
                className='PopupCardTaskAnalyzeProcessStatus__card--close-button'
                onClick={() => onTogglePop()}
              >
                <svg
                  height='0.5rem'
                  viewBox='0 0 12.263 12.263'
                  width='0.5rem'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M3.153,7.489V5.518H1.183a1.182,1.182,0,1,1,0-2.364h1.97V1.183a1.183,1.183,0,1,1,2.365,0V3.154H7.489a1.182,1.182,0,1,1,0,2.364H5.518V7.489a1.183,1.183,0,1,1-2.365,0Z'
                    fill='#616477'
                    transform='translate(6.132) rotate(45)'
                  />
                </svg>
              </div>
            </div>
            <div className='PopupCardTaskAnalyzeProcessStatus__card-body'>
              {data && data?.length > 0 ? (
                <div className='PopupCardTaskAnalyzeProcessStatus__card-list'>
                  {data.map((v, index) => (
                    <TaskProcessItem
                      elapsedTime={v.elapsedTime}
                      key={index + v.id}
                      onClick={() => onClick && onClick(v)}
                      projectName={v.projectName}
                      projectType={v.projectType}
                      status={v.status}
                    />
                  ))}
                </div>
              ) : (
                <img
                  src={taskAnalyzeNoData}
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '1.5rem',
                    marginTop: '1.5rem',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      }
      // onVisibleChange={() => onTogglePop()}
      open={visible}
      overlayClassName='task-analyze PopupCardTaskAnalyzeProcessStatus'
      placement='bottomRight'
      trigger='click'
    >
      <StatusButton
        isLoading={isLoading}
        onClick={() => onTogglePop()}
        visible={visible}
      />
    </Popover>
  );
}

PopupCardTaskAnalyzeProcessStatus.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  onTogglePop: PropTypes.func,
  visible: PropTypes.bool,
};
