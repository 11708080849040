import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router';
import { GeoJSON, useMap } from 'react-leaflet';
import { AREA_STYLE } from '../../constant/AREA_STYLE';
import { useSIPRO } from '../../hook';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import _ from 'lodash';
import { useProject } from '@bvt-features/mapp/project/hooks/useProject';

const remapCoordsSipro = (geo, originalCollection) => {
  const distanceOrRadius = _.get(geo, 'id_radius');
  const distanceCollections = _.uniq(
    originalCollection.map((v) => _.get(v, 'id_radius'))
  );
  const distanceOrRadiusIndex = distanceCollections.indexOf(distanceOrRadius);
  const coords = [];

  for (let i = 0; i <= distanceOrRadiusIndex; i++) {
    const radius = distanceCollections[i];
    const foundData = originalCollection.find(
      (v) => _.get(v, 'id_radius') === radius
    );

    if (foundData) {
      const geometry = _.get(foundData, 'features[0].geometry');

      if (geometry.type === 'Polygon') {
        coords.push(...(geometry.coordinates || []));
      } else if (geometry.type === 'MultiPolygon') {
        coords.push(...(geometry.coordinates.flat() || []));
      }
    }
  }

  return coords;
};

export default function GeoJsonSiproContainer() {
  const initialMap = useMap();
  const location = useLocation();
  const SIPRO = useSIPRO();
  const idMap = initialMap.getContainer().id;
  const PROPERTIESLEFT = SIPRO.state.filtered_area_collection;
  const PROPERTIESRIGHT = SIPRO.state.filtered_area_collection_right;
  const PROPERTIES = !idMap.includes('right')
    ? PROPERTIESLEFT
    : PROPERTIESRIGHT;
  const MAINMAP = useMainMap();
  const PROJECT = useProject();
  const [geojson, setGeojson] = useState([]);

  const styleFinder = _.find(
    MAINMAP.state.layerVisibilityList,
    (v) => v.key === 'GEOJSON_SIPRO'
  );

  useEffect(() => {
    if (SIPRO.state.status_GET_AREA === 'SUCCESS') {
      setGeojson(SIPRO.state.area_collection);
    } else {
      setGeojson([]);
    }
  }, [SIPRO.state.status_GET_AREA, SIPRO.state.data, PROPERTIES]);

  useEffect(() => {
    MAINMAP.registerLayer({
      isActive: true,
      key: 'GEOJSON_SIPRO',
      opacity: 0.2,
      title: 'Site Profiling',
      description: ['Buffer']
    });
    return () => {
      MAINMAP.removeLayer('GEOJSON_SIPRO');
    };
  }, []);

  useEffect(() => {
    MAINMAP.updateLayer({
      key: 'GEOJSON_SIPRO',
      isActive: true,
      opacity: 0.2,
      title: 'Site Profiling',
      description: [PROJECT?.state?.detail?.name, 'Buffer']
    });
  }, [PROJECT.state.detail]);

  // tmp

  // eslint-disable-next-line react/display-name
  const GeoJson = () => {
    /**
     * @type {React.MutableRefObject<import('leaflet').GeoJSON>}
     */
    const ref = useRef();

    if (ref.current) {
      ref.current.setZIndex(101);
    }

    const getStyle = (
      radiusOrDistance,
      activeRadiusOrDistance,
      activeGeojson,
      geojsonName
    ) => {
      const listRadius = _.uniqBy(SIPRO.state.area_collection, (v) => v.radius);
      const isActive = radiusOrDistance === activeRadiusOrDistance;
      const isLastRadius =
        listRadius[listRadius.length - 1].radius === radiusOrDistance;
      const isDifferentGeojson = activeGeojson !== geojsonName;

      let style = {
        ...AREA_STYLE[isActive ? 'selected' : 'unselected'],
        opacity: styleFinder.opacity * 2,
        ...(isActive && { fillOpacity: styleFinder.opacity }),
      };

      if (isDifferentGeojson) {
        style = {
          ...AREA_STYLE.unselected,
          ...(isLastRadius
            ? {
              fillOpacity: 0.1,
              opacity: 0.6
            }
            : {
              fillOpacity: 0,
              opacity: 0.2
            }),
        };
      }
      return style;
    };

    return (
      <Fragment>
        {geojson.map((geo, index) => {
          const features = geo?.features[0];
          const radiusOrDistance = features?.properties?.durasi_distance;
          const activeRadiusOrDistance =
            PROPERTIES?.properties?.durasi_distance;
          const activeGeojson = _.filter(
            SIPRO.state.area_collection,
            (v) => v.site_name === PROPERTIES?.properties?.name
          )?.[0]?.site_name;

          const data = {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: remapCoordsSipro(
                geo,
                _.filter(
                  SIPRO.state.area_collection,
                  (v) => v.site_name === PROPERTIES?.properties?.name
                )
              ),
            },
          };

          const unselectData = {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: remapCoordsSipro(
                geo,
                _.filter(
                  SIPRO.state.area_collection,
                  (v) => v.site_name === features.properties.name
                )
              ),
            },
          };
          return (
            <GeoJSON
              data={
                activeGeojson === features.properties.name ||
                location.pathname.includes('dashboard')
                  ? data
                  : unselectData
              }
              key={index}
              ref={ref}
              style={getStyle(
                radiusOrDistance,
                activeRadiusOrDistance,
                activeGeojson,
                features.properties.name
              )}
            />
          );
        })}
      </Fragment>
    );
  };
  return (
    <Fragment>
      {initialMap && styleFinder?.isActive ? <GeoJson /> : null}
    </Fragment>
  );
}
