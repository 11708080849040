import INTERACTIVE_MAP_CONSTANT from '../constant';

const initialState = {
  project_id: null,
  uuid: '',
};

export function UUID(state = initialState, action) {
  switch (action.type) {
    case INTERACTIVE_MAP_CONSTANT.UUID:
      return action.payload;
    default:
      return state;
  }
}
