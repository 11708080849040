import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import './ButtonGroupDatex.scss';
import { Popover } from '@bvt-atoms';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For data category object
 * @typedef TCategoryDataObject
 * @property {string|number} key
 * @property {string} label
 * @property {any} icon
 * @property {Boolean} disabled
 */

/**
 * @copyright PT Bhumi Varta Techonology
 * @author Renta<renta.yustika@bvarta.com>
 * @description Button Group DATEX
 * @param {object} props
 * @param {Array<TCategoryDataObject>} props.data
 * @param {(key:string)=>void} props.onChange
 * @param {'POI'|'THEMATIC'|'TELCO'} props.activeKey
 */

const ButtonGroupDatex = (props) => {
  const { data, activeKey, onChange = () => {} } = props;
  const [state, setState] = useState({
    activeKey: '',
  });

  useEffect(() => {
    if (activeKey) {
      setState({ ...state, activeKey: activeKey });
    }
  }, [activeKey]);

  const handleChangeActive = (e) => {
    setState({
      ...state,
      activeKey: e?.key,
    });
    onChange(e);
  };

  const PopoverContent = () => {
    return (
      <div className='ButtonGroupDatex container-popover'>
        <div className='arrow arrow---down' />
        <p className='ButtonGroupDatex container-popover-text'>
          Contact our sales to subscribe
        </p>
      </div>
    );
  };

  return (
    <div className='ButtonGroupDatex__container'>
      <div className='ButtonGroupDatex__wrapper'>
      {_.map(data || [], (v, i) => {
        return v?.label === 'Telco Data' && v?.disabled ? (
          <Popover
            className='popover-wrapper'
            content={<PopoverContent />}
            header={false}
            key={i}
            placement='top'
            trigger='hover'
          >
            <div
              className={`ButtonGroupDatex__item ${
                state?.activeKey === v?.key
                  ? 'ButtonGroupDatex__item--active'
                  : ''
              } ${v?.disabled && 'ButtonGroupDatex__item--disabled'}`}
              key={i}
              onClick={(e) => {
                !v?.disabled && e.stopPropagation();
                !v?.disabled && handleChangeActive(v);
              }}
            >
              {v?.icon}
              <span>{v?.label}</span>
            </div>
          </Popover>
        ) : (
          <div
            className={`ButtonGroupDatex__item ${
              state?.activeKey === v?.key
                ? 'ButtonGroupDatex__item--active'
                : ''
            } ${v?.disabled && 'ButtonGroupDatex__item--disabled'}`}
            key={i}
            onClick={(e) => {
              !v?.disabled && e.stopPropagation();
              !v?.disabled && handleChangeActive(v);
            }}
          >
            {v?.icon}
            <span>{v?.label}</span>
          </div>
        );
      })}
      </div>
  
    </div>
  );
};

export { ButtonGroupDatex };
