import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DynamicIcon from '@bvt-icons';
import { useNavigate } from 'react-router';
import './SearchResultContainer.scss';
import LottiePlayer from 'lottie-react';
import { NotFound, SearchFile } from '@bvt-animations';
import { Popup } from '@bvt-atoms';
import { LayoutTopBarMainApp } from '@bvt-features/mapp/component/LayoutTopBarMainApp';
import { LayoutSideBarMainApp } from '@bvt-features/mapp/component/LayoutSideBarMainApp';
import { useAuth } from '@bvt-features/auth/hooks';
import { RecentActivityTable } from '@bvt-features/mapp/mappHome/component/';
import { projectTypeSlug } from '@bvt-features/mapp/mainOpen/components/openProject/items/constans';
import { Col, Row } from 'antd';
import {
  mainHomeLinkBoxAnalyze,
  mainHomeLinkBoxModule,
} from '../../../../pages/bvt/MainHome/mainHomeLinkBox';
import PopupRenameForm from '@bvt-features/genset/parameter/components/PopupRenameForm';
import { useSearchParams } from 'react-router-dom';
import { LinkBoxSquare } from '@bvt-shared/component/LinkBox/LinkBoxSquare';
import { SearchBox } from '@bvt-shared/component/SearchBox';
import { HeaderSegment } from '@bvt-shared/component/HeaderSegment';
import { useMainOpenProject } from '@bvt-features/mapp/mainOpen/hooks';

/**
 * @param {('home'|'new')} menu
 */

/**
 * @author Renta <renta.yustika@bvarta.com>
 * @copyright PT Bhumi Varta Technology
 */

const SearchResultContainer = (props) => {
  const { menu } = props;

  const AUTH = useAuth();
  const navigate = useNavigate();
  const recentActivity = useMainOpenProject();
  const [searchParams] = useSearchParams();

  const [loading, onLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [itemHovered, setItemHovered] = useState();
  const [containerState, setContainerState] = useState({
    pageSize: 3,
    page: 1,
    sort: {
      by: 'lastupdate',
      type: 'DESC',
    },
    filter: {
      name: searchParams.get('name'),
    },
  });
  const [modalState, setModalState] = useState({
    type: 'EDIT',
    isOpen: false,
    project_id: 0,
  });

  const [filteredMainHomeLinkBoxAnalyze, setFilteredMainHomeLinkBoxAnalyze] =
    useState(mainHomeLinkBoxAnalyze);
  const [filteredMainHomeLinkBoxModule, setFilteredMainHomeLinkBoxModule] =
    useState(mainHomeLinkBoxModule);

  const s3URL = '/api/api-uploads3/v2/photo-user-company/display-photo/';

  const handleLogout = () => {
    navigate('/auth/logout');
  };

  const handleBack = () => {
    navigate(`/app/main/${menu}`);
  };

  const handleSearch = async (val) => {
    onLoading(true);
    setContainerState({
      ...containerState,
      filter: {
        name: val,
      },
    });

    setFilteredMainHomeLinkBoxModule([]);
    const tempMainHomeLinkBoxModule = [];
    mainHomeLinkBoxModule.forEach((item, idxItem) => {
      tempMainHomeLinkBoxModule.push({ ...item, child: [] });
      item.child.forEach((child) => {
        if (child.text.toLowerCase().includes(val.toLowerCase())) {
          tempMainHomeLinkBoxModule[idxItem].child.push(child);
        }
      });
    });

    setFilteredMainHomeLinkBoxAnalyze([]);
    const tempMainHomeLinkBoxAnalyze = [];
    mainHomeLinkBoxAnalyze.forEach((item, idxItem) => {
      tempMainHomeLinkBoxAnalyze.push({ ...item, child: [] });
      item.child.forEach((child) => {
        if (child.text.toLowerCase().includes(val.toLowerCase())) {
          tempMainHomeLinkBoxAnalyze[idxItem].child.push(child);
        }
      });
    });

    setFilteredMainHomeLinkBoxModule(tempMainHomeLinkBoxModule);
    setFilteredMainHomeLinkBoxAnalyze(tempMainHomeLinkBoxAnalyze);

    onLoading(false);
  };

  const internalHandleChange = (e) => {
    setContainerState({
      pageSize: e.perPage,
      page: e.currentPage,
      sort: {
        by: e?.sort?.by,
        type: e?.sort?.type,
      },
      filter: e?.filter,
    });
  };

  const handleOnHover = (linkBox, isHovered) => {
    isHovered ? setItemHovered(linkBox) : setItemHovered(undefined);
  };

  useEffect(() => {
    handleSearch(searchParams.get('name'));
  }, []);

  useEffect(() => {
    recentActivity.get({
      meta: {
        pageSize: containerState.pageSize,
        page: containerState.page,
        sort: containerState.sort,
        filter: containerState.filter,
      },
    });
  }, [containerState]);

  return (
    <div className='search-result-container'>
      <LayoutTopBarMainApp
        data={{
          userName: AUTH.state?.data?.user?.fullName
            ? AUTH.state.data.user.fullName.split(' ')[0]
            : undefined,
          userPhoto:
            AUTH.state?.data?.user?.photo_path &&
            AUTH.state?.data?.user?.photo_path !== 'bnVsbA=='
              ? `${s3URL}${AUTH.state.data.user.photo_path}`
              : undefined,
        }}
        onLogoutClick={() => setShowPopup(!showPopup)}
      />
      <Popup
        body={{
          icon: 'confirmlogout',
          desc: <p>Are you sure want to log out?</p>,
        }}
        footerButton={{
          rightClass: 'red',
        }}
        handler={{
          onHide: () => {
            setShowPopup(false);
          },
          onSubmit: () => {
            handleLogout();
          },
        }}
        headerText='Confirm'
        show={showPopup}
        submitText='Logout'
      />
      <LayoutSideBarMainApp activeMenu='home' menuList={[]} />
      <div className='row-main-search-wrapper'>
        <div className='row-main-search'>
          <div className='col-main-search'>
            <button className='col-main-search-back' onClick={handleBack}>
              <DynamicIcon height='1rem' icon='arrow' width='1rem' />
            </button>
            <div className='col-main-search-text'>Back</div>
          </div>
          <div className='col-main-search-searchbox-container'>
            <HeaderSegment
              children={
                <SearchBox
                  className='col-main-search-searchbox'
                  handler={{
                    onSearch: (val) => handleSearch(val),
                  }}
                  placeholder='Project name or module name'
                />
              }
              title='Search Result'
            />
            {!(
              recentActivity.state.status_GET === 'LOADING' ||
              recentActivity.state.status_DELETE === 'LOADING'
            ) && (
              <div className='mapp-RecentActivity'>
                {recentActivity.state?.list?.length > 0 && (
                  <Fragment>
                    <HeaderSegment
                      className='mt-4'
                      loading={false}
                      style={{ marginBottom: '1rem' }}
                      title='Projects'
                      variant='outlined'
                    />

                    <RecentActivityTable
                      currentPage={containerState.page}
                      data={recentActivity.state.list.map((c) => ({
                        ...c,
                        project_name: c?.name,
                        create_date: c?.created_at,
                        last_update: c?.updated_at,
                        project_type: c?.project_type,
                        key: c?.id,
                      }))}
                      filter={containerState.filter}
                      isHideCreateProject={true}
                      isHideSort={true}
                      isLoading={
                        recentActivity.state.status_GET === 'LOADING' ||
                        recentActivity.state.status_DELETE === 'LOADING'
                      }
                      onChange={(v) => internalHandleChange(v)}
                      onCreateReportClick={(v) => {
                        const type = projectTypeSlug(v.project_type);
                        if (type === 'grid-analysis') {
                          navigate(
                            `/app/dashboard?project_id=${v.id}&step=6${
                              v?.task?.UUID ? '&process=' + v?.task?.UUID : ''
                            }&report-type=pdf`
                          );
                        } else if (type === 'site-profiling') {
                          navigate(
                            `/app/dashboard?project_id=${v.id}&step=7${
                              v?.task?.UUID ? '&process=' + v?.task?.UUID : ''
                            }&report-type=pdf`
                          );
                        }
                      }}
                      onDuplicateClick={(v) =>
                        setModalState({
                          type: 'DUPLICATE',
                          isOpen: true,
                          project_id: v.id,
                        })
                      }
                      onEditClick={(v) =>
                        setModalState({
                          type: 'EDIT',
                          isOpen: true,
                          project_id: v.id,
                        })
                      }
                      onRemoveClick={(v) =>
                        setModalState({
                          type: 'DELETE',
                          isOpen: true,
                          project_id: v.id,
                        })
                      }
                      onResumeClick={(v) => {
                        let stepLogic = 1;
                        const type = projectTypeSlug(v.project_type);
                        if (v?.task?.status === 'SUCCESS') {
                          if (type === 'grid-analysis') {
                            stepLogic = 6;
                          }
                          if (type === 'site-profiling') {
                            stepLogic = 7;
                          }
                        } else {
                          stepLogic = v.step_order;
                        }
                        navigate(
                          `/app/map/map-analytic/${type}?step=${stepLogic}&project_id=${
                            v.id
                          }${v?.task?.UUID ? '&process=' + v?.task?.UUID : ''}`
                        );
                      }}
                      onShowAll={() => navigate('/app/main/open')}
                      perPage={containerState.pageSize}
                      sort={containerState.sort}
                      total={recentActivity.state?.properties.total}
                    />
                    <Popup
                      body={{
                        icon: 'confirmremove',
                        desc: (
                          <>
                            <h1>Are you sure ?</h1>
                            <p>you want to Remove this project ?</p>
                            <b>
                              {JSON.stringify(
                                recentActivity.state?.list?.filter(
                                  (v) => v.id === modalState?.project_id
                                )[0]?.name
                              )}
                            </b>
                            <p>This Process cannot be undone </p>
                          </>
                        ),
                        iconHeight: '6rem',
                        iconWidth: '6rem',
                      }}
                      cancelButton={true}
                      cancelText='Abort'
                      footerButton={{
                        leftClass: 'text-grey',
                        rightClass: 'red',
                      }}
                      handler={{
                        onHide: () =>
                          setModalState({ ...modalState, isOpen: false }),
                        onSubmit: () => {
                          recentActivity.remove(modalState?.project_id);
                          setTimeout(() => {
                            recentActivity.get({
                              meta: {
                                pageSize: containerState.pageSize,
                                page: containerState.page,
                                sort: containerState.sort,
                              },
                            });
                          }, 500);
                          setModalState({
                            isOpen: false,
                            project_id: 0,
                            type: 'IDLE',
                          });
                        },
                      }}
                      header={false}
                      show={modalState.isOpen && modalState.type === 'DELETE'}
                      submitText='Remove'
                    />
                    <PopupRenameForm
                      buttonConfirmText={
                        modalState.type === 'EDIT' ? 'Rename' : 'Duplicate'
                      }
                      defaultValue={
                        recentActivity.state?.list?.filter(
                          (v) => v.id === modalState?.project_id
                        )[0]?.name
                      }
                      errorMessage={recentActivity.state.message}
                      headerText={
                        modalState.type === 'EDIT'
                          ? 'Rename Your Project'
                          : 'Duplicate From Your Saved Project'
                      }
                      onSubmit={(e) =>
                        modalState.type === 'EDIT'
                          ? recentActivity.rename(modalState.project_id, e)
                          : recentActivity.duplicate(modalState.project_id, e)
                      }
                      reFetch={() =>
                        recentActivity.get({
                          meta: {
                            pageSize: containerState.pageSize,
                            page: containerState.page,
                            sort: containerState.sort,
                          },
                        })
                      }
                      setShowPopup={(v) =>
                        setModalState({ ...modalState, isOpen: v })
                      }
                      show={
                        modalState.isOpen &&
                        (modalState.type === 'EDIT' ||
                          modalState.type === 'DUPLICATE')
                      }
                      status={
                        modalState.type === 'EDIT'
                          ? recentActivity.state.status_PATCH
                          : recentActivity.state.status_POST
                      }
                    />
                  </Fragment>
                )}

                {(filteredMainHomeLinkBoxAnalyze[0]?.child?.length > 0 ||
                  filteredMainHomeLinkBoxModule[0]?.child?.length > 0) && (
                  <Fragment>
                    <HeaderSegment
                      className='mt-4'
                      loading={false}
                      style={{ marginBottom: '1rem' }}
                      title='Modules'
                      variant='outlined'
                    />
                    <Row gutter={[10, 10]} justify='start'>
                      {filteredMainHomeLinkBoxModule?.map((v, vidx) => (
                        <Fragment key={vidx}>
                          {v.child?.map((v2, v2Idx) => (
                            <Col
                              key={v2Idx}
                              onMouseEnter={() => handleOnHover(v2, true)}
                              onMouseLeave={() => handleOnHover(v2, false)}
                            >
                              <LinkBoxSquare
                                background={v2.background}
                                BvtIcon={v2.disabledIcon || v2.icon}
                                className='main-home__content-title__space'
                                disabled={v2.disabledIcon ? true : false}
                                isHovered={v2 === itemHovered}
                                loading={loading}
                                onClick={() => {
                                  if (v2Idx > 0) {
                                    navigate({
                                      pathname: `/app/main/new/${v.child[0].slug}`,
                                      search: `menu=${v2.slug}&path=${v.path}`,
                                    });
                                  } else {
                                    navigate({
                                      pathname: `/app/main/new/${v2.slug}`,
                                      search: `path=${v.path}`,
                                    });
                                  }
                                }}
                                text={v2.text}
                              />
                            </Col>
                          ))}
                        </Fragment>
                      ))}
                      {filteredMainHomeLinkBoxAnalyze?.map((v, vidx) => (
                        <Fragment key={vidx}>
                          {v.child?.map((v2, v2Idx) => (
                            <Col
                              key={v2Idx}
                              onMouseEnter={() => handleOnHover(v2, true)}
                              onMouseLeave={() => handleOnHover(v2, false)}
                            >
                              <LinkBoxSquare
                                background={v2.background}
                                BvtIcon={v2.disabledIcon || v2.icon}
                                className='main-home__content-title__space'
                                disabled={v2.disabledIcon ? true : false}
                                isHovered={v2 === itemHovered}
                                loading={loading}
                                onClick={() => {
                                  if (v2Idx >= 0) {
                                    navigate({
                                      pathname: `${v2.path}`,
                                    });
                                  }
                                }}
                                text={v2.text}
                              />
                            </Col>
                          ))}
                        </Fragment>
                      ))}
                    </Row>
                  </Fragment>
                )}
              </div>
            )}
          </div>
        </div>
        <div className='row-main-search-lottie'>
          {loading && (
            <LottiePlayer
              animationData={SearchFile}
              className='search-file-lottie'
            />
          )}
          {!loading &&
            !recentActivity.state?.list?.length &&
            !filteredMainHomeLinkBoxAnalyze[0]?.child?.length &&
            !filteredMainHomeLinkBoxModule[0]?.child?.length && (
            <LottiePlayer
              animationData={NotFound}
              className='search-file-lottie'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchResultContainer;

SearchResultContainer.propTypes = {
  menu: PropTypes.string,
};

SearchResultContainer.defaultProps = {
  menu: 'home',
};
