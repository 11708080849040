import React, { useEffect } from 'react';
import { Button, Card, Drawer, Space, Typography } from 'antd';
import { useMainMap } from '../../hook/useMainMap';


export const BaseMapControlContainer = () => {
  const map = useMainMap();

  useEffect(() => {
    if (map.state.layout.baseMapControl) {
      const elm = document.querySelector('.ant-drawer-content-wrapper');
      map.disableMapEvent(elm);
    }
  }, [map.state.layout.baseMapControl]);

  return (
    <React.Fragment>
      <Drawer
        getContainer={false}
        mask={false}
        onClose={() => map.toggleBaseMapControl({ isOpen: false })}
        open={map.state.layout.baseMapControl}
        placement='right'
        title='Base Map'
        width={312}
      >
        <Space direction='vertical' style={{ width: '100%' }}>
          {map.state.baseMapList.map((v, idx) => (
            <Card
              bordered
              cover={
                <img
                  src={v.img}
                  style={{ width: '100%', height: 92, objectFit: 'cover' }}
                />
              }
              key={idx + v.key + v.img}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>{v.title}</Typography>
                <Button
                  disabled={map.state.selectedBaseMap === v.key}
                  onClick={() => {
                    map.toggleBaseMapControl();
                    map.switchBaseMap({ key: v.key });
                  }}
                  type='primary'
                >
                  Activate
                </Button>
              </div>
            </Card>
          ))}
        </Space>
      </Drawer>
    </React.Fragment>
  );
};
