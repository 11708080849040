import React, {useEffect} from 'react';
import { usePlace } from '../../../hooks/usePlace';
import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import '../Dropdown.scss';
import DropdownInter from '../../DropdownInter';

export function SearchProvinceInter(props) {
  const { isProvince,show } = props;
  const PLACE = usePlace();

  useEffect(() => {
    getData();
  }, [PLACE.state.country_mandala]);

  useEffect(() => {
    if (!isEmpty(PLACE.state.params.provinsi)) {
      isProvince && isProvince(PLACE.state.params.provinsi);
    }
  }, [PLACE.state.params.provinsi]);

  const getData = (value = "") => {
    const params = {
      meta: { filter: { keyword: value ,country_id: PLACE.state.country_mandala.country_id }, page: 1, pageSize: 100 },
    };
    PLACE.get_province_inter({ params });
  };

  const getDataDebounce = debounce(getData, 800);

  const getPlaceholderProvince = () => {
    const countryId = PLACE.state.country_mandala.country_id
    const listNameRegion = ["Province","Region","Province"]
    return listNameRegion[countryId-1]
  }

  const listOption = () => {
    if(show == "all"){
      return PLACE.state.province?.list
    }else{
      return PLACE.state.province?.list?.filter((item)=>["Kanto","Ho Chi Minh"].includes(item.name))
    }
  }

  return (
      <DropdownInter
        handler={{
          select: (ctx) => {
            PLACE.setParams({ type: 'provinsi', ...ctx });
            PLACE.removeParams({ kota: null });
            PLACE.removeParams({ kecamatan: null });
            PLACE.removeParams({ desa: null });
            isProvince(ctx);
            props?.isCity?.(null);
            props?.setDistrict?.(null);
            props?.setVillage?.(null);
          },
          clear: () => {
            PLACE.removeParams({ provinsi: null });
            PLACE.removeParams({ kota: null });
            PLACE.removeParams({ kecamatan: null });
            PLACE.removeParams({ desa: null });
            props.isProvince && props.isProvince(null);
            props.setCity && props.setCity(null);
            props.setDistrict && props.setDistrict(null);
            props.setVillage && props.setVillage(null);
          },
          search: (value) => {
            getDataDebounce(value);
          },
        }}
        isLoading={PLACE.state.province?.status_GET === 'LOADING'}
        options={listOption()}
        dispayName={`name`}
        displayId={`id`}
        placeholder={getPlaceholderProvince()}
        selected={PLACE.state.params.provinsi && {
          id: PLACE.state.params.provinsi.id,
          name: PLACE.state.params.provinsi.name,
          code: PLACE.state.params.provinsi.code,
          
        }}
        type='provinsi'
      />
  );
}

SearchProvinceInter.defaultProps = {
  isProvince: undefined,
  show:"all"
};

SearchProvinceInter.propTypes = {
  isProvince: PropTypes.func,
  province: PropTypes.object,
  setCity: PropTypes.func,
  setDistrict: PropTypes.func,
  setVillage: PropTypes.func,
  validation: PropTypes.bool,
  show: PropTypes.string,
};
