import { useAuth } from '@bvt-features/auth/hooks';
import { STEP_ACTION } from '@bvt-features/mapp/step/redux';
import { useDispatch, useSelector } from 'react-redux';
import { GRIANA_ACTION, GRIANA_CONSTANT } from '../../redux';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta <renta.yustika@bvarta.com>
 */

/**
 * @description For meta object
 * @typedef IMetaProps
 * @type {Object}
 * @property {number} total
 * @property {number} prevPage
 * @property {number} nextPage
 * @property {number} perPage
 */

/**
 * @description For saving project object
 * @typedef IFORMIKGRIANA
 * @type {Object}
 * @property {number} id
 * @property {string} project_name
 * @property {string} project_type
 * @property {Object} province
 * @property {number} province.id
 * @property {number} province.code
 * @property {Object} city
 * @property {number} city.id
 * @property {number} city.code
 * @property {number|string} poi
 * @property {number|string} parameter
 * @property {Object} peopleDensity
 * @property {number} peopleDensity.year
 * @property {number} peopleDensity.month
 * @property {string|number} peopleDensity.day
 * @property {Object} peopleDensity.hour
 * @property {string|number} peopleDensity.hour.min
 * @property {string|number} peopleDensity.hour.max
 * @property {string} peopleDensity.timeType
 * @property {boolean} peopleDensity.isChecked
 * @property {string} peopleDensity.resultType
 * @property {boolean} peopleDensity.isChecked
 * @property {number} gridSize
 * @property {boolean} switchPeopleDensity
 * @property {Object} score
 * @property {number} score.poi
 * @property {number} score.ses
 * @property {number} score.density
 * @property {number} score.demography
 * @property {Object} analyze_result
 * @property {Object} analyze_result.poi
 * @property {Object} analyze_result.grid
 * @property {number} history.id_step
 * @property {number} history.step_order
 * @property {string|number} id_source
 * @property {string} process_id
 */

/**
 * @description For Grid Detail
 * @typedef IGridDetailProps
 * @type {Object}
 * @property {number} gid
 * @property {number} poi
 * @property {number} ses
 * @property {Array<string>} RDTR
 * @property {string} desa
 * @property {number} rank
 * @property {Object} agama
 * @property {number} grid_x
 * @property {number} grid_y
 * @property {string} kabkot
 * @property {string} provinsi
 * @property {Object} subclass
 * @property {number} grid_size
 * @property {number} id_source
 * @property {string} kecamatan
 * @property {string} kodepodes
 * @property {Object} landValue
 * @property {number} landValue.min
 * @property {number} landValue.max
 * @property {number} param_poi
 * @property {number} param_ses
 * @property {Object} pekerjaan
 * @property {string} skala_poi
 * @property {string} skala_ses
 * @property {number} demography
 * @property {Object} pendidikan
 * @property {string} nilai_tanah
 * @property {number} total_param
 * @property {string} skala_result
 * @property {number} param_age_range
 * @property {string} skala_age_range
 * @property {Object} age_range_category
 * @property {Object} social_economi_status
 * @property {string} social_ekonomi_status
 */

/**
 * @description For poi collections geojson features
 * @typedef IPOICollectionsGeojsonFeatures
 * @type {Object}
 * @property {string} type
 * @property {Object} properties
 * @property {number} properties.gid
 * @property {number} properties.id_poi
 * @property {string} properties.kodkec
 * @property {string} properties.kodkel
 * @property {number} properties.id_brand
 * @property {string} properties.subclass
 * @property {string} properties.kodepodes
 * @property {string} properties.kode_brand
 * @property {string} properties.nama_brand
 * @property {number} properties.id_merchant
 * @property {string} properties.kode_kategori
 * @property {string} properties.nama_merchant
 * @property {string} properties.alamat_merchant
 * @property {string} properties.kode_sub_kategori
 * @property {number} properties.id_kategori
 * @property {string} properties.nama_kategori
 * @property {string} properties.icon_category
 * @property {string} properties.icon_color
 * @property {number} properties.id_sub_kategori
 * @property {string} properties.nama_sub_kategori
 * @property {string} properties.icon_sub_category
 * @property {Object} geometry
 * @property {string} geometry.type
 * @property {Array<number>} geometry.coordinates
 */

/**
 * @description For POI Collections
 * @typedef IPOICollections
 * @type {Object}
 * @property {string} message
 * @property {IMetaProps} meta
 * @property {Object} geoJson
 * @property {string} geoJson.type
 * @property {Array<IPOICollectionsGeojsonFeatures>} geoJson.features
 */

/**
 * @description For area collections geojson features
 * @typedef IAreaCollectionsGeojsonFeatures
 * @type {Object}
 * @property {string} type
 * @property {Object} properties
 * @property {number} properties.gid
 * @property {Object} properties.center
 * @property {string} properties.center.lat
 * @property {string} properties.center.lng
 * @property {number} properties.sum_poi
 * @property {string} properties.skala_result
 * @property {number} properties.score
 * @property {Object} geometry
 * @property {string} geometry.type
 * @property {Array} geometry.coordinates
 */

/**
 * @description For Area Collections
 * @typedef IAreaCollections
 * @type {Object}
 * @property {string} message
 * @property {IMetaProps} meta
 * @property {Object} geoJson
 * @property {string} geoJson.type
 * @property {Array<IAreaCollectionsGeojsonFeatures>} geoJson.features
 */

export function useGRIANA() {
  const dispatch = useDispatch();
  const AUTH = useAuth();

  /**
   * @typedef {Object} GRIANA_REDUCER
   * @property {'IDLE'|'LOADING'|'SUCCESS'|'FAILED'} status_GET``
   * @property {'IDLE'|'LOADING'|'SUCCESS'|'FAILED'} status_DETAIL
   * @property {'IDLE'|'LOADING'|'SUCCESS'|'FAILED'} status_GET_AREA
   * @property {'IDLE'|'LOADING'|'SUCCESS'|'FAILED'} status_GET_POI
   * @property {'IDLE'|'LOADING'|'SUCCESS'|'FAILED'} status_DETAIL
   * @property {'IDLE'|'LOADING'|'SUCCESS'|'FAILED'} status_GET_AREA
   * @property {'IDLE'|'LOADING'|'SUCCESS'|'FAILED'} status_GET_POI
   * @property {'IDLE'|'LOADING'|'SUCCESS'|'FAILED'} status_POST
   * @property {'IDLE'|'LOADING'|'SUCCESS'|'FAILED'} status_PUT
   * @property {'IDLE'|'LOADING'|'SUCCESS'|'FAILED'} status_DELETE
   * @property {'IDLE'|'LOADING'|'SUCCESS'|'FAILED'} status_ANALYZE
   * @property {string} message
   * @property {Array} data
   * @property {ISaveProjectObject} formSaveProject
   * @property {IPropsAnalyzeObject} formAnalyzeProject
   * @property {IGridDetailProps} gridDetail
   * @property {IGridDetailProps} gridDetailRight
   * @property {IPOICollections} poiCollections
   * @property {IPOICollections} poiCollectionsRight
   * @property {Array<number>} [whiteListGid]
   * @property {Array<number>} [whiteListGidRight]
   * @property {Array<string>} acceptable
   * @property {Array<string>} acceptableRight
   * @property {number} step
   * @property {string} gid
   * @property {string} gid_right
   * @property {number} lastStep
   * @property {boolean} IS_SHOWN_ON_MAP
   */

  /**
   *  @type {GRIANA_REDUCER} GRIANA_REDUCER
   */
  const state = useSelector((state) => state.features.MAPANA.GRIANA);
  const stateStep = useSelector((state) => state.features.MAPP.STEP);

  const createRenameProject = (last) => {
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;

    if (last === 0) {
      setLastStep(1);
      urlParams.set('step', 1);
      window.history.replaceState({}, '', `${url}`);
      dispatch(STEP_ACTION.setStep({ step: 1 }));
    } else {
      setLastStep(last);
      urlParams.set('step', last);
      window.history.replaceState({}, '', `${url}`);
      dispatch(STEP_ACTION.setStep({ step: last }));
    }
  };

  const nextStep = () => {
    let remapSetStep = stateStep.step < 6 ? stateStep.step + 1 : 6;
    let remapSetGrianaStep =
      stateStep.step + 10 < 15 ? stateStep.step + 11 : 15;
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;
    urlParams.set('step', remapSetStep);
    window.history.replaceState({}, '', `${url}`);
    dispatch(GRIANA_ACTION.setStep({ step: remapSetGrianaStep }));
    dispatch(STEP_ACTION.setStep({ step: remapSetStep }));
    setLastStep(remapSetStep);
  };

  const prevStep = () => {
    let remapSetStep = stateStep.step > -1 ? stateStep.step - 1 : 0;
    let remapSetGrianaStep = stateStep.step + 10 > 9 ? stateStep.step + 10 : 10;
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;
    remapSetStep === 0
      ? urlParams.delete('step')
      : urlParams.set('step', remapSetStep);

    window.history.replaceState({}, '', `${url}`);
    dispatch(GRIANA_ACTION.setStep({ step: remapSetGrianaStep }));
    dispatch(STEP_ACTION.setStep({ step: remapSetStep }));
    setLastStep(remapSetStep);
  };

  /**
   *
   * @param {number} payload
   */
  const setStep = (payload) => {
    dispatch(GRIANA_ACTION.setStep({ step: payload + 10 }));
  };
  /**
   * @param {IFORMIKGRIANA} payload
   */
  const remapGridPropertyForAnalyze = (payload) => ({
    province: payload?.province?.code,
    regency: payload?.city?.code,
    poi: payload?.poi,
    parameter: payload?.parameter,
    id_source: payload?.id_source,
    scoring: {
      gridSize: payload?.gridSize || 1000, // default 1000
      categories: {
        poiCategory: Object.fromEntries(
          Object.entries(payload?.score?.poi || {}).map(
            ([key, value]) => [key, value.value]
          )
        ),
        thematic: {
          ...(Object.keys(payload?.score?.demography || {}).length ||
          Object.keys(payload?.score?.ses || {}).length ?
            {
              ...(Object.entries(payload?.score?.demography || {}).length && {
                demography: Object.fromEntries(Object.entries(
                  payload?.score?.demography || {}).map(([key, value]) => [key, value.value]))
              }),
              ...(Object.entries(payload?.score?.ses || {}).length && {
                ses: Object.fromEntries(Object.entries(
                  payload?.score?.ses || {}).map(([key, value]) => [key, value.value]))
              })
            } : {}
          )
        },
        density: payload?.score?.density,
      },
    },
    peopleDensityParam: {
      day: payload?.peopleDensity?.day,
      year: payload?.peopleDensity?.year,
      month: payload?.peopleDensity?.month,
      hour: {
        min: null,
        max: null,
      },
      resultType: payload?.peopleDensity?.resultType,
    },
    id_project: payload?.id,
  });

  /**
   * @param {IFORMIKGRIANA} payload
   */
  const remapGridPropertyForSaveProject = (payload) => ({
    name: payload?.project_name,
    project_type: payload?.project_type,
    history: {
      form_set: {
        poi: payload?.poi,
        density: {
          day: payload?.peopleDensity?.day,
          hour: {
            min: payload?.peopleDensity?.hour?.min,
            max: payload?.peopleDensity?.hour?.max,
          },
          year: payload?.peopleDensity?.year,
          month: payload?.peopleDensity?.month,
          isChecked: payload?.peopleDensity?.isChecked,
          resultType: payload?.peopleDensity?.resultType,
          timeType: payload?.peopleDensity?.timeType,
        },
        project: {
          name: payload?.project_name,
          id_source: payload?.id_source,
        },
        regency: {
          id: payload?.city?.id,
          code: payload?.city?.code,
        },
        scoring: {
          gridSize: payload?.gridSize || 1000, // default 1000
          categories: {
            poi: payload?.score?.poi,
            ...(payload?.score?.ses && { ses: payload?.score?.ses }),
            density: payload?.score?.density,
            demography: payload?.score?.demography,
          },
        },
        province: {
          id: payload?.province?.id,
          code: payload?.province?.code,
        },
        parameter: payload?.parameter,
      },
      analyze_result: {
        poi: payload?.analyze_result?.poi,
        grid: payload?.analyze_result?.grid,
      },
      id_step: payload?.id_step,
    },
    id: payload?.id,
    id_source: payload?.id_source,
    process_id: payload?.process_id,
  });

  /**
   * @param {IFORMIKGRIANA} payload
   */
  const saveProject = (payload) => {
    let remapPayload = remapGridPropertyForSaveProject(payload);
    dispatch(GRIANA_ACTION.saveProject(remapPayload));
  };
  /**
   * @param {IFORMIKGRIANA} payload
   */
  const doAnalyze = (payload) => {
    let remapPayloadSave = remapGridPropertyForSaveProject(payload);
    let remapPayloadAnalyze = remapGridPropertyForAnalyze(payload);
    dispatch(GRIANA_ACTION.doAnalyze(remapPayloadSave, remapPayloadAnalyze));
  };

  /**
   * @param {IAreaCollections} payload
   */
  const getGridCollections = (payload) => {
    dispatch(GRIANA_ACTION.getGridCollections(payload));
  };
  /**
   * @param {IAreaCollections} payload
   */
  const getGridCollectionsRight = (payload) => {
    dispatch(GRIANA_ACTION.getGridCollectionsRight(payload));
  };

  /**
   * @param {IGridDetailProps} payload
   */
  const getGridDetail = (payload) => {
    dispatch(GRIANA_ACTION.getGridDetail(payload));
  };

  const getGridDetailRight = (payload) => {
    dispatch(GRIANA_ACTION.getGridDetailRight(payload));
  };

  /**
   * @param {IPOICollections} payload
   */
  const getPoiCollections = (payload) => {
    dispatch(GRIANA_ACTION.getPoiCollections(payload));
  };

  const getPoiCollectionsRight = (payload) => {
    dispatch(GRIANA_ACTION.getPoiCollectionsRight(payload));
  };

  /**
   *
   * @param {string} payload
   */
  const setGid = (payload) => {
    dispatch(GRIANA_ACTION.setGid(payload));
  };
  const setGidRight = (payload) => {
    dispatch(GRIANA_ACTION.setGidRight(payload));
  };

  const setWhiteList = (payload) => {
    dispatch(GRIANA_ACTION.setWhiteList(payload));
  };
  const setWhiteListRight = (payload) => {
    dispatch(GRIANA_ACTION.setWhiteListRight(payload));
  };

  const setAcceptable = (payload) => {
    dispatch(GRIANA_ACTION.setAcceptable(payload));
  };

  const setAcceptableRight = (payload) => {
    dispatch(GRIANA_ACTION.setAcceptableRight(payload));
  };

  const resetAll = () => {
    dispatch({ type: GRIANA_CONSTANT.RESET_ALL });
  };

  const resetStatus = () => {
    dispatch({ type: GRIANA_CONSTANT.RESET_STATUS });
  };

  const resetPoiCollections = () => {
    dispatch({
      type: GRIANA_CONSTANT.POI_COLLECTIONS_SUCCESS,
      payload: { poiCollections: [] },
    });
  };

  const remapPOIHandlerParam = (param) => ({
    meta: {
      filter: {
        keyword: param?.meta?.filter?.keyword || '',
        poi_for_user: AUTH.state.data?.user?.id,
        rangeDate: {
          start: param?.meta?.filter?.rangeDate?.start || '',
          end: param?.meta?.filter?.rangeDate?.end || '',
        },
        type: param?.meta?.filter?.type || '',
      },
      page: param?.meta?.page || 1,
      pageSize: param?.meta?.pageSize || 20,
      sort: {
        by: param?.meta?.sort?.by || 'created_date',
        name: param?.meta?.sort?.name || 'ASC',
      },
    },
  });

  const remapParameterHandlerParam = (param) => ({
    meta: {
      filter: {
        keyword: param?.meta?.filter?.keyword,
        user_id: AUTH?.state?.data?.user?.id,
        rangeDate: param?.meta?.filter?.rangeDate,
      },
      page: param?.meta?.page || 1,
      pageSize: param?.meta?.pageSize || 20,
      sort: { by: param?.meta?.sort?.by, name: param?.meta?.sort?.name },
    },
  });

  const selectGID = (payload) => {
    dispatch(GRIANA_ACTION.selectGid(payload));
  };
  const selectGIDRight = (payload) => {
    dispatch(GRIANA_ACTION.selectGidRight(payload));
  };

  /**
   *
   * @param {number} param
   */
  const setLastStep = (param) => {
    dispatch(GRIANA_ACTION.setLastStep({ lastStep: param }));
  };

  const setIsShownOnMap = (payload) => {
    dispatch(GRIANA_ACTION.setIsShownOnMap({ IS_SHOWN_ON_MAP: payload }));
  };

  /**
   * Check validity for next if hit direct url
   * @param {IFORMIKGRIANA} payload
   */
  const isValidForNext = (payload, step) => {
    const step1 = !!payload.province.code && !!payload.city.code;
    const step2 = payload?.poi?.length > 0;
    const step4 = payload.peopleDensity.isChecked
      ? !!payload.peopleDensity.year
      : true;

    switch (step) {
      case 2:
        return step1;
      case 3:
        return step1 && step2;
      case 4:
        return step1 && step2;
      case 5:
        return step1 && step2 && step4;
      default:
        return false;
    }
  };

  return {
    state,
    createRenameProject,
    nextStep,
    prevStep,
    setStep,
    resetAll,
    getGridCollections,
    getGridCollectionsRight,
    getGridDetail,
    getGridDetailRight,
    getPoiCollections,
    getPoiCollectionsRight,
    resetPoiCollections,
    setGid,
    setGidRight,
    setWhiteList,
    setWhiteListRight,
    setAcceptable,
    setAcceptableRight,
    saveProject,
    resetStatus,
    doAnalyze,
    remapPOIHandlerParam,
    remapParameterHandlerParam,
    selectGID,
    selectGIDRight,
    setLastStep,
    setIsShownOnMap,
    isValidForNext,
  };
}
