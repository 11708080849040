import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Dropdown.scss';
import { debounce, filter, isEmpty } from 'lodash';
import { Loading } from '../Loading';
import { Popover } from '@bvt-atoms';

const IconClose = () => {
  return (
    <svg
      className='close'
      height='1rem'
      viewBox='0 0 12.263 12.263'
      width='1rem'
    >
      <path
        d='M3.153,7.489V5.518H1.183a1.182,1.182,0,1,1,0-2.364h1.97V1.183a1.183,1.183,0,1,1,2.365,0V3.154H7.489a1.182,1.182,0,1,1,0,2.364H5.518V7.489a1.183,1.183,0,1,1-2.365,0Z'
        fill='currentColor'
        transform='translate(6.132) rotate(45)'
      />
    </svg>
  );
};

const ArrowUp = () => {
  return (
    <svg
      height='0.65rem'
      viewBox='0 0 11 6'
      width='0.65rem'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.763.8A1,1,0,0,1,6.237.8l3.227,3.52A1,1,0,0,1,8.727,6H2.273a1,1,0,0,1-.737-1.676Z'
        data-name='Polygon 133'
        fill='#4d4f5c'
        id='Polygon_133'
      />
    </svg>
  );
};

const ArrowDown = () => {
  return (
    <svg
      height='0.65rem'
      viewBox='0 0 11 6'
      width='0.65rem'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.763.8A1,1,0,0,1,6.237.8l3.227,3.52A1,1,0,0,1,8.727,6H2.273a1,1,0,0,1-.737-1.676Z'
        data-name='Polygon 133'
        fill='#4d4f5c'
        id='Polygon_133'
        transform='translate(11 6) rotate(180)'
      />
    </svg>
  );
};

export const Dropdown = (props) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    return () => {
      setList([]);
    };
  }, []);

  useEffect(() => {
    debounce(() => {
      setList(
        filter(props.options, (option) =>
          option[`nama_${props.type}`].includes(props.inputValue.toUpperCase())
        )
      );
    }, 800)();
  }, [props.inputValue, props.options]);

  const DisplayInput = () => {
    return (
      <React.Fragment>
        {props.selected?.name ? (
          <div
            className={`shared-dropdown dropdown-place__display-box-input ${props.className}`}
          >
            <div className='shared-dropdown dropdown-place__display-box-input_text'>
              {props.selected.name}
            </div>
            <div
              className='shared-dropdown dropdown-place__display-box-input_button'
              onClick={(e) => {
                e.stopPropagation();
                props.handler.select && props.handler.select({});
                props.handler.clear && props.handler.clear();
              }}
            >
              <IconClose />
            </div>
          </div>
        ) : (
          <div className='shared-dropdown dropdown-place__display-box-input_placeholder'>
            {props.placeholder}
          </div>
        )}
      </React.Fragment>
    );
  };

  const PopoverContent = () => {
    return (
      <div className='shared-dropdown container-popover'>
        <div className='arrow arrow---down' />
        <p className='shared-dropdown container-popover-text'>
          Contact our sales to subscribe
        </p>
      </div>
    );
  };

  function compareStatus(a, b) {
    return a.status - b.status;
  }

  const [toggle, setToggle] = useState(false);
  const suggestions = () => {
    if (!isEmpty(list) && !props.isLoading) {
      return (
        <div className='shared-dropdown dropdown-place__options-list-container'>
          {props?.validation ? (
            list.sort(compareStatus).map((ctx) => (
              !ctx.status?
                <div
                  className={`shared-dropdown dropdown-place__options-list atas ${
                    ctx[`id_${props.type}`] === props.selected?.id ? 'active' : ''
                  }`}
                  key={ctx[`id_${props.type}`]}
                  onClick={() => {
                    props.handler.select && props.handler.select(ctx);
                    setToggle(false);
                  }}
                >
                  {ctx[`nama_${props.type}`]}
                </div> : (
                  <Popover
                    className='shared-dropdown popover-wrapper'
                    content={<PopoverContent />}
                    header={false}
                    key={ctx[`id_${props.type}`]}
                    placement='top-left'
                    trigger='hover'
                    visibility={ctx.status !== 'T'}
                  >
                    <div
                      className={`shared-dropdown dropdown-place__options-list dis ${
                        ctx[`id_${props.type}`] === props.selected?.id ? 'active' : ''
                      } ${ctx.status? 'disabled': ''}`}
                      key={ctx[`id_${props.type}`]}
                      onClick={() => {
                        props.handler.select && !ctx.status && props.handler.select(ctx);
                        // setToggle(false);
                      }}
                    >
                      {ctx[`nama_${props.type}`]}
                    </div>
                  </Popover>
                )
            ))
          ) : list.map((ctx) => (
            <div
              className={`shared-dropdown dropdown-place__options-list else ${
                ctx[`id_${props.type}`] === props.selected?.id ? 'active' : ''
              }`}
              key={ctx[`id_${props.type}`]}
              onClick={() => {
                props.handler.select && props.handler.select(ctx);
                setToggle(false);
              }}
            >
              {ctx[`nama_${props.type}`]}
            </div>
          ))}
        </div>
      );
    } else if (!isEmpty(list)) {
      return (
        <div className='shared-dropdown dropdown-place__options-not-found'>
          {props.placeholder} not found
        </div>
      );
    } else {
      return (
        <div className='shared-dropdown dropdown-place__options-not-found'>
          <Loading color={'green'} size={'medium'} />
        </div>
      );
    }
  };

  return (
    <div className='shared-dropdown dropdown-place'>
      <div
        className='shared-dropdown dropdown-place__container'
        onMouseLeave={() => setToggle(false)}
      >
        <div
          className={`shared-dropdown dropdown-place__display-box ${
            props.disabled ? 'disabled' : ''
          } ${props.selected?.name || toggle ? 'active' : ''}`}
          onClick={() => setToggle((prev) => !prev)}
        >
          <DisplayInput />
          {toggle ? <ArrowUp /> : <ArrowDown />}
        </div>
        {toggle && (
          <div className='shared-dropdown dropdown-place__options'>
            <div className='shared-dropdown dropdown-place__options-search'>
              <input
                onChange={(e) => {
                  props.handler.input && props.handler.input(e.target.value);
                }}
                placeholder='Search'
                value={props.inputValue}
              />
            </div>
            {suggestions()}
          </div>
        )}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handler: PropTypes.shape({
    input: PropTypes.func,
    select: PropTypes.func,
    clear: PropTypes.func,
  }),
  inputValue: PropTypes.string,
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  selected: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  type: PropTypes.oneOf(['provinsi', 'kota', 'kecamatan', 'desa']),
  validation: PropTypes.bool,
};
