import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import './PopupCardSelectCountry.scss';
import { StatusCountry } from '../StatusCountry';
import { usePlace } from '@bvt-features/mainmap/place/hooks/usePlace';
import { useState } from 'react';

export function PopupCardSelectCountry() {

  const [visible, setVisible] = useState(false)

  const PLACE = usePlace()

  useEffect(() => {
    const params = {
      meta: {
        filter: { keyword: "" },
        page: 1,
        pageSize: 100,
      },
    };
    PLACE.get_country({ params });
  }, [])
  
  const handleChangeCountry = (value) => {
    PLACE.resetPlaceParams()
    PLACE.setCountryMandala(value)
    setVisible(false)
  }

  return (
    <Popover
      content={
        <div className='select-country PopupCardSelectCountry'>
          <div className='PopupCardSelectCountry__card'>
            <div className='PopupCardSelectCountry__card-header'>
              <div className='PopupCardSelectCountry__card--header--text'>
                List Country
              </div>
            </div>
            {PLACE.state.country?.list && PLACE.state.country.list.map((country)=>{
              const {country_id,country_name} = country
              return <div key={country_id} className='PopupCardSelectCountry__card-body' onClick={()=>{handleChangeCountry(country)}}>
                  {country_name}
              </div>
            })}
         
          </div>
        </div>
      }
      // onVisibleChange={() => onTogglePop()}
      open={visible}
      overlayClassName='task-analyze PopupCardSelectCountry'
      placement='bottomRight'
      trigger='click'
    >
      <StatusCountry
        onClick={() => {setVisible(!visible)}}
        disable={PLACE.state.isChangeCountryDisabled}
        visible={visible}
      />
    </Popover>
  );
}

PopupCardSelectCountry.propTypes = {

};
