import * as _ from 'lodash';
import { produce } from 'immer';

/**
 * @description For dataLayer object
 * @typedef TRoleKeymapper
 * @type {Object}
 * @property {string} key
 * @property {boolean} isAllowed
 * @property {Array<TRoleKeymapper>} children
 */

/**
 * @description For mapping company role to key value type
 * @type {Array<TRoleKeymapper>} initialdraft
 */

let initialDraft = [
  {
    key: 'MAPANA',
    isAllowed: false,
    children: [
      {
        key: 'GRIANA',
        isAllowed: false,
        children: [
          {
            key: 'GENERATE_REPORT',
            isAllowed: true,
            children: [],
          },
          {
            key: 'GENERATE_REPORT_FULL',
            isAllowed: false,
            children: [],
          },
        ],
      },
      {
        key: 'SIPRO',
        isAllowed: false,
        children: [
          {
            key: 'GENERATE_REPORT',
            isAllowed: true,
            children: [],
          },
          {
            key: 'GENERATE_REPORT_FULL',
            isAllowed: false,
            children: [],
          },
        ],
      },
      {
        key: 'MANDALA',
        isAllowed: false,
        children: [],
      },
      {
        key: 'ASMANTELKOM',
        isAllowed: false,
        children: [],
      },
    ],
  },
  {
    key: 'GENSET',
    isAllowed: true, // default true
    children: [
      {
        key: 'POI',
        isAllowed: true, // default true
        children: [],
      },
      {
        key: 'PARAMETER',
        isAllowed: true, // default true
        children: [],
      },
    ],
  },
  {
    key: 'DATEX',
    isAllowed: false,
    children: [
      {
        key: 'THEMATIC',
        isAllowed: false,
        children: [],
      },
      {
        key: 'THEMATIC_RINJANI',
        isAllowed: false,
        children: [],
      },
      {
        key: 'POI_RINJANI',
        isAllowed: false,
        children: [],
      },
      {
        key: 'POI',
        isAllowed: false,
        children: [],
      },
      {
        key: 'TELCO',
        isAllowed: false,
        children: [],
      },
    ],
  },
  {
    key: 'TELCO_DATA',
    isAllowed: false,
  },
  {
    key: 'THEMATIC_DATA',
    isAllowed: false
  },
];

const defaultPermission = {
  superAdmin: 'true',
  map_analytic: {
    gridAnalysis: 'true',
    siteProfilingMandala: 'false',
    siteProfiling: 'true',
    analyticAccess: 'true',
    asmanTelkom: 'false',
    generalReportSipro: 'true',
    generalReportGriana: 'true'
  },
  data_explorer: {
    thematic: 'true',
    telcoData: 'true',
    pointOfInterest: 'true',
    dataExplorerAccess: 'true',
    pointOfInterestRinjani: 'false',
    thematicRinjani:'false'
  },
  telco_data: 'true'
};

const checkerMAPANA = (role, draft) => {
  if (role?.map_analytic?.analyticAccess === 'true') {
    let draftMapana = _.find(draft, (v) => v.key === 'MAPANA') || {};
    draftMapana.isAllowed = true;

    // GRIANA CHECKER
    if (role?.map_analytic?.gridAnalysis === 'true') {
      let draftGriana = _.find(draftMapana.children, (v) => v.key === 'GRIANA') || {};
      draftGriana.isAllowed = true;
      // GENERATE REPORT CHECKER
      if(role?.map_analytic?.generalReportGriana === 'false'){
        let draftGENERATE_REPORT = _.find(draftGriana.children, (v) => v.key === 'GENERATE_REPORT') || {};
        draftGENERATE_REPORT.isAllowed = false;
      }
      if (role?.map_analytic?.fullDataGriana === 'true') {
        let draftGrianaGenerateReportFull = _.find(draftGriana.children, (v) => v.key === 'GENERATE_REPORT_FULL') || {};
        draftGrianaGenerateReportFull.isAllowed = true;
      }
    }

    // SIPRO CHECKER
    if (role?.map_analytic?.siteProfiling === 'true') {
      let draftSipro = _.find(draftMapana.children, (v) => v.key === 'SIPRO') || {};
      draftSipro.isAllowed = true;
      // GENERATE REPORT CHECKER
      if(role?.map_analytic?.generalReportSipro === 'false'){
        let draftGENERATE_REPORT = _.find(draftSipro.children, (v) => v.key === 'GENERATE_REPORT') || {};
        draftGENERATE_REPORT.isAllowed = false;
      }
      if (role?.map_analytic?.fullDataSipro === 'true') {
        let draftSiproGenerateReportFull = _.find(draftSipro.children, (v) => v.key === 'GENERATE_REPORT_FULL') || {};
        draftSiproGenerateReportFull.isAllowed = true;
      }
    }

    // SIPROMANDALA CHECKER
    if (role?.map_analytic?.siteProfilingMandala === 'true' && process.env.MODE !== 'production') {
      let draftSiproMandala = _.find(draftMapana.children, (v) => v.key === 'MANDALA') || {};
      draftSiproMandala.isAllowed = true;
    }

    // ASMANTELKOM CHECKER
    if (role?.map_analytic?.asmanTelkom === 'true') {
      let draftSipro = _.find(draftMapana.children, (v) => v.key === 'ASMANTELKOM') || {};
      draftSipro.isAllowed = true;
    }
  }
};

const checkerGENSET = (role, draft) => {
  if (role?.general_setup?.generalSetupAccess === 'true') {
    let draftGenset = _.find(draft, (v) => v.key === 'GENSET') || {};
    draftGenset.isAllowed = true;

    // PARAMETER CHECKER
    if (role?.general_setup?.manageParameter === 'true') {
      _.find(draftGenset.children, (v) => v.key === 'PARAMETER').isAllowed = true;
    }

    // POI CHECKER
    if (role?.general_setup?.customizePOI === 'true') {
      _.find(draftGenset.children, (v) => v.key === 'POI').isAllowed = true;
    }
  }
};

const checkerDATEX = (role, draft) => {
  if (role?.data_explorer?.dataExplorerAccess === 'true') {
    let draftDatex = _.find(draft, (v) => v.key === 'DATEX') || {};
    draftDatex.isAllowed = true;

    // TELCO CHECKER
    if (role?.data_explorer?.telcoData === 'true') {
      _.find(draftDatex.children, (v) => v.key === 'TELCO').isAllowed = true;
    }

    // POI CHECKER
    if (role?.data_explorer?.pointOfInterest === 'true') {
      _.find(draftDatex.children, (v) => v.key === 'POI').isAllowed = true;
    }

    // THEMATIC CHECKER
    if (role?.data_explorer?.thematic === 'true') {
      _.find(draftDatex.children, (v) => v.key === 'THEMATIC').isAllowed = true;
    }

    // POI RINJANI CHECKER
    if (role?.data_explorer?.pointOfInterestRinjani === 'true' && process.env.MODE !== 'production') {
      _.find(draftDatex.children, (v) => v.key === 'POI_RINJANI').isAllowed = true;
    }

    // THEMATIC RINJANI CHECKER
    if (role?.data_explorer?.thematicRinjani === 'true' && process.env.MODE !== 'production') {
      _.find(draftDatex.children, (v) => v.key === 'THEMATIC_RINJANI').isAllowed = true;
    }
  }
};

const checkTelcoData = (role, draft) => {
  if(role?.telco_data === 'true') {
    let draftTelco = _.find(draft, (v) => v.key === 'TELCO_DATA') || {};
    draftTelco.isAllowed = true;
  }
};

const checkerThematicData =(role, draft) => {
  const category = role?.thematic_data?.category || [];
  if(category?.find(v => Number(v.id) === 2)){
    const subcategory = role?.thematic_data?.subcategory || [];
    const sesAccess = subcategory?.find(v => Number(v.id) === 14);
    const draftThematic = _.find(draft, (v) => v.key === 'THEMATIC_DATA') || {};
    draftThematic.isAllowed = sesAccess ? true: false;
  }
};

export const permissionMapper = (role = defaultPermission) => {
  return produce(initialDraft, (draft) => {

    // MAPANA CHECKER
    checkerMAPANA(role, draft);

    // GENSET CHECKER
    checkerGENSET(role, draft);

    // DATEX CHECKER
    checkerDATEX(role, draft);

    // DATA ACCESSIBILITY TELCO DATA
    checkTelcoData(role, draft);

    // DATA ACCESSIBILITY THEMATIC DATA
    checkerThematicData(role, draft);

    return draft;
  });
};
